var State = require('./State.js')
let Persist = require('./Persist.js')
const Share = require('./Share.js')

var Store = {}

State.Store = Store

// Get a change history
Store.get = function(name) {
    return State.history[name]
}

Store.update = function(data, change) {
    data.push(change)
}

Store.localChange = function(change) {
    State.history.uDesign[State.design].push(change)
    const encoded = Share.encodeAllNormal()
    State.history.encoded[State.design] = encoded
}

Store.persist = function() {
    Persist.rawPersist("Knot", State.history)
}

function normalize(history) {
    if (!history) history = {}
    if (history.uDesign) {
        for (var i=0; i < 32; i++) {
            history.uDesign[i] = history.uDesign[i] ? history.uDesign[i] : (() => [])()
        }
    } else {
        history.uDesign = Array.from(Array(32), () => [])
    }
    if (history.encoded) {
        for (var i=0; i < 32; i++) {
            history.encoded[i] = history.encoded[i] ? history.encoded[i] : ""
        }
    } else {
        history.encoded = Array(32).fill("")
    }
    return history
}

var arrayChangeHandler = {
    get: function(target, property) {
      if (property == 0) {
        console.log('[' + target[property] + ']');
      }
      // property is index in this case
      return target[property];
    },
    set: function(target, property, value, receiver) {
      if (property == 0) {
        console.log('[' + target[property] + '] to [' + value + ']');
        if (target[property].length > 0 && value.length == 0) {
            console.log('fail')
            console.trace()
        }
      }
      target[property] = value;
      // you have to return true to accept the changes
      return true;
    }
};

Store.initialize = function(callback) {
	Persist.rawFetch("Knot", function(data) {
        data = normalize(data)
        State.history = data
        // State.history = {
        //     uDesign: data.uDesign,
        //     encoded: new Proxy(data.encoded, arrayChangeHandler)
        // }
        if (callback) {
            callback(data)
        }
	})
}

module.exports = Store
