var State = require('./State.js')
var Draw = require('./Draw.js')
var Tile = require('./Tile.js')
var Random = require('./Random.js')

// Generate frames for BABYLON, rotate, and spins

var Animate = {}

Animate.frameRate = 30 // Target frames per second.

Animate.rotateTile = function(mesh) {
  mesh.rotate(BABYLON.Axis.Y, Math.PI/3, BABYLON.Space.WORLD)
}

// Keys for unit circle
Animate.unitCircleKeys = function() {
  var keys = [];

  var numberOfKeyframes = 33;
  var alpha = 0;
  for (var i = 0; i < numberOfKeyframes; i++){
    var x = Math.cos(alpha);
    var y = 0;
    var z = Math.sin(alpha);

    keys.push({
	    frame: i*8,
	    value: new BABYLON.Vector3(x, y, z)
    });

    alpha += Math.PI/16;
  }
  return keys
}

//Animate.unitCircle = function(

Animate.viaKeys = function(mesh, keys, repeat) {
  var repeat = !!repeat

  var frames = keys[keys.length-1].frame

  // TODO Test for jump using one or the other OR
  // TODO read code for difference
  //var animationLoop = BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
  var animationLoop = BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE

  //Create a Vector3 animation at 30 FPS
  var animation = new BABYLON.Animation("animation", "position", 30, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, animationLoop)

  animation.setKeys(keys)

  // Adding animation to mesh animations collection
  mesh.animations.push(animation)

  //Finally launch animation from key 0 to key 120 with no loop
   State.scene.beginAnimation(mesh, 0, frames, repeat)
}

Animate.meshToPositions = function(mesh, positions, repeat) {

  var repeat = !!repeat

  //var animationLoop = BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
  var animationLoop = BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
  //Create a Vector3 animation at 30 FPS
  var animation = new BABYLON.Animation("animation", "position", 30, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, animationLoop)

  var framesPerPosition = 6

  // Animation keys
  // For now, not figuring on distance between positions. Uneven speed
  var keys = [];

  for (var i=0; i < positions.length; i++) {
    keys.push({ frame: framesPerPosition*i, value: positions[i] });
  }

  var frames = framesPerPosition*(positions.length - 1)

  animation.setKeys(keys);

  // Adding animation to mesh animations collection
  mesh.animations.push(animation)

  //Finally launch animation from key 0 to key 120 with no loop
   State.scene.beginAnimation(mesh, 0, frames, repeat);
}

// TODO Finish
Animate.startPath = function(mesh, path) {
    if (!path || path.length === 0) {
        return
    }
    mesh.path = path
    var target = path[0]
    var vector = target.subtract(mesh.position)

}

// TODO Finish
/*
Animate.path = function(mesh) {
    if (!mesh.path || mesh.path.length === 0) {
        // unregister
    }
    var next = path[0]
    if (BABYLON.Distance(mesh.position, next) <= State.moveEpsilon) {
        mesh.position.x = next.x
        mesh.position.z = next.z
        path.unshift()
        Animate.startPath(mesh, path)
    } else {
        mesh.position.addInPlace(mesh.velocity)
    }
    return true
}
*/

Animate.meshToPositionNextHexArc = function(mesh, positions, call, hexArc, fps) {

  var fps = fps || 30

  var animationLoop = BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
  //Create a Vector3 animation at 30 FPS
  var animation = new BABYLON.Animation("animation", "position", fps, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, animationLoop)

  var framesPerPosition = 6

  // Animation keys
  // For now, not figuring on distance between positions. Uneven speed
  var keys = [];

  for (var i=0; i < positions.length; i++) {
    keys.push({ frame: framesPerPosition*i, value: positions[i] });
  }

  var frames = framesPerPosition*(positions.length - 1)

  animation.setKeys(keys);

  var callback = function() {
    if (mesh.animations !== null) {
        call(mesh, hexArc)
    }
  }

  mesh.animations = [animation]
  mesh.myAnimation = State.scene.beginAnimation(mesh, 0, frames, false, 1, callback);

}


Animate.worldMove = function(mesh, toPosition, frames, endEventFunction) {
  //Create a Vector3 animation at 30 FPS
  var animation = new BABYLON.Animation("animation", "position", 30, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT)

  // Animation keys
  var keys = [];
  keys.push({ frame: 0, value: mesh.position });
  keys.push({ frame: frames, value: toPosition });

  animation.setKeys(keys);

  // Creating an easing function
  var easingFunction = new BABYLON.CircleEase();

  // For each easing function, you can choose between EASEIN (default), EASEOUT, EASEINOUT
  easingFunction.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

  // Adding the easing function to the animation
  animation.setEasingFunction(easingFunction);

  // Run event if defined
  if (endEventFunction) {
    var endEvent = new BABYLON.AnimationEvent(frames, function() {
      endEventFunction(mesh)
    })
    // Attach event to your animation
    animation.addEvent(endEvent);
  }

  // Adding animation to mesh animations collection
  mesh.animations.push(animation)

  //Finally launch animation from key 0 to key 120 with no loop
  State.scene.beginAnimation(mesh, 0, frames, false);

}

Animate.circle = function(mesh) {
  var animationLoop = BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
  //Create a Vector3 animation at 30 FPS
  var animation = new BABYLON.Animation("animation", "scaling", 30, BABYLON.Animation.ANIMATIONTYPE_VECTOR3, animationLoop)

  var keys = Animate.wobbleKeys()
  var frames = keys[keys.length-1].frame
  keys.push({ frame: frames, value: new BABYLON.Vector3(0,0,0) })

  animation.setKeys(keys);

  var callback = function() {}

  State.scene.beginDirectAnimation(mesh, [animation], 0, frames, false, 1, callback);
}

Animate.halt = function(mesh, animationHandle) {
    mesh.xDelta = 0
    mesh.yDelta = 0
    mesh.zDelta = 0
    mesh.unregisterBeforeRender(animationHandle)
    return mesh
}

Animate.moveDeltas = function(mesh) {
    if (mesh.velocity) {
        mesh.position.x += (mesh.velocity.x || 0)
        mesh.position.y += (mesh.velocity.y || 0)
        mesh.position.z += (mesh.velocity.z || 0)
    }
}

// TODO Complete
Animate.moveOnArc = function(mesh) {
    if (mesh.arcCenter && mesh.radius) {
    }
}

Animate.move = function(mesh, xDelta, zDelta, checkFunc) {
    mesh.velocity = new BABYLON.Vector3(xDelta, 0, zDelta)
    var move = function() {
      if (!checkFunc || checkFunc(mesh)) {
        Animate.moveDeltas(mesh)
      }
    }
    mesh.registerBeforeRender(move)
    return move
}

Animate.spin = function(mesh) {
     State.scene.registerBeforeRender(function() {
      mesh.rotate(BABYLON.Axis.Y, Math.PI/3, BABYLON.Space.WORLD)
    })
}


Animate.allSpin = function() {
    for(var i=0; i <  State.scene.meshes.length; i++) {
        if ( State.scene.meshes[i].name === "tile") {
             State.scene.meshes[i].rotate(BABYLON.Axis.Y, Math.PI/3, BABYLON.Space.WORLD)
        }
    }
}

// Math.PI/120 and Math.PI/240 give a good, slow spin
Animate.allSpinSlower = function() {
    for(var i=0; i <  State.scene.meshes.length; i++) {
        if ( State.scene.meshes[i].name === "tile") {
             State.scene.meshes[i].rotate(BABYLON.Axis.Y, Math.PI/240, BABYLON.Space.WORLD)
        }
    }
}

Animate.allSpinTrio = function() {
    var trio = [Math.PI/9, Math.PI/18, Math.PI/36]
    for(var i=0; i <  State.scene.meshes.length; i++) {
        if ( State.scene.meshes[i].name === "tile") {
            var speed =  State.scene.meshes[i].speed
            if (!speed) {
                speed = trio[Random.generateIndex(3)]
                 State.scene.meshes[i].speed = speed
            }
             State.scene.meshes[i].rotate(BABYLON.Axis.Y, speed, BABYLON.Space.WORLD)
        }
    }
}


// Time-driven oscillation.
Animate.oscillateSin = function(tickholder) {
    tickholder.tick = (tickholder.tick + 1) % 120
    var val = Math.PI*2/120*tickholder.tick
    var move = Math.sin(val)*Animate.sinPI60
    return move
}

Animate.oscillateSinCubed = function(tickholder) {
    tickholder.tick = (tickholder.tick + 1) % 240
    var val = Math.PI*2/240*tickholder.tick
    var base = Math.sin(val)*Animate.sinPI60Cubed
    var move = base*base*base
    return move
}

// Binary search on function. Assume increasing output for increasing input.
Animate.minimizeError = function(target, func) {
    if (target <= 0) {
        console.log("minimizeError: target must be larger than 0")
        return;
    }

    var epsilon = Number.EPSILON
    var maxIterations = 10000
    var iterations = 0
    var modifier = 1
    var modifierModifier = 0.99
    var working = Number.MIN_VALUE

    var testTarget = func(working)

    while (Math.abs(testTarget-target) > epsilon && working >= Number.MIN_VALUE && working <= Number.MAX_VALUE && iterations < maxIterations) {
      while (working < Number.MAX_VALUE && testTarget < target && iterations < maxIterations) {
        working *= (1+modifier)
        testTarget = func(working)
        iterations++
      }
      modifier *= modifierModifier
      while (working > Number.MIN_VALUE && testTarget > target && iterations < maxIterations) {
        working *= 1/(1+modifier)
        testTarget = func(working)
        iterations++
      }
      modifier *= modifierModifier
    }
    return [working, target, testTarget, testTarget-target, epsilon, iterations]
}

Animate.sinPIMinimizeError = function() {
    var target = Math.PI
    var chunks = 60
    var chunkOfPI = Math.PI/chunks

    var calcFunc = function(input) {
      var result = 0
      for (var i=0; i<chunks; i++) {
          result += Math.sin(i*chunkOfPI)*input
      }
      return result
    }
    return Animate.minimizeError(target, calcFunc)
}

Animate.sinPI60 = 0.08226549882923616

Animate.sinPICubeMinimizeError = function() {
    var target = Math.PI
    var chunks = 60
    var chunkOfPI = Math.PI/chunks

    var calcFunc = function(input) {
      var result = 0
      for (var i=0; i<chunks; i++) {
          var val = Math.sin(i*chunkOfPI)*input
          result += (val*val*val)
      }
      return result
    }
    return Animate.minimizeError(target, calcFunc)
}

Animate.sinPI60Cubed = 0.4978172092696493

Animate.tick = 0

Animate.allBackAndForth = function() {
    var yRotation = Animate.oscillateSinCubed(Animate)*5
    //if (yRotation > 0.99) { console.log("Hit good high") }
    //if (yRotation < -0.99) { console.log("Hit good low") }
    //if (yRotatiaon > 1.0 || yRotation < -1.0) { console.log("Bad rotation?", yRotation) }
    //yRotation = 3/2*Math.PI*(yRotation * yRotation * yRotation)
    //yRotation = 0.25*(yRotation * yRotation * yRotation)
    for(var i=0; i <  State.scene.meshes.length; i++) {
        if ( State.scene.meshes[i].name === "tile") {
             State.scene.meshes[i].rotate(BABYLON.Axis.Y, yRotation, BABYLON.Space.WORLD)
        }
    }
}

Animate.beforeRenderFunctions = []
Animate.beforeRender = function() {
    for (var i=0; i<Animate.beforeRenderFunctions.length; i++) {
        Animate.beforeRenderFunctions[i]()
    }
    Draw.updatePaintCanvasTexture()
    // TODO Mesh.addDot() instead
}

Animate.rolling = function(mesh) {
    var k = 0.0;
    var y = 0.0;
    var x = 0.0;
     State.scene.registerBeforeRender(function() {
        wheel1.rotation.copyFromFloats(0.0, 0.0, Math.PI / 2); //Keep wheel vertical
        wheel1.addRotation(x, 0.0, 0.0);  //Steering Rotation Front Wheels
        wheel1.addRotation(0.0, y, 0.0);  //Forward Rotation Front Wheels
        y += 0.05;
        k += 0.02;
        x = Math.sin(k) / 2.0;
    });
}

/*
    // Create a particle system
    var particleSystem = new BABYLON.ParticleSystem("particles", 2000,  State.scene);



    //Texture of each particle
    //particleSystem.particleTexture = new BABYLON.Texture("textures/flare.png",  State.scene);

    var txt = particleSystem.particleTexture = new BABYLON.DynamicTexture(null, {width:512,height:80},  State.scene, true);
    txt.hasAlpha = true;
    txt.drawText("Babylon", 0, 80, "bold 70px Sans",   "white" );  //Font, colour, background

    window.addEventListener("click", function () {
	    txt.drawText("WebGL", 0, 80, "bold 70px Sans",   "yellow" );  //Font, colour, background
	});

    // Where the particles come from
    particleSystem.emitter = fountain; // the starting object, the emitter
    particleSystem.minEmitBox = new BABYLON.Vector3(-1, 0, 0); // Starting all from
    particleSystem.maxEmitBox = new BABYLON.Vector3(1, 0, 0); // To...

    // Colors of all particles
    particleSystem.color1 = new BABYLON.Color4(0.7, 0.8, 1.0, 1.0);
    particleSystem.color2 = new BABYLON.Color4(0.2, 0.5, 1.0, 1.0);
    particleSystem.colorDead = new BABYLON.Color4(0, 0, 0.2, 0.0);

    // Size of each particle (random between...
    particleSystem.minSize = 0.1;
    particleSystem.maxSize = 0.5;

    // Life time of each particle (random between...
    particleSystem.minLifeTime = 0.3;
    particleSystem.maxLifeTime = 1.5;

    // Emission rate
    particleSystem.emitRate = 150;

    // Blend mode : BLENDMODE_ONEONE, or BLENDMODE_STANDARD
    particleSystem.blendMode = BABYLON.ParticleSystem.BLENDMODE_ONEONE;

    // Set the gravity of all particles
    particleSystem.gravity = new BABYLON.Vector3(0, -9.81, 0);

    // Direction of each particle after it has been emitted
    particleSystem.direction1 = new BABYLON.Vector3(-7, 8, 3);
    particleSystem.direction2 = new BABYLON.Vector3(7, 8, -3);

    // Angular speed, in radians
    particleSystem.minAngularSpeed = 0;
    particleSystem.maxAngularSpeed = Math.PI;

    // Speed
    particleSystem.minEmitPower = 1;
    particleSystem.maxEmitPower = 3;
    particleSystem.updateSpeed = 0.005;

    // Start the particle system
    particleSystem.start();

*/

module.exports = Animate
