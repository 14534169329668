//
// Hex centers with axial addressing (coordinates) ==================
//
// Human translated from https://www.redblobgames.com/grids/hexagons/implementation.html
// All functions assume index addressing [q, r]

// Hex addressing and world location translation
// 
var Hex = {}

Hex.worldHexRatio = 16.0
Hex.radius =  Hex.worldHexRatio // 16
Hex.worldTileDiameter = 27

Hex.equal = function(a, b) {
    return (a[0] === b[0] && a[1] === b[1] && a[2] === b[2])
}
Hex.add = function(a, b) {
    return [a[0]+b[0], a[1]+b[1]]
}
Hex.subtract = function(a, b) {
    return [a[0]-b[0], a[1]-b[1]]
}
Hex.multiply = function(a, k) {
    // If k isn't int, returns fractional hex
    return [a[0]*k, b[0]*k]
}

Hex.cubeLength = function(a) {
    return Math.floor((Math.abs(a[0]) + Math.abs(a[1]) + Math.abs(-a[0]-a[1]))/2)
}

Hex.cubeDistance = function(a, b) {
    return Hex.cubeLength(Hex.subtract(a, b))
}

Hex.directions = [
    [0, 1], [1, 0], [1, -1],
    [0, -1], [-1, 0], [-1, 1]
]

/*
Hex.neighbors = function(a) {
    return Hex.directionsA.map(function(d) {
	  return Hex.add(a, d)
	})
}
*/

/*
Hex.inSet = function(a, set) {
  for (var i=0; i < set.length; i++) {
    if (a[0] === set[i][0] && a[1] === set[i][1]) {
      return true
    }
  }
  return false
}
*/

Hex.pointy = {
    f: [
	    Math.sqrt(3.0), Math.sqrt(3.0)/2.0,
		0.0, 3.0 / 2.0
	],
    b: [
	    Math.sqrt(3.0)/3.0, -1.0 / 3.0,
        0.0, 2.0/3.0
	],
	angle: 0.5  // in radians
}

/*
Hex.flat = {
    f: [
	    3.0/2.0, 0.0,
	    Math.sqrt(3.0)/2.0, Math.sqrt(3.0)
	],
    b: [
	    2.0/3.0, 0.0,
        -1.0/3.0, Math.sqrt(3.0)/3.0
	],
	angle: 0.0  // in radians
}
*/


// Counts on BABYLON matrix operations
// p === [x,y]
Hex.pixelToFractionalHex = function(p, layout) {
    var x = (p[0] - layout.origin.x)/layout.size.x
	var y = (p[1] - layout.origin.y)/layout.size.y
	var q = Hex.pointy.b[0]*x + Hex.pointy.b[1]*y
	var r = Hex.pointy.b[2]*x + Hex.pointy.b[3]*y
	return [q, r]
}

Hex.isWhole = function(a) {
  return Number.isInteger(a[0]) && Number.isInteger(a[1])
}
    
Hex.round = function(f) {
    const q = Math.floor(f[0]+0.5)
	const r = Math.floor(f[1]+0.5)
	const s = Math.floor(-f[0]-f[1]+0.5)
	const qDiff = Math.abs(q - f[0])
	const rDiff = Math.abs(r - f[1])
	const sDiff = Math.abs(s - (-f[0]-f[1]))
	if (qDiff > rDiff && qDiff > sDiff) {
	    return [-r - s, r]
	} else if (rDiff > sDiff) {
	    return [q, -q - s]
	}
    return [q, r]
}

/*
// Returns fractional 
Hex.hexToPixel = function(a, layout) {
    // Assuming pointy a[0] === q, a[1] === r
	var x = (Hex.pointy.f[0]*a[0] + Hex.pointy.f[1]*a[1]) * layout.size.x
	var y = (Hex.pointy.f[2]*a[0] + Hex.pointy.f[3]*a[1]) * layout.size.y
	return [x + layout.origin.x, y + layout.origin.y]
}
*/

/*
Hex.distance2D = function(a, b) {
  var m = a[0]-b[0]
  var n = a[1]-b[1]
  return (Math.sqrt(m*m + n*n))
}
*/

/*
Hex.nearestCenterPixel = function(p) {
    var a = Hex.round(Hex.pixelToFractionalHex(p))
    return Hex.hexToPixel(a)
}
*/
//
// Babylon specific functions
//

Hex.hexToWorld = function(a, size) {
    size = size || Hex.worldHexRatio
    // Assuming pointy a[0] === q, a[1] === r
	var x = (Hex.pointy.f[0]*a[0] + Hex.pointy.f[1]*a[1])*size
	var z = (Hex.pointy.f[2]*a[0] + Hex.pointy.f[3]*a[1])*size
	return new BABYLON.Vector3(x, 0, z)
}

Hex.worldToFractionalHex = function(w, size) {
    size = size ||  Hex.worldHexRatio
    var x = w.x/size
	var z = w.z/size
	var q = Hex.pointy.b[0]*x + Hex.pointy.b[1]*z
	var r = Hex.pointy.b[2]*x + Hex.pointy.b[3]*z
	return [q, r]
}

Hex.hexWorldWidth = function() {
    var p1 = Hex.hexToWorld([0,0])
    var p2 = Hex.hexToWorld([0,1])
    var distance = BABYLON.Vector3.Distance(p1, p2)
    return distance
}()

Hex.nearestHexCenter = function(w, size) {
    size = size ||  Hex.worldHexRatio
    var fractional = Hex.worldToFractionalHex(w, size)
    var a = Hex.round(fractional)
	return a
}

// Side 2, 1, 0, 5, 4, 3
Hex.neighborCentersInWorld = function() {
    var result = Hex.directions.map(function(d) {
        return Hex.hexToWorld(d,  Hex.worldHexRatio)
    })
    return result
}()

Hex.bigArcRadius = Hex.neighborCentersInWorld[1].x
Hex.smallArcRadius = Hex.neighborCentersInWorld[1].x/4

Hex.sidesInWorld = function() {
    var result = []
    for (var side = 0; side < 6; side++) {
        result.push(Hex.neighborCentersInWorld[(side+3)%6])
    }
    return result
}()

Hex.pointsInWorld = function() {
    var h = Hex.neighborCentersInWorld[1].x
    var zPoint = Math.sqrt((h/2)*(h/2) + (h/4)*(h/4))
    var result = [
      new BABYLON.Vector3(h/2,  0, h/4),
      new BABYLON.Vector3(h/2,  0, -h/4),
      new BABYLON.Vector3(0,    0, -zPoint),
      new BABYLON.Vector3(-h/2, 0, -h/4),
      new BABYLON.Vector3(-h/2, 0, h/4),
      new BABYLON.Vector3(0,    0, zPoint)
    ]
    return result
}()

// w needs to be normalized to distance from [0,0]
Hex.pointToNeighborDistances = function(w) {
  var distances = Hex.neighborCentersInWorld.map(function(neighborPosition, i) {
      // The texture is rotated, so:
      //    i: 0, 1, 2, 3, 4, 5
      // side: 3, 4, 5, 0, 1, 2
      // TODO: draw texture normalized to side coordinates (do not rotate)
      var side = (i+3)%6 
      return {
        side: side,
        distance: w.subtract(neighborPosition).length()
      }
  })
  return distances
}


// hexA, hexB => [{ hex: hexA, side: ofHexB }, { hex: hexB, side: ofHexA }]
Hex.sharedSides = function(hexA, hexB) {
    if (Hex.cubeDistance(hexA, hexB) !== 1) {
        console.log("Hex.sidePair not adjacent", hexA, hexB)
    }
    var side = 0
    var vector = Hex.subtract(hexA, hexB)
    if (Hex.equal(vector, [0,1])) { side = 3 }
    if (Hex.equal(vector, [1,0])) { side = 4 }
    if (Hex.equal(vector, [1,-1])) { side = 5 }
    if (Hex.equal(vector, [0,-1])) { side = 0 }
    if (Hex.equal(vector, [-1,0])) { side = 1 }
    if (Hex.equal(vector, [-1,1])) { side = 2 }
    return [ side, (side+3)%6 ]
}

/*
// Assume scene and engine are in global space
Hex.pixelToWorld = function(p) {
    return scene.pick(p[0], p[1], undefined, false, scene.activeCamera).pickedPoint
}

Hex.worldToPixel = function(vector) {
    var camera = scene.activeCamera
	var worldMatrix = BABYLON.Matrix.Identity()
	var viewportWidth = engine.getRenderWidth()
	var viewportHeight = engine.getRenderHeight()
	var transform = camera.getViewMatrix().multiply(camera.getProjectionMatrix())
	var viewport = camera.viewport.toGlobal(viewportWidth, viewportHeight)
	var projection = camera.getProjectionMatrix()
    var p = BABYLON.Vector3.Project(vector, worldMatrix, transform, viewport)
    return [Math.round(p.x), Math.round(p.y)]
}
*/

Hex.nearestHexSide = function(position) {
    var hex = Hex.nearestHexCenter(position)
    var hexWorld = Hex.hexToWorld(hex)
    var offsetFromCenter = hexWorld.subtract(position)
    var distances = Hex.pointToNeighborDistances(offsetFromCenter)
    var sorted = distances.sort(function(a, b) { return a.distance - b.distance })
    return {
        hex: hex,
        side: sorted[0].side
    }
}

Hex.positionsWithinXHex = function(w1, w2, hexes) {
    var hexesAsWorld = hexes *  Hex.worldHexRatio
    var xdiff = w1.x - w2.x
    var zdiff = w1.z - w2.z
    var distance = Math.sqrt((xdiff*xdiff) + (zdiff*zdiff))
    return distance < hexesAsWorld
}

Hex.findLastHexIndex = function(hex, hexes) {
    for (var i=hexes.length-1; i >= 0; i--) {
        if (Hex.equal(hex, hexes[i])) {
            return i
        }
    }
    return -1
}

Hex.addBetweenHexes = function(positionA, positionB, hexes) {
    var lastHex = Hex.nearestHexCenter(positionA)
    const halfway = BABYLON.Vector3.Center(positionA, positionB)
    const quarterway = BABYLON.Vector3.Center(positionA, halfway)
    var hex = Hex.nearestHexCenter(quarterway)
    if (!Hex.equal(hex, lastHex)) {
        hexes.push(hex)
        lastHex = hex
    }
    hex = Hex.nearestHexCenter(halfway)
    if (!Hex.equal(hex, lastHex)) {
        hexes.push(hex)
        lastHex = hex
    }
    const threequarterway = BABYLON.Vector3.Center(halfway, positionB)
    hex = Hex.nearestHexCenter(threequarterway)
    if (!Hex.equal(hex, lastHex)) {
        hexes.push(hex)
        lastHex = hex
    }
    hex = Hex.nearestHexCenter(positionB)
    if (!Hex.equal(hex, lastHex)) {
        hexes.push(hex)
        lastHex = hex
    }
    if (hexes.length > 2) {
        [a, b] = hexes.slice(-2)
        if (Hex.cubeDistance(a,b) > 1.0) {
            console.log("Hex.positionsToHexes Distance greater than 1", a, b)
        }
    }
    //return hexes
}

Hex.positionsToHexes = function(positions) {
    // generate hex stream from positions with no duplicates
    // and some interpolation
    // TODO for better interpolation, measure Hex distance between two positions
    // TODO and if greater than 1, then create intermediate
    var hexes = []
    var lastPosition = positions[0]
    var lastHex = Hex.nearestHexCenter(positions[0])
    hexes.push(lastHex)
    positions.forEach(function(position) {
        Hex.addBetweenHexes(lastPosition, position, hexes)
        lastPosition = position
    })
    return hexes
}

// Given a x,z vector, return the side 0-5 it goes towards
Hex.whichSide = function(x, z) {
    var pish = (Math.atan2(x, z) + Math.PI*2)%(Math.PI*2)
    var side = Math.floor(pish/(Math.PI/3))
    return side
}

Hex.allowedSides = function(x, z) {
    var side = Hex.whichSide(x, z)
    return [side, (side+5)%6, (side+1)%6]
}

module.exports = Hex