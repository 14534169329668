var Color = require('./Color.js')
var Tile = require('./Tile.js')
var HexGroup = require('./HexGroup.js')
const State = require('./State.js')

var Define = {}
Define.BoardTile = function(p) {
    var def = p.def
    var hex = p.hex
    if (def === "empty") {
        return [Define.PhoneFrameTile(hex)]
    } else if (def == "Web") {
        return [Define.WebBoardTile(hex)]
    } else if (def == "BlackWhite") {
        return [Define.BlackWhiteTile(hex)]
    } else if (def == "topMenu") {
        return [Define.TopMenu(hex)]
    } else if (def == "share") {
        return [Define.Share(hex)]
    } else if (Color.named.colors[def]) {
        var color = Color.named.colors[def]
        return [Define.ColorBoardTile(color, hex)]
    } else if (def.slice(-6) === "marble") {
        var color = Color.named.colors[def.slice(0,-7)]
        if (!color) {
            color = Color.named.colors["Bay of Many"]
        }
        return [Define.ColorMarbleBoardTile(color, hex)] //, Define.PhoneFrameTile(hex)]
    } else if (def.slice(-5) === "paint") {
        var color = Color.named.colors[def.slice(0,-6)]
        if (!color) {
            color = Color.named.colors["Bay of Many"]
        }
        return [Define.ColorFingerpaintBoardTile(color, hex)]
    } else {
        let sideStrokes = Tile.sideAndStokesFromDef(def)
        return [Define.StrokeBoardTile(sideStrokes.strokes, sideStrokes.side, hex)]
    }
}

// appearance, location
Define.PhoneFrameTiles = function() {
    var frameGroup = HexGroup.rectangleToo([-4,4],[-8,8])
    var tiles = frameGroup.map(function(hex) {
        return Define.PhoneFrameTile(hex)
    })
    return tiles
}

Define.PhoneFrameTile = function(hex) {
    return {
            name: "tile",
            type: "frame",
            shader: "track",
            hex: hex,
            locked: true,
            strokes: [],
            backgroundColor: "#000000",
            hexEdgeColor: "#333333",
            y: State.yFrame,
            on: "inert"
    }
}

Define.WebBoardTile = function(hex) {
    return {
        name: 'tile',
        type: 'web',
        shader: 'web',
        strokes: Tile.webSideAndStrokes(),
        y: State.yGenerator,
        hex: hex,
        on: "uDesignOn",
        generator: true
    }
}

Define.BlackWhiteTile = function(hex) {
    return {
        name: "blackWhite",  // TODO: change to :'tile'
        type: "blackWhite",
        hex: hex,
        y: State.yGenerator,
        on: "blackWhite"
    }
}

Define.TopMenu = function(hex) {
    return {
        strokes: [],
        backgroundColor: Color.colors[Color.schemes[3][1]],
        hexEdgeColor: Color.colors[Color.schemes[3][2]],
        name: "tile",
        label: "houseSVG",
        type: "icon",
        hex: hex,
        y: State.yGenerator,
        topMenu: true
    }
}

Define.Share = function(hex) {
    return {
        strokes: [],
        //backgroundColor: "#fff",
        backgroundColor: Color.colors[Color.schemes[3][1]],
        hexEdgeColor: Color.colors[Color.schemes[3][2]],
        name: "tile",
        label: "shareSVG",
        type: "icon",
        hex: hex,
        y: State.yGenerator,
        on: "share"
    }
}

Define.ColorBoardTile = function(color, hex) {
    return {
        name: "tile",
        shader: "track",
        type: "color",
        hex: hex,
        strokes: [],
        backgroundColor: color,
        hexEdgeColor: Color.colors[2],
        y: State.yGenerator,
        on: "colorOn",
        generator: true
    }
}

Define.ColorFingerpaintBoardTile = function(color, hex) {
    return {
        name: "tile",
        type: "color",
        hex: hex,
        strokes: [],
        backgroundColor: color,
        y: State.yGenerator,
        on: "colorfingerpaint"
    }
}

Define.ColorMarbleBoardTile = function(color, hex) {
    return {
        name: "marble",
        type: "marble",
        hex: hex,
        backgroundColor: color,
        y: State.yGenerator,
        paintTrail: true,
        on: "marbleSwipe"
        //on: Game.marbleSwipeBounceOn
    }
}

Define.StrokeBoardTile = function(strokes, side, hex) {
    var tileStrokes = Tile.addColorToStrokes(strokes,  "#ffffff", "#000000")
    return {
        name: "tile",
        type: "generator",
        shader: "track",
        hex: hex,
        strokes: tileStrokes,
        side: side,
        backgroundColor: State.backgroundColors[0].backgroundColor,
        hexEdgeColor: State.backgroundColors[0].hexEdgeColor,
        y: State.yGenerator,
        on: "uDesignOn",
        generator: true
    }
}

Define.ElementsFromArray = function(array) {
    if (array.length !== 17) { console.log("Board needs to be length 17") }
    var elements = []
    for (var lineNumber=-8; lineNumber <= 8; lineNumber++) {
        var lineHexes = HexGroup.rectangleToo([-5,5], [-lineNumber, -lineNumber])
        var lineDefinition = array[lineNumber+8]
        if (lineDefinition.length !== lineHexes.length) {
            console.log("Line",lineNumber,"not of proper length. Needs,is:",lineHexes.length, lineDefinition.length)
        }
        lineDefinition.forEach(function(def, i) {
            elements.push({
                def: def,
                hex: lineHexes[i]
            })
        })
    }
    return elements
}


module.exports = Define