var State = require('./State.js')
var Color = require('./Color.js')
// The drawing of everything onto a texture

var Draw = {}

Draw.iconOnCtxRaw = function(ctx, path, fillColor, strokeColor, lineWidth, translate, scale, rotate) {
    var iconPath = new Path2D(path)
    ctx.save()
    ctx.translate(translate.x, translate.y)
    ctx.scale(scale.x, scale.y)
    ctx.lineWidth = lineWidth
    ctx.fillStyle = fillColor
    ctx.strokeStyle = strokeColor
    ctx.fill(iconPath)
    ctx.stroke(iconPath)
    ctx.restore()
}

Draw.rotateExecute = function(ctx, rotate, fn) {
    ctx.save()
    ctx.rotate(rotate)
    fn()
    ctx.restore()
}

Draw.iconOnCtx = function(ctx, iconSVG, fillColor, strokeColor) {
    var fillColor = fillColor || "#000"
    var strokeColor = strokeColor || "#000"
    var lineWidth = iconSVG.lineWidth || 80
    var translate = iconSVG.translate || { x: 0, y: 2.8 }
    var scale = iconSVG.scale || { x: 0.022, y: 0.022 }
    var rotate = iconSVG.rotate || 0
    Draw.rotateExecute(ctx, rotate, function() {
        Draw.iconOnCtxRaw(ctx, iconSVG.path, fillColor, strokeColor, lineWidth, translate, scale)
    })
 }

Draw.addIconToTexture = function(texture, iconSVG, fillColor, strokeColor) {
    if (!texture) { return texture }

    var ctx = texture._context
    Draw.iconOnCtx(ctx, iconSVG, fillColor, strokeColor)
    texture.update(false)
    return texture
}

Draw.hotCornerCtx = function(ctx, colors) {
    // cog for configuration
    var gear_colors = [6,7,8,9,10,11,12,13]

    var gear_path = new Path2D(Draw.gearSVG)
    ctx.save()
	ctx.translate(0, -20)
    ctx.scale(6.5,6.5)
    ctx.save()
    ctx.translate(0, 2.8)
    ctx.scale(0.0225, 0.0225)
    ctx.lineWidth = 80
    ctx.fillStyle = "#000"
    ctx.strokeStyle = "#fff"
    ctx.fill(gear_path)
    ctx.stroke(gear_path)
    ctx.restore()
    var overlay_path = Draw.roundedRectanglePath(-2.4, 7.5, 4.6, 8.3, 2)
    gear_colors.forEach(function(d,i) {
        ctx.save()
        ctx.translate(20.15, 22.8)
        ctx.rotate((Math.PI/180)*i*45)
        ctx.fillStyle = colors[d]
        ctx.fill(overlay_path)
        ctx.restore()
    })
    ctx.restore()
}

Draw.roundedRectanglePath = function(x,y,w,h,r) {
    var path = new Path2D()
    path.moveTo(x+r, y)
    path.lineTo(x+w-r, y)
    path.quadraticCurveTo(x+w, y, x+w, y+r);
    path.lineTo(x+w, y+h-r);
    path.quadraticCurveTo(x+w, y+h, x+w-r, y+h);
    path.lineTo(x+r, y+h);
    path.quadraticCurveTo(x, y+h, x, y+h-r);
    path.lineTo(x, y+r);
    path.quadraticCurveTo(x, y, x+r, y)
    return path
}

Draw.diameter = 44
Draw.radius = Draw.diameter/2
Draw.bandWidth = Draw.diameter/10
Draw.edgeWidth = 2

// Originally generated from d3.hexbin
// These are the sides of a hexagon
// Consider re-generating as part of Hex
Draw.normHexPath = "m0,-22l19.05255888325765,10.999999999999998l3.552713678800501e-15,21.999999999999996l-19.05255888325764,11.000000000000005l-19.05255888325766,-10.99999999999999l-1.4210854715202004e-14,-21.999999999999996l19.052558883257635,-11.000000000000014z"

Draw.generateMids = function() {
  var hex_path = Draw.normHexPath
  var hex_path_trimmed = hex_path.slice(1, hex_path.length-1) // remove first and last characters ("m" and "z")
  var movement_string_array = hex_path_trimmed.split("l")
  var movements = movement_string_array.map(function(s) {
    var a = s.split(",")
    var b = a.map(function(s) { return +s })
    return b
  })

  var generic_mids = movements.map(function(m, i, movements) {
    var x = movements[i][0]/2.0
    var y = movements[i][1]/2.0
    for (var j = 0; j < i; j++) {
      x += movements[j][0]
      y += movements[j][1]
    }
    return [x, y]
  })

  generic_mids = generic_mids.slice(1)

  /*
  var calculate_control_points = function(start, end, center) {
    var control_1_x = center[0]*0.46 + start[0]*0.54
    var control_1_y = center[1]*0.46 + start[1]*0.54
    var control_2_x = center[0]*0.46 + end[0]*0.54
    var control_2_y = center[1]*0.46 + end[1]*0.54

    return [
      [control_1_x, control_1_y],
      [control_2_x, control_2_y]
    ]
  }

  var build_svg_curve = function(start_p, c1, c2, end_p) {
    var start = start_p[0]+","+start_p[1]
    var control_1 = c1[0]+" "+c1[1]
    var control_2 = c2[0]+" "+c2[1]
    var end = end_p[0]+" "+end_p[1]

    //C x1 y1, x2 y2, x y
    return "M"+start+"C"+control_1+","+control_2+","+end
  }
*/
   return generic_mids
}

Draw.mids = Draw.generateMids()

// l x, y
Draw.buildSvgLine = function(start_p, end_p) {
    var start = start_p[0]+","+start_p[1]
    var end_x = end_p[0]-start_p[0]
    var end_y = end_p[1]-start_p[1]
    var end = end_x+","+end_y
    //var back = -end_p[0] + "," + (-end_p[1])
    return "m"+start+"l"+end //+"m"+back
}

// l x, y
Draw.buildSvgHalfLine = function(start_p, end_p) {
    var start = start_p[0]+","+start_p[1]
    var end_x = end_p[0]-start_p[0]
    var end_y = end_p[1]-start_p[1]
    var end = end_x/2+","+end_y/2
    //var back = -end_p[0] + "," + (-end_p[1])
    return "m"+start+"l"+end //+"m"+back
}

// "a rx,ry xAxisRotate LargeArcFlag,SweepFlag x,y"
Draw.buildSvgArc = function(start_p, end_p, radius) {
    var start = start_p[0]+","+start_p[1]
    var end = end_p[0]-start_p[0]+","+(end_p[1]-start_p[1])
    //var back = -end_p[0] + "," + (-end_p[1])
    return "m"+start+"a"+radius+","+radius+" 0 0 0 "+end //+"m"+back
}

Draw.buildArc = function(side_i, side_j) {
    var start_p = Draw.mids[side_i]
    var end_p = Draw.mids[side_j]
    var diff = Math.abs(side_i - side_j)
    var result;
    if (diff === 1 || diff == 5) {  // small circle
        result = Draw.buildSvgArc(start_p, end_p, Draw.radius * 0.5)
    } else if (diff === 2 || diff == 4) {  // large circle
        result = Draw.buildSvgArc(start_p, end_p, Draw.radius * 1.5) }
      // straight across
    else if (diff === 3) {
      //var controls = calculate_control_points(start_p, end_p, [0,0])
      //result = build_svg_curve(start_p, controls[0], controls[1], end_p)
      result = Draw.buildSvgLine(start_p, end_p)
    } else { // diff === 0, center to same side
       var otherSide = (side_i + 3)%6
       var other_p = Draw.mids[otherSide]
       result = Draw.buildSvgHalfLine(start_p, other_p)
    }
    return result
}

Draw.calculateSvgSize = function(x_tiles, y_tiles, diameter) {
    // This math is at least good from 2 to 13 with diameter 96
    var width = diameter * (x_tiles * 0.87 + 0.44 ) + 1.5
    var height = diameter * (y_tiles * 0.75 + 0.28 )
    return {
      width: width,
      height: height
    }
}

Draw.tileCtx = function(ctx, p) {
    var strokes = p.strokes || []
    var hex_edge_on = (p.hexEdgeOn  && State.hexEdgesOn)
    var center = p.center
    var rotation = p.rotation
    var backgroundColor = p.backgroundColor
    var hexEdgeColor = p.hexEdgeColor
    var hex_path = new Path2D("M"+center[0]+","+center[1]+Draw.normHexPath)
    // Draw tile background
    ctx.fillStyle = backgroundColor
    ctx.fill(hex_path)
    // Draw tile edge (perhaps)
    ctx.lineWidth = Draw.edgeWidth
    if (hex_edge_on) {
        ctx.strokeStyle = hexEdgeColor
        ctx.stroke(hex_path)
    } else {
        ctx.strokeStyle = backgroundColor
        ctx.stroke(hex_path)
    }
    ctx.strokeStyle = hexEdgeColor
    strokes.forEach(function(stroke) {
        var s = stroke.stroke
        // Tile path
        var arc_path = "M"+center[0]+","+center[1]
        var s_0 = (s[0]+rotation)%6
        var s_1 = (s[1]+rotation)%6
        arc_path += Draw.buildArc(s_0, s_1)
        var stroke_path = new Path2D(arc_path)
        // Edge (under)
        ctx.lineWidth = Draw.bandWidth + 3 // edge size: 3.
        ctx.strokeStyle = stroke.edgeColor
        ctx.stroke(stroke_path)
        // Band (over)
        ctx.lineWidth = Draw.bandWidth
        ctx.strokeStyle = stroke.bandColor
        ctx.stroke(stroke_path)
    })
}

Draw.ctxScale = 1.465 // 2.7 //5.4  // Magic? Yes.
Draw.defaultTextureSize = 64
Draw.textureCenter = [Draw.defaultTextureSize/(Draw.ctxScale*2), Draw.defaultTextureSize/(Draw.ctxScale*2)]

Draw.writeHexTexture = function(ctx, p) {
	Draw.tileCtx(ctx, {
        strokes: p.strokes,
        backgroundColor: p.backgroundColor,
        hexEdgeColor: p.hexEdgeColor,
        hexEdgeOn: p.hex_outline,
        center: [p.textureSize/(Draw.ctxScale*2), p.textureSize/(Draw.ctxScale*2)],
        rotation: 0,
    })
}

Draw.pEqual = function(p1, p2) {
    if (p1.backgroundColor !== p2.backgroundColor) { return false }
    if (p1.hexEdgeColor !== p2.hexEdgeColor) { return false }
    if (p1.hex_outline !== p2.hex_outline) { return false }
    if (p1.size !== p2.size) { return false }
    if (!p1.strokes && !p2.strokes) { return true }
    if (!p1.strokes) { return false }
    if (!p2.strokes) { return false }
    if (p1.strokes.length !== p2.strokes.length) { return false }
    for (var i = 0; i < p1.strokes.length; i++) {
        if (p1.strokes[i].stroke[0] !== p2.strokes[i].stroke[0]) { return false }
        if (p1.strokes[i].stroke[1] !== p2.strokes[i].stroke[1]) { return false }
        if (p1.strokes[i].bandColor !== p2.strokes[i].bandColor) { return false }
        if (p1.strokes[i].edgeColor !== p2.strokes[i].edgeColor) { return false }
    }
    return true
}

Draw.checkTextureCache = function(p) {
    if (p.nocache) {
        return null
    }
    for (var i=0; i < State.textureCache.length; i++) {
        if (Draw.pEqual(p, State.textureCache[i].p)) {
            return State.textureCache[i]
        }
    }
    return null
}

Draw.generateHexTexture = function(p) {
    var cacheHit = Draw.checkTextureCache(p)
    if (cacheHit !== null) {
        return cacheHit.texture
    }
    // p.textureSize = 64 // 32 // 64 // 128 // 256
	var dynamicTexture = new BABYLON.DynamicTexture("DynamicTexture", p.textureSize, State.scene, true);
	dynamicTexture.hasAlpha = false;
	var ctx = dynamicTexture._context
    ctx.scale(Draw.ctxScale,Draw.ctxScale) // Scale only once.
    Draw.writeHexTexture(ctx, p)
	dynamicTexture.update(false)
	dynamicTexture.wAng = Math.PI/2 // Rotate the texture for same orientation
    State.textureCache.push({
        p: p,
        texture: dynamicTexture
    })
	return dynamicTexture
}

Draw.redrawHexTexture = function(texture, p) {
	var ctx = texture._context
    Draw.writeHexTexture(ctx, p)
	texture.update(false)
	return texture
}

Draw.addLabelToTexture = function(texture, label) {
    let lines = label.split(" ")
    if (lines.length == 1) {
        return Draw.addLabelToTextureLine(texture, label, 0)
    } else if (lines.length == 2) {
        var t = Draw.addLabelToTextureLine(texture, lines[0], -8)
        return Draw.addLabelToTextureLine(texture, lines[1], 8)
    }
    return texture
}

Draw.addLabelToTextureLine = function(texture, label, yAdjustment) {
    if (!texture) { return texture }
    // Placement adjustment by length of text
    var string = "" + label
    var x = 15
    var y = 30 + (yAdjustment || 0)
    var fontSize = 24 // px
    if (string.length >= 2) { fontSize = 20; x -= 6 }
    if (string.length >= 3) { fontSize = 15; x -= 3 }
    if (string.length >= 4) { fontSize = 13; y -= 2 }
    if (string.length >= 5) { fontSize = 11; y -= 1 }
    if (string.length >= 6) { fontSize = 10; y -= 1 }
    if (string.length >= 7) { fontSize = 8; y -= 0 }
    // TODO: If need more than 7 characters, go to 2 lines
    // TODO: If need more than 14 characters, go to 3 lines

    var ctx = texture._context
    // Order for ctx.font matters.
    ctx.font = "bold " + fontSize + "px sans-serif"
    ctx.lineWidth = 3
    ctx.strokeStyle = "white"
    ctx.strokeText(string, x, y)
    ctx.fillStyle = "black"
    ctx.fillText(string, x, y)
    texture.update(false)
    return texture
}

Draw.addLabelToTextureOffsetCenter = function(texture, label, dx, dy) {
    if (!texture) { return texture }
    // Placement adjustment by length of text
    var string = "" + label
    var x = 21 + dx
    var y = 26 + dy
    var fontSize = 10 // px

    var ctx = texture._context
    // Order for ctx.font matters.
    ctx.font = "bold " + fontSize + "px sans-serif"
    ctx.lineWidth = 3
    ctx.strokeStyle = "white"
    ctx.strokeText(string, x, y)
    ctx.fillStyle = "black"
    ctx.fillText(string, x, y)
    texture.update(false)
    return texture
}

// TODO Call .writeHexTexture from Board. for each tile.
Draw.thumbnailTexture = function(p) {
	var dynamicTexture = new BABYLON.DynamicTexture("DynamicTexture", p.size, State.scene, true);
	dynamicTexture.hasAlpha = false;
	var ctx = dynamicTexture._context
    ctx.scale(Draw.ctxScale*5,Draw.ctxScale*5) // Scale only once. Perhaps / 5
    return dynamicTexture
}

Draw.generateMaterial = function(name, texture) {
  var material = new BABYLON.StandardMaterial(name, State.scene);
  // All four are needed for point light to look right. Other lighting might
  // make one or more of these moot.
  material.diffuseTexture = texture
  material.specularTexture = texture
  //material.emissiveTexture = texture
  //material.ambientTexture = texture
  //material.useLogarithmicDepth = true // Improves layering/depth calculation
  return material
}

Draw.colorMaterial = function(hexColor) {
  var color3 = new BABYLON.Color3.FromHexString(hexColor)
  var material = new BABYLON.StandardMaterial(name, State.scene);
  // All four are needed for point light to look right. Other lighting might
  // make one or more of these moot.
  material.diffuseColor = color3
  material.specularColor = color3
  //material.emissiveColor = color3
  //material.ambientColor = color3
  //material.useLogarithmicDepth = true // Improves layering/depth calculation
  return material
}

function setColor(mesh, color) {
    var v; //facet verticex
    var verticesWithColor = new Set();
    var colors = [];
    var indices = mesh.getIndices();
    for (var f = 0; f < indices.length / 3; f++) {
        for (var i = 0; i < 3; i++) {
            v = indices[3 * f + i];
            if (!verticesWithColor.has(v)) {
                verticesWithColor.add(v);
                colors[4 * v] = color.r;
                colors[4 * v + 1] = color.g;
                colors[4 * v + 2] = color.b;
                colors[4 * v + 3] = color.a;
            }
        }
    }
    mesh.setVerticesData(BABYLON.VertexBuffer.ColorKind, colors);
}

Draw.generateBlackWhiteHexTile = function(scene, name) {
    let mesha = BABYLON.MeshBuilder.CreateDisc("bw", {radius: 16, tessellation: 6, updatable: true});
    mesha.position.z = -0.001
    var meshb = BABYLON.MeshBuilder.CreateDisc("bw", {radius: 16, tessellation: 4, updatable: true, arc: 0.5});
    meshb.position.z = -0.0011

    var white = new BABYLON.Color4(1.0, 1.0, 1.0, 1);
    var black = new BABYLON.Color4(0.2, 0.2, 0.2, 1);

    setColor(mesha, black)
    setColor(meshb, white)
    var mesh = BABYLON.Mesh.MergeMeshes([mesha, meshb])
    mesh.rotate(BABYLON.Axis.X, Math.PI / 2, BABYLON.Space.WORLD)
    mesh.rotate(BABYLON.Axis.Y, Math.PI / 6 * 7, BABYLON.Space.WORLD)

    // Material

    var mat =  new BABYLON.StandardMaterial("bw", State.scene);
    mat.specularColor = BABYLON.Color3.Black()
    // Only reason to use material:
    //mat.useLogarithmicDepth = true

    mesh.material = mat

    return mesha
}

Draw.generateHexTile = function(scene, name, material) {
  var hexigon = BABYLON.MeshBuilder.CreateDisc(name, {radius: 16, arc: 1, tessellation: 6, sideOrientation: BABYLON.Mesh.DOUBLESIDE}, scene);
  hexigon.material = material
  hexigon.rotate(BABYLON.Axis.X, Math.PI / 2, BABYLON.Space.WORLD)
  hexigon.rotate(BABYLON.Axis.Y, Math.PI / 2, BABYLON.Space.WORLD)
  return hexigon
}

Draw.generateDebugHexTile = function(scene) {
    var options = {
        radius: 16,
        arc: 1,
        tessellation: 6,
        sideOrientation: BABYLON.Mesh.DOUBLESIDE,
        updateable: true
    };
    var mesh = BABYLON.MeshBuilder.CreateDisc("gray", options, scene);
    mesh.rotate(BABYLON.Axis.X, Math.PI / 2, BABYLON.Space.WORLD)
    mesh.rotate(BABYLON.Axis.Y, Math.PI / 2, BABYLON.Space.WORLD)

    var mesh = BABYLON.MeshBuilder.CreateIcoSphere("m", {radius: 2.0, updatable: true}, scene);
    mesh.convertToFlatShadedMesh();
    var indices = mesh.getIndices();

    var v; //facet vertex
    var verticesWithColor = new Set();
    var colors = [];
    for (var f = 0; f < indices.length / 3; f++) {
        var color = new BABYLON.Color4(Math.random(), Math.random(), Math.random(), 1);
        for (var i = 0; i < 3; i++) {
            v = indices[3 * f + i];
            if (!verticesWithColor.has(v)) {
                verticesWithColor.add(v);
                colors[4 * v] = color.r;
                colors[4 * v + 1] = color.g;
                colors[4 * v + 2] = color.b;
                colors[4 * v + 3] = color.a;
            }
        }
    }
    mesh.setVerticesData(BABYLON.VertexBuffer.ColorKind, colors);

    return mesh
  }

Draw.generateAndPlaceHexTile = function(scene, material, d) {
  var hexigon = Draw.generateHexTile(scene, d.name, material)
  hexigon.convertToUnIndexedMesh()

  hexigon.position.y = d.y
  hexigon.position.z = d.z
  hexigon.position.x = d.x
  hexigon.side = d.side | 0
  if (hexigon.side !== 0) {
    hexigon.rotate(BABYLON.Axis.Y, (Math.PI/3)*d.side, BABYLON.Space.WORLD)
  }
  return hexigon
}


Draw.marbleColorMaterial = function(color) {
    var mat = new BABYLON.StandardMaterial("mat", State.scene);
    var color3 = BABYLON.Color3.FromHexString(color)
	mat.ambientColor = color3
	mat.specularColor = BABYLON.Color3.White()
	mat.diffuseColor = color3
    //mat.emissiveColor = color
    //mat.useLogarithmicDepth = true
    return mat
}

Draw.makeMarble = function(color) {
    if (!State.marbleMaterial) {
        var marbleMaterial = new BABYLON.StandardMaterial("marble", State.scene);
        var marbleTexture = new BABYLON.MarbleProceduralTexture("marble", 512, State.scene);
        marbleTexture.numberOfBricksHeight = 1;
        marbleTexture.numberOfBricksWidth = 1;
        marbleMaterial.ambientTexture = marbleTexture;
        //marbleMaterial.useLogarithmicDepth = true // Improves layering/depth calculation
        State.marbleMaterial = marbleMaterial
    }
    if (!State.marbleColorMaterials) {
        var marbleColorMaterials = {}
        Color.colors.forEach((color) => {
            marbleColorMaterials[color] = Draw.marbleColorMaterial(color)
        })
        State.marbleColorMaterials = marbleColorMaterials
    }
    var marbleDiameter=20
    // Add and manipulate meshes in the scene
    var marble = BABYLON.MeshBuilder.CreateSphere("marble", {diameter: marbleDiameter, updatable: true}, State.scene);
    marble.diameter = marbleDiameter
    if (color) {
        // All marble color materials are pre-built
        marble.material = State.marbleColorMaterials[color]
        marble.color = color
    } else {
        console.log('color not defined')
        marble.material = State.marbleMaterial
    }
    //marble.material.useLogarithmicDepth = true // Improves layering/depth calculation

    marble.position.y = marbleDiameter //2 + 1

    marble.parent = State.ground
    marble.id = State.tileId++

    return marble
}

Draw.makeClock = function() {
    var filename = "jiyeon-park-224073-unsplash.jpg"
}

// Unplaced label tile
Draw.labeledHexTile = function(name, label) {
  var dynamicTexture = Draw.generateHexTexture({
	    strokes: [],
        backgroundColor: "#ddd",
        hexEdgeColor: "#fff",
		hex_outline: true,
		textureSize: 64
  })

  Draw.addLabelToTexture(dynamicTexture, label)

  var material = Draw.generateMaterial("tileMat", dynamicTexture)

  var tile = Draw.generateHexTile(State.scene, name, label)

  tile.parent = State.ground

  return tile
}

Draw.generateTransparentHexTexture = function(p) {
    // p.size = 64 // 32 // 64 // 128 // 256
	var dynamicTexture = new BABYLON.DynamicTexture("DynamicTexture", p.size, State.scene, true);
	dynamicTexture.hasAlpha = true;
	var ctx = dynamicTexture._context
    ctx.scale(Draw.ctxScale,Draw.ctxScale) // Scale only once.
    Draw.writeHexTexture(ctx, p)
	dynamicTexture.update(false)
	dynamicTexture.wAng = Math.PI/2 // Rotate the texture for same orientation
	return dynamicTexture
}

Draw.generateTransparentMaterial = function(name, texture) {
  var material = new BABYLON.StandardMaterial(name, State.scene);
  material.diffuseTexture = texture
  material.opacityTexture = texture

  material.specularTexture = texture
  material.emissiveTexture = texture
  material.ambientTexture = texture

  material.hasAlpha = true
  //material.useLogarithmicDepth = true // Improves layering/depth calculation
  return material
}

Draw.guideHexTile = function(name) {
  var dynamicTexture = Draw.generateHexTexture({
	    strokes: [],
        backgroundColor: "transparent",
        hexEdgeColor: "transparent",
		hex_outline: true,
		textureSize: 64
  })

  var textureCenter = Draw.textureCenter
  var x = textureCenter[0]
  var y = textureCenter[1]
  var ctx = dynamicTexture._context

  ctx.fillStyle = "#ffffff"
  ctx.beginPath()
  ctx.strokeStyle = "#000000"
  ctx.arc(x, y, 7, 0, 2 * Math.PI)
  ctx.stroke()
  ctx.beginPath()
  ctx.strokeStyle = "#ffffff"
  ctx.arc(x, y, 6, 0, 2 * Math.PI)
  ctx.moveTo(x+6,y)
  ctx.lineTo(x+20,y)
  ctx.moveTo(x-6,y)
  ctx.lineTo(x-20,y)
  ctx.moveTo(x,y+6)
  ctx.lineTo(x,y+20)
  ctx.moveTo(x,y-6)
  ctx.lineTo(x,y-20)
  ctx.stroke()

  dynamicTexture.update(false)

  var material = Draw.generateTransparentMaterial("tileMat", dynamicTexture)

  var tile = Draw.generateHexTile(State.scene, name, material)

  tile.parent = State.ground

  return tile
}

Draw.fullScreenPaintCanvas = function() {
    // TODO get canvas width and height

    // Want to create a texture that will cover the whole screen in high definition
    var size = 1024
//	var dynamicTexture = new BABYLON.DynamicTexture("DynamicTexture", size, State.scene, true)
  var dynamicTexture = Draw.generateHexTexture({
	    strokes: [],
        backgroundColor: "transparent",
        hexEdgeColor: "transparent",
		hex_outline: false,
		textureSize: 2048
  })

    var material = Draw.generateTransparentMaterial("paintballCanvas", dynamicTexture)
    var plane = BABYLON.MeshBuilder.CreatePlane("plane", {width: size, height: size}, State.scene)
    plane.material = material
    // Make it good in the x-z plane (exchange y, z, by rotating on x)
    plane.rotate(BABYLON.Axis.X, Math.PI / 2, BABYLON.Space.WORLD)
    plane.rotate(BABYLON.Axis.Y, Math.PI / 2, BABYLON.Space.WORLD)
    plane.position.y = 0.1
    // This doesn't work. Needed to use predicate in Input
    // plane.isPickable = false
    plane.parent = State.ground

    var ctx = dynamicTexture._context
    ctx.beginPath()
    ctx.strokeStyle = "#ffffff"
    ctx.arc(Draw.textureCenter[0]*32, Draw.textureCenter[1]*32, 600, 0, 2 * Math.PI)
    ctx.stroke()
    dynamicTexture.update(false)

    // TODO Marble trace on it
    return plane
}

Draw.worldToCanvas = function(position) {
    var canvasCenter = Draw.textureCenter[0]*32
    // The canvas is mirrored. Whatever. :)
    var canvasX = -position.x * 1.36 + canvasCenter
    var canvasY = -position.z * 1.36 + canvasCenter
    return {
        x: canvasX,
        y: canvasY
    }
}

Draw.smallCircleOnCanvas = function(position, size, color) {
    var size = size || 4
    var color = color || "#ffffff"
    var canvasXY = Draw.worldToCanvas(position)
    var mesh = State.paintCanvas
    var material = mesh.material
    var texture = material.diffuseTexture
    var ctx = texture._context
    ctx.beginPath()
    ctx.fillStyle = color
    if (color === "#000000") {
        ctx.shadowColor = "#AAAAAA"
    } else {
        ctx.shadowColor = "#000000"
    }
    ctx.shadowBlur = Math.floor((size+2)/4)
    ctx.shadowBlurOffsetY = -Math.floor((size+4)/4)
    ctx.arc(canvasXY.x, canvasXY.y, size, 0, 2 * Math.PI)
    ctx.fill()
    texture.update(false)
}

Draw.trailOnCanvas = function(positionA, positionB, size, color) {
    var size = size || 4
    var color = color || "#ffffff"
    var canvasXYa = Draw.worldToCanvas(positionA)
    var canvasXYb = Draw.worldToCanvas(positionB)
    var distanceX = Math.abs(canvasXYa.x - canvasXYb.x)
    var distanceY = Math.abs(canvasXYa.y - canvasXYb.y)
    var maxDistance = Math.max(distanceX, distanceY)
    // Could calculate the diagonal instead and move by 1 or whatever along it
    var steps = Math.floor(maxDistance/1)  // Step by 1
    var stepX = (canvasXYb.x - canvasXYa.x)/steps
    var stepY = (canvasXYb.y - canvasXYa.y)/steps
    var mesh = State.paintCanvas
    var material = mesh.material
    var texture = material.diffuseTexture
    var ctx = texture._context
    for (var i=0; i < steps; i++) {
      ctx.beginPath()
      ctx.fillStyle = color
      if (color === "#000000") {
         ctx.shadowColor = "#AAAAAA"
      } else {
         ctx.shadowColor = "#000000"
      }
      ctx.shadowBlur = Math.floor((size+2)/4)
      ctx.shadowBlurOffsetY = -Math.floor((size+4)/4)
      ctx.arc(canvasXYa.x+(i*stepX), canvasXYa.y+(i*stepY), size, 0, 2 * Math.PI)
      ctx.fill()
    }
    State.updatePaintCanvasTexture = true
}

Draw.updatePaintCanvasTexture = function() {
    if (State.updatePaintCanvasTexture) {
        var mesh = State.paintCanvas
        var material = mesh.material
        var texture = material.diffuseTexture
        texture.update(false)
        State.updatePaintCanvasTexture = false
    }
}


function makeDataFromHexStrings(colors) {
    // Just make it square. Still tiny.
    var arraySize = colors.length*4

    var data = new Uint8Array(arraySize)

    colors.forEach(function(color, i) {
        var bColor = BABYLON.Color4.FromHexString(color)
        data[i*4  ] = Math.round(bColor.r*255)
        data[i*4+1] = Math.round(bColor.g*255)
        data[i*4+2] = Math.round(bColor.b*255)
        data[i*4+3] = Math.round(bColor.a*255)
    })
  return data
}
Draw.makeDataFromHexStrings = makeDataFromHexStrings

function rawTextureFromData(data, width, height, scene) {
  var texture = new BABYLON.RawTexture(
    data,
    width,
    height,
    BABYLON.Engine.TEXTUREFORMAT_RGBA, // format
    scene,
    false, // gen mipmaps
    false, // invertY
    BABYLON.Texture.NEAREST_SAMPLINGMODE,
    BABYLON.Engine.TEXTURETYPE_UNSIGNED_INT
  )
  texture.hasAlpha = true
  return texture
}
Draw.rawTextureFromData = rawTextureFromData

function fatArc(radius, width, radians) {
    // Heristic: one segment for every PI/64 "smooth enough"
    var segments = Math.ceil(radians/(Math.PI/64))
    var points = segments + 1
    var halfWidth = width/2
    var innerRadius = radius - halfWidth
    var outerRadius = radius + halfWidth

    var arc1 = [];
    var arc2 = [];
    var rad = 0
    for (var i = 0; i < points; i++) {
        rad = radians*i/segments
        arc1.push(new BABYLON.Vector3(innerRadius*Math.cos(rad), innerRadius*Math.sin(rad), 0));
        arc2.push(new BABYLON.Vector3(outerRadius*Math.cos(rad), outerRadius*Math.sin(rad), 0));
    }
    var ribbon = BABYLON.MeshBuilder.CreateRibbon("ribbon", {pathArray: [arc1, arc2]});
    return ribbon
}
Draw.fatArc = fatArc

module.exports = Draw
