var State = require('./State.js')
var Hex = require('./Hex.js')

// Log has four levels:
// raw - raw events
// mug - mug until good... just the data we needed (in theory)
// intersection - context for mug
// change - world change, aka event sourcing.

// Log is the writer of all information to "external" places
// See Play for the reader of (some of) this information

// TODO Remove from implementation and add when there is use-case
// TODO Write use-cases :)

var Log = {}

// This is where browser event filtering occurs
//Log.aBrowserEvent = function(evt) { Log.log("mug", evt) }
Log.aBrowserEvent = function(e) {
//    console.warn(e)
} // noop

Log.selectedPointerEvent = function(e) {
    var norm = Log.mugEvent(e)
    var kind = (('ontouchstart' in window)?'touch':'pointer')
    Log.screen(JSON.stringify(norm))
}

Log.screen = function(s) {
    var element = document.getElementById("logit")
    var text = element.textContent || element.innerText;
    element.innerHTML = s+"|-|"+text
}

Log.browserChange = function(change) { Log.log("browserChange", change) }
Log.gameChange = function(change) { Log.log("gameChange", change) }

Log.normalizeRawEvent = function(evt) {
  var norm = {}
  for (var property in evt) {
    // Don't copy DOM, but copy all else
    if (property === "target") {
          norm[property] = evt[property].parentNode.innerHTML
    } else if (evt[property] instanceof Node) {
        //console.log('Node')
    } else if (evt[property] instanceof Window) {
        //console.log('Window')
    } else if (property === "path") {
        //console.log('path')
    } else {
        //console.log(property, evt[property])
        norm[property] = evt[property]
    }
  }
  return norm
}

Log.mugEventFieldsLots = [
    "pointerId","width","height","pressure","tiltX","tiltY","tangentialPressure","twist","pointerType","isPrimary","screenX","screenY","clientX","clientY","ctrlKey","shiftKey","altKey","metaKey","button","buttons","relatedTarget","pageX","pageY","x","y","offsetX","offsetY","movementX","movementY","fromElement","layerX","layerY","detail","sourceCapabilities","which","NONE","CAPTURING_PHASE","AT_TARGET","BUBBLING_PHASE","type","eventPhase","bubbles","cancelable","defaultPrevented","composed","timeStamp","returnValue","cancelBubble","type"
]

Log.mugEventFieldsSome = [
    "pointerId","width","height","pressure","tiltX","tiltY","tangentialPressure","twist","pointerType","isPrimary","screenX","screenY","clientX","clientY","pageX","pageY","x","y","offsetX","offsetY","movementX","movementY","fromElement","layerX","layerY","timeStamp","type"
]

// clientX/clientY - current view
// pageX/pageY - whole page
//   For a full-screen WebGL app, these "should" be the same
// pointerId, and isPrimary: for multi-touch
Log.mugEventFieldsMin = [
    "pointerId","isPrimary","clientX","clientY","pageX","pageY","movementX","movementY","timeStamp","type"
]

Log.mugEventFields = Log.mugEventFieldsMin

Log.mugEvent = function(evt) {
  var norm = {}
  for (var property in evt) {
    if (Log.mugEventFields.indexOf(property) !== -1) {
        norm[property] = evt[property]
    }
  }
  return norm
}

/*
// Untested
// If func1 changes arguments, they get changed in func2
// If needed, an Object.apply can be used
Log.tee = function(func1, func2) {
    return function(...args) {
        var a = func1(...args)
        var b = func2(...args)
        return [a,b]
    }
}
*/

Log.persistBacklog = []
Log.persist = function(norm) {
    if (State.socket && State.fingerprint) {
        if (Log.persistBacklog.length > 0) {
          Log.persistBacklog.push(norm)
          var backlog  = Log.persistBacklog.shift()
          while (backlog) {
            backlog.fingerprint = State.fingerprint
            State.socket.emit("log", backlog)
            backlog = Log.persistBacklog.shift()
          }
        } else {
          State.socket.emit("log", norm)
        }
    } else {
        Log.persistBacklog.push(norm)
    }
}

// For debugging, tiles are returned in fractional format: [float, float, side]
Log.tilesFromScene = function(scene) {
  var meshes = scene.meshes
  var name = "tile"
  var tiles = []
  meshes.forEach(function(mesh) {
    if (mesh.name === name) {
      var fractionalHex = Hex.worldToFractionalHex(mesh.position,  Hex.worldHexRatio)
      var side = Hex.worldRotationToSide(mesh.rotation)
      fractionalHex.push(side)
      tiles.push(fractionalHex)
    }
  })
  return tiles
}


Log.snapshot = function(scene) {
  var pieces = []
  scene.meshes.forEach(function(mesh) {
    if (mesh.name && mesh.hex && mesh.name !== "choice") {
        //console.log(mesh.name)
        var piece = Object.assign({},mesh.def)
        piece.name = mesh.name
        piece.hex = mesh.hex
        piece.strokes = mesh.strokes
        piece.backgroundColor = mesh.backgroundColor
        piece.hexEdgeColor = mesh.hexEdgeColor
        pieces.push(piece)
    } else if (mesh.name === "marble") {
        var piece = Object.assign({}, mesh.def)
        piece.name = mesh.name
        piece.hex = Hex.worldToFractionalHex(mesh.position)
        pieces.push(piece)
    }
  })
  return {
      pieces: pieces
  }
}

Log.snapshotEnabled = true
Log.takeSnapshot = function() {
  if (Log.snapshotEnabled) {
    var snapshot = Log.snapshot(State.scene)
    snapshot.fingerprint = State.fingerprint
    var JSONsnapshot = JSON.stringify(snapshot)
    localStorage.setItem("snapshot", JSONsnapshot)
    if (State.socket) {
      State.socket.emit("snapshot", JSONsnapshot)
    }
  }
}

Log.replaying = false

Log.thisGameIndex = function() {
    return Log.previousGameIndex(Log.localCache.length)
}

Log.previousGameIndex = function(gameIndex) {
    var i = gameIndex-1
    while (i >= 0) {
        if (Log.localCache[i].type === "rewindToGame") {
            var gameTime = Log.localCache[i].gameTime
            while(i >= 0 && Log.localCache[i].clientTime > gameTime) {
                if (Log.localCache[i].type === "rewindToGame") {
                    console.log("rewindToGame during rewindToGame")
                }
                i--
            }
            return i
        } else if (Log.localCache[i].type === "newGame") {
            return i
        }
        i--
    }
    // Not found
    return -1
}

Log.log = function(type, data) {
    if (Log.replaying) {
        return
    }
    var now = Date.now()
    // All records include a clientTime and fingerprint
    var norm = {
        clientTime: now,
        fingerprint: State.fingerprint,
        type: type
    }
    // The choice here was to keep everything on the same level. Flat (vs tree) format.
    // Many loggers make the same choice.
    // If there is a namespace conflict, it needs to be taken care in the client code:
    // here (not preferred) or elsewhere (preferred).
    // TODO dispatch if there is more than browser event mugging (mug until good)
    if (type === "mug") {
        var mug = Log.mugEvent(data)
        // Note that this will most likely overwrite the type field assigned above
        // As of this writing, this is not considered a bug
        Object.assign(norm, mug)
    } else {
        // Here, as well, the type field is overwritten with the more specific type
        Object.assign(norm, data)
    }
    Log.persist(norm)
}

Log.debug = function() {}


module.exports = Log
