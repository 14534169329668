// Generative data and utility functions for strokes on hex tiles

var Tile = {}

// ======================================================
//             Tile Strokes
// ======================================================
// Arcs between sides 0 to 5
Tile.arcs = [
  // zero arc
  [],
  // one arc
  [[0, 1]],
  [[0, 2]],
  [[0, 3]],
  // two arcs
  [[0, 1], [2, 3]],
  [[0, 1], [2, 4]],
  [[0, 1], [3, 4]],
  [[0, 1], [3, 5]],
  [[0, 1], [2, 5]],
  [[0, 2], [3, 5]],
  [[0, 2], [1, 3]],
  [[1, 3], [0, 2]],
  [[0, 2], [1, 4]],
  [[1, 4], [0, 2]],
  [[0, 3], [1, 4]],
  [[1, 4], [0, 3]],
  // three arcs
  [[0, 1], [2, 3], [4, 5]],
  [[0, 1], [2, 5], [3, 4]],
  [[0, 2], [1, 3], [4, 5]],
  [[1, 3], [0, 2], [4, 5]]
  //[[0, 2], [3, 5], [1, 4]],
  //[[0, 2], [1, 4], [3, 5]],
  //[[3, 5], [1, 4], [0, 2]]
]
Tile.curvesOnly =  [1, 2, 4, 5, 6, 7, 9, 10, 11, 16, 18, 19]
Tile.noBlank = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19]

// Mini language to design boards
Tile.named = {}

Tile.named.curves = ["c","C","cca","cCa","ccb","cCb","CC","CCxa","CCxb","ccc","cCCxa","cCCxb"]
Tile.named.doubleCurves = ["cca", "cCa", "ccb", "cCb", "CC", "CCxa", "CCxb", "ccc", "cCCxa", "cCCxb"]

Tile.named.triples = ["ccc", "cCCxa", "cCCxb", "clc"]

Tile.named.strokes = {
  // zero arc
  blank: [],
  b: [],
  // one arc
  c: [[0, 1]],
  C: [[0, 2]],
  l: [[0, 3]],
  // two arcs
  cca: [[0, 1], [2, 3]],
  cCa: [[0, 1], [2, 4]],
  ccb: [[0, 1], [3, 4]],
  cCb: [[0, 1], [3, 5]],
  cl: [[0, 1], [2, 5]],
  CC: [[0, 2], [3, 5]],
  CCxa: [[0, 2], [1, 3]],
  CCxb: [[1, 3], [0, 2]],
  Clxa: [[0, 2], [1, 4]],
  Clxb: [[1, 4], [0, 2]],
  llxa: [[0, 3], [1, 4]],
  llxb: [[1, 4], [0, 3]],
  // three arcs
  ccc: [[0, 1], [2, 3], [4, 5]],
  clc: [[0, 1], [2, 5], [3, 4]],
  cCCxa: [[0, 2], [1, 3], [4, 5]],
  cCCxb: [[1, 3], [0, 2], [4, 5]]
  //[[0, 2], [3, 5], [1, 4]],
  //[[0, 2], [1, 4], [3, 5]],
  //[[3, 5], [1, 4], [0, 2]]
}

Tile.sideAndStokesFromDef = function(def) {
  var strokes = Tile.named.strokes[def]
  var side = 0
  if (!strokes) {
      var lastC = def.slice(-1)
      if ('012345'.indexOf(lastC) !== -1) {
          strokes = Tile.named.strokes[def.slice(0,-1)]
          side = +lastC
      }
      strokes = strokes || []
  }
  return {
    side: side,
    strokes: strokes
  }
}


Tile.webSideAndStrokes = function() {
  let strokes = [[0,0],[1,1],[2,2],[3,3],[4,4],[5,5]]
  return strokes.map((s) => {
      return { side: 0, stroke: s}
  })
}

Tile.normalizedArcs = function(strokes, side) {
  var translated = strokes.map(function(s) {
    return [(s.stroke[0]+side)%6, (s.stroke[1]+side)%6]
  })
  return translated
}

Tile.normalizedStrokes = function(strokes, side) {
  var translated = strokes.map(function(s) {
    return {
       stroke: [(s.stroke[0]+side)%6, (s.stroke[1]+side)%6],
       edgeColor: s.edgeColor,
       bandColor: s.bandColor
    }
  })
  // TODO Likely need to sort here
  return translated
}

Tile.rotateByOneSide = function(rotation) {
  return (rotation+1)%6  // +1 side
}

// TODO: Test
Tile.deleteArcsIfExist = function(tile, template) {
  var numberStrokesDeleted = 0
  var normTileArcs = Tile.normalizedArcs(tile.strokes, tile.side)
  var normTemplateArcs = Tile.normalizedArcs(tempate.strokes, template.side)
  // Assuming that the normArcs index is the same as tile.strokes index
  for (var j=normTileArcs.length-1; j>=0; j--) {
    for (var i=0; i<normTemplateArcs.length; i++) {
      if (normTileArcs[j][0] === normTemplateArcs[i][0] &&
          normTileArcs[j][1] === normTemplateArcs[i][1]) {
        tile.strokes.spice(j, 1)
        numberStrokesDeleted += 1
      }
   }
  }
  return numberStrokesDeleted
}

Tile.addArcsIfDoNotExist = function(tile, template) {
  var numberStrokesAdded = 0
  var normTileArcs = Tile.normalizedArcs(tile.strokes, tile.side)
  var normTemplateArcs = Tile.normalizedArcs(tempate.strokes, template.side)
  // Assuming that the normArcs index is the same as tile.strokes index
  for (var j=normTileArcs.length-1; j>=0; j--) {
    for (var i=0; i<normTemplateArcs.length; i++) {
      // Must not match either end
      if (normTileArcs[j][0] !== normTemplateArcs[i][0] &&
          normTileArcs[j][1] !== normTemplateArcs[i][1]) {
        // TODO: add default bandColor and edgeColor to tile.def
        tile.strokes.push({
          stroke: normTemplateArcs[i],
          bandColor: "#FFFFFF",
          edgeColor: "#000000"
        })
        numberStrokesAdded += 1
      }
   }
  }
  return numberStrokesAdded
}

Tile.arcsEqual = function(tileA, tileB) {
  if (tileA.strokes.length !== tileB.strokes.length) { return false }
  var strokesA = Tile.normalizedArcs(tileA.strokes, tileA.side)
  var strokesB = Tile.normalizedArcs(tileB.strokes, tileB.side)
  for (var j=0; j<strokesA.length; j++) {
    var strokeA = strokesA[j]
    var match = false
    for (var i=0; i<strokesB.length; i++) {
      var strokeB = strokesB[i]
      if (strokeA[0] === strokeB[0]) {
        if (strokeA[1] === strokeB[1]) {
         match = true
        } else {
          return false
        }
     }
   }
   if (!match) { return false }
  }
  return true
}

Tile.strokesEqual = function(tileA, tileB) {
  if (tileA.strokes.length !== tileB.strokes.length) { return false }
  var strokesA = Tile.normalizedStrokes(tileA.strokes, tileA.side)
  var strokesB = Tile.normalizedStrokes(tileB.strokes, tileB.side)
  for (var j=0; j<strokesA.length; j++) {
    var strokeA = strokesA[j]
    var match = false
    for (var i=0; i<strokesB.length; i++) {
      var strokeB = strokesB[i]
      if (strokeA.stroke[0] === strokeB.stroke[0]) {
        if (strokeA.stroke[1] === strokeB.stroke[1]) {
          if (strokeA.edgeColor === strokeB.edgeColor &&
              strokeA.bandColor === strokeB.bandColor) {
            match = true
          } else {
            return false
          }
        } else {
          return false
        }
     }
   }
   if (!match) { return false }
  }
  return true
}
//console.log("1:t",Tile.arcsEqual({ strokes: [], side: 0 }, { strokes: [], side: 0 }))
//console.log("2:t",Tile.arcsEqual({ strokes: [], side: 0 }, { strokes: [], side: 2 }))
//console.log("3:f",Tile.arcsEqual({ strokes: [], side: 0 }, { strokes: [[0,1]], side: 0 }))
//console.log("4:f",Tile.arcsEqual({ strokes: [[0,1]], side: 0 }, { strokes: [], side: 0 }))
//console.log("5:t",Tile.arcsEqual({ strokes: [[0,1]], side: 0 }, { strokes: [[0,1]], side: 0 }))
//console.log("6:t",Tile.arcsEqual({ strokes: [[1,2]], side: 0 }, { strokes: [[0,1]], side: 1 }))
//console.log("7:t",Tile.arcsEqual({ strokes: [[0,1],[2,3]], side: 0 }, { strokes: [[2,3],[0,1]], side: 0 }))
//console.log("8:f",Tile.arcsEqual({ strokes: [[0,1],[2,3]], side: 0 }, { strokes: [[2,3],[0,4]], side: 0 }))
//console.log("9:t",Tile.arcsEqual({ strokes: [[0,1],[2,3]], side: 0 }, { strokes: [[2,3],[4,5]], side: 4 }))

Tile.equal = function(tileA, tileB) {
  if (tileA.backgroundColor === tileB.backgroundColor &&
      tileA.hexEdgeColor === tileB.hexEdgeColor) {
    if (Tile.strokesEqual(tileA, tileB)) {
      return true
    }
  }
  return false
}

Tile.topTileEqual = function(hexA, hexB) {
  var tileA = Path.topMesh(hexA)
  var tileB = Path.topMesh(hexB)
  return Tile.equal(tileA, tileB)
}

Tile.topTileAllEqual = function(hexesA, hexesB) {
  if (hexesA.length !== hexesB.length) { return false; }
  for (var i=0; i<hexesA.length; i++) {
    if (!Tile.topTileEqual(hexesA[i], hexesB[i])) {
      return false
    }
  }
  return true
}

Tile.addColorToStrokes = function(strokes, bandColor, edgeColor) {
    return strokes.map(function(stroke) {
        return {
          stroke: stroke,
          bandColor: bandColor,
          edgeColor: edgeColor
        }
    })
}

Tile.hexArcToWorldLine = function(hex, arc) {
    const w = Hex.hexToWorld(hex)
    const s1 = Hex.sidesInWorld[arc[0]]
    const s2 = Hex.sidesInWorld[arc[1]]
    return [w.add(s1), w.add(s2)]
}

module.exports = Tile;
