var State = require('./State.js')
var Color = require('./Color.js')
var Action = require('./Action.js');
var Board = require('./Board.js')
var Tile = require('./Tile.js')
var TileGroup = require('./TileGroup.js')
var Hex = require('./Hex.js')
var HexGroup = require('./HexGroup.js')
var Input = require('./Input.js');
var Maze = require('./Maze.js')
var UDesign = require('./UDesign.js')
var Random = require('./Random.js')
var Generate = require('./Generate.js')
var Draw = require('./Draw.js')
var Material = require('./Material.js')
var Log = require('./Log.js')
var Path = require('./Path.js')
var Store = require('./Store.js')
var Define = require('./Define.js')
var Billiards = require('./Billiards')

// Game Monitor / Manager / Driver / Definitions
// TODO Move generation to Generate
// TODO Move games to Games.js
// TODO Rename to Manager

var Game = {}

// Game.tags, Game.behaviors, Game.colors, Game.assets,
// Tags, colors, and assets define look (colors most directly, then tags, then assets)
// Behaviors define motion

//
//Game.tags = []
// Piece.behaviors = ['moveable', 'tappable', 'snappable', 'fixed', 'onSnap', 'onNoSnap']
// Board.behaviors = ['ground-fixed']
// Game.behaviors = ['']
//Game.appearances = ['hex-tile', 'sphere', 'color-from-paintbox', 'strokes']
// Board.paintbox = []
// Game.paintbox = [] // colors
//

// appearance
Game.fromTileList = function() {
  if (Game.tileList) {
    return Tile.arcs[Game.tileList[Random.generateIndex(Game.tileList.length)]]
  } else {
    console.log("Game.tileList not set")
    return State.gamesDefaults.pickStrokes()
  }
}

Game.isNormalTile = function(tile) {
   return (tile.name === "tile" && tile.type !== "frame" && tile.type !== "start" && tile.type !== "finish" && tile.type !== "agentHereFinish" && !tile.marble)
}

// appearance
Game.toRandomStrokesFromSetUndefinedColor = function(tiles, strokeSet) {
    tiles.forEach(function(tile) {
        if (tile.name === "tile" && tile.type !== "frame" && tile.type !== "start" && tile.type !== "finish" && tile.type !== "agentHereFinish" && !tile.marble) {
            var strokes = strokeSet[Random.generateIndex(strokeSet.length)]
            tile.strokes = strokes.map(function(stroke) {
                return {
                    stroke: stroke,
                    bandColor: undefined,
                    edgeColor: undefined
                }
            })
            tile.strokeSet = strokeSet
        }
    })
}

// appearance
Game.toRandomStrokesFromSetWithColor = function(tiles, strokeSet, bandColor, edgeColor) {
    tiles.forEach(function(tile) {
        if (tile.name === "tile" && tile.type !== "frame" && tile.type !== "start" && tile.type !== "finish" && tile.type !== "agentHereFinish" && !tile.marble) {
            var strokes = strokeSet[Random.generateIndex(strokeSet.length)]
            tile.strokes = strokes.map(function(stroke) {
                return {
                    stroke: stroke,
                    bandColor: bandColor,
                    edgeColor: edgeColor
                }
            })
            tile.strokeSet = strokeSet
        }
    })
}

// appearance, location
Game.generatePoolTilelist = function() {
    var tiles = []
    var colorScheme = Color.schemes[4]
    var inGrid = HexGroup.hex(3)
    inGrid.forEach(function(hex) {
      var strokesA = Game.commands[Game.pickStrokes]()
      var strokes = Tile.addColorToStrokes(strokesA, Color.colors[colorScheme[0]], Color.colors[colorScheme[2]])
      var w = Hex.hexToWorld(hex,  Hex.worldHexRatio)
      w.y = State.yField
      tiles.push({
        strokes: strokes,
        backgroundColor: Color.colors[colorScheme[1]],
        position: w,
        hex: hex
      })
    })
    var andGrid = HexGroup.rectangle([-3,3],[5,5])
    HexGroup.rectangle([-3,3],[-5,-5], andGrid)
    andGrid.forEach(function(hex,i) {
      var strokes = []
      var color = Color.colors[i]
      if (color === "#000000") { color = "#101010" }
      var w = Hex.hexToWorld(hex,  Hex.worldHexRatio)
      w.y = State.yField
      tiles.push({
        strokes: strokes,
        backgroundColor: color,
        position: w,
        name: "marble",
        type: "marble",
        hex: hex
      })
    })
    return tiles
}

// appearance, location, behavior
Game.marbles = function() {
    var tiles = []
    var colorScheme = Color.schemes[4]
    var inGrid = HexGroup.hex(3)
    inGrid.forEach(function(hex) {
      var strokesA = Game.commands[Game.pickStrokes]()
      var strokes = Tile.addColorToStrokes(strokesA, Color.colors[colorScheme[0]], Color.colors[colorScheme[2]])
      var w = Hex.hexToWorld(hex,  Hex.worldHexRatio)
      w.y = State.yField
      tiles.push({
        strokes: strokes,
        backgroundColor: Color.colors[colorScheme[1]],
        position: w,
        hex: hex
      })
    })
    var andGrid = HexGroup.rectangle([-2,1],[5,5])
    andGrid.forEach(function(hex,i) {
      var strokes = []
      var color = Color.colors[i]
      var w = Hex.hexToWorld(hex,  Hex.worldHexRatio)
      w.y = State.yField
      var marble = Draw.makeMarble()
      marble.on = marble.on || {}
      marble.on.collide = Action.bounce
      marble.locked = true
      marble.position = w
    })
    return tiles
}

// location, behavior
Game.rollMarblePath = function(path) {
    // TODO Move marble from edge to loop
    Action.rollMarblePath(path)
}

// no appearance, location, or behavior
Game.noop = function() {}

Game.commands = {}
Game.commands.pickFromTileList = Game.fromTileList
Game.commands.zoomAwayPath = Action.zoomAwayPath
Game.commands.inOrderFromTileList = Game.inOrderFromTileList

// TODO: Keep structure of meshes as built for game rather than querying scene
// TODO: replace grid: with frame:
// no appearance, location, or behavior
Game.getGridTilesAndOther = function() {
  var tiles = []
  var grid = []
  var other = []
  var meshes = State.scene.meshes
  meshes.forEach(function(mesh) {
    if (mesh.name === "tile") {
      tiles.push(mesh)
    } else if (mesh.name === "frame") {
      grid.push(mesh)
    } else {
      other.push(mesh)
    }
  })
  return {
    tiles: tiles,
    grid: grid,
    other: other
  }
}

// location
Game.makeGridList = function(gridDef) {
    var grid = []
    gridDef.forEach(function(g) {
        if (g.length === 3) {
          HexGroup[g[0]](g[1],g[2], grid)
        } else if (g.length === 2) {
          HexGroup[g[0]](g[1], grid)
        } else {
          HexGroup[g[0]](grid)
        }
    })
    return grid
}

Game.ccc = function(pieces) {
  var ccc = Tile.named.strokes.ccc
  pieces.forEach(function(piece) {
      if (Game.isNormalTile(piece)) {
          var strokes = Tile.addColorToStrokes(ccc, undefined, undefined)
          piece.strokes = strokes
      }
  })
  Generate.toRandomColorScheme(pieces)
}

Game.cCC = function(pieces) {
  var cCC = Tile.named.strokes.cCCxa
  pieces.forEach(function(piece) {
      if (Game.isNormalTile(piece)) {
          var strokes = Tile.addColorToStrokes(cCC, undefined, undefined)
          piece.strokes = strokes
      }
  })
  Generate.toRandomColorScheme(pieces)
}

// appearance
Game.changeCurves8 = function(pieces) {
  Game.toRandomStrokesFromSetUndefinedColor(pieces, [[[0,1],[2,4]],[[1,2],[3,5]],[[0,2],[3,4]],[[0,3]],[[1,4]],[[2,5]]])
  Generate.toRandomColorScheme(pieces)
}

// appearance
Game.changeCurves7 = function(pieces) {
  Game.toRandomStrokesFromSetUndefinedColor(pieces, [[[0,1]],[[0,2]],[[0,3]],[[1,2]],[[1,3]],[[2,3]],[[2,4]]])
  Generate.toRandomColorScheme(pieces)
}

// appearance
Game.changeCurves6 = function(pieces) {
  Game.toRandomStrokesFromSetUndefinedColor(pieces, [[[0,2]],[[1,3]],[[2,4]],[[3,5]],[[1,4]],[[2,5]],[[0,3]],[[0,3]]])
  Generate.toRandomColorScheme(pieces)
}

// 3 of 6 big curve rotations, 3 different straight line rotations
// appearance
Game.changeCurves5 = function(pieces) {
  Game.toRandomStrokesFromSetUndefinedColor(pieces, [[[0,2]],[[1,3]],[[2,4]],[[1,4]],[[2,5]],[[0,3]]])
  Generate.toRandomColorScheme(pieces)
}

// appearance
Game.changeCurves4 = function(pieces) {
  Game.toRandomStrokesFromSetUndefinedColor(pieces, [[[0,3],[2,5]],[[0,1],[2,3]],[[0,3]],[[0,1],[2,5]]])
  Generate.toRandomColorScheme(pieces)
}

// appearance
Game.changeCurves3 = function(pieces) {
  Game.toRandomStrokesFromSetUndefinedColor(pieces, [[[0,1]],[[0,3]],[[0,1],[2,5]]])
  Generate.toRandomColorScheme(pieces)
}

// appearance
Game.changeCurves2 = function(pieces) {
  Game.toRandomStrokesFromSetUndefinedColor(pieces, [[[0,1],[2,5]],[[0,1],[2,3],[4,5]],[[0,3]]])
  Generate.toRandomColorScheme(pieces)
}

//appearance
Game.changeCurves = function(pieces) {
  Game.toRandomStrokesFromSetUndefinedColor(pieces, [[[0,1],[2,5],[3,4]]])
  Generate.toRandomColorScheme(pieces)
}

Game.colorBook = function(pieces) {
  var curves = Tile.named.triples.map(function(name) { return Tile.named.strokes[name] })
  Game.toRandomStrokesFromSetWithColor(pieces, curves, "#ffffff", "#000000")
}

// appearance, location, behavior
Game.celebrate = function() {
    Board.celebrate(State.boards[State.currentBoard], State.Manager.nextBoard)
    //Manager.nextBoard()
}

Game.gamesChoices = function() {
  return Object.keys(State.gameList)
}

// appearance
Game.randomStroke = function() {
  var beginning = Random.generateIndex(6)
  var end = Random.generateIndex(5)
  if (end >= beginning) {
    end += 1
  }
  return [beginning, end]
}

Game.clear = function() {
  Board.clear(State.scene)
}

// appearance, location, behavior
Game.rewindOneGame = function() {
    var thisGameIndex = Log.thisGameIndex()
    if (thisGameIndex > 0) {
        var previousGameIndex = Log.previousGameIndex(thisGameIndex)
        if (previousGameIndex >= 0) {
            Log.log("rewindToGame", {
                gameTime: Log.localCache[previousGameIndex].clientTime
            })
            Board.clear(State.scene)
            Log.replaying = true

            var i = previousGameIndex
            var move = Log.localCache[i++]
            if (move.type !== "newGame" || !move.game) {
                Log.replaying = false
                Log.log("expected newGame, got", move)
                Log.replaying = true
            }
            State.boards = State.gameList[move.game]
            State.currentBoard = 0
            move = Log.localCache[i++]
            if (move.type !== "newBoard" || !move.board) {
                Log.replaying = false
                Log.log("expected newBoard, got", move)
                Log.replaying = true
            }
            for (; i<thisGameIndex && move.type !== "Board.clear"; i++) {
                move = Log.localCache[i]
                console.log(move)
                if (move.type === "Generate.piece") {
                    Generate.piece(move)
                }
            }
            console.log("end",move)
            Log.replaying = false
        }
    }
}

// appearance
Game.randomColorScheme = function() {
  return Color.schemes[Random.generateIndex(Color.schemes.length)]
}

Game.tileAtHex = function(hex, colorScheme, strokes) {
  var w = Hex.hexToWorld(hex,  Hex.worldHexRatio)
  // TODO? Do this at deploy?
  w.y = State.yField
  return {
        strokes: strokes,
        backgroundColor: Color.colors[colorScheme[1]],
        hexEdgeColor: Color.colors[colorScheme[2]],
        position: w,
        hex: hex
  }
}

Game.checkLoop = function(mesh) {
  var paths = Path.paths(mesh)
  paths.forEach(function(path) {
    if (path.loop) {
        if (mesh.on && mesh.on.tileLoop) {
            mesh.on.tileLoop(path)
        } else if (Game.onTileLoop) {
            Game.onTileLoop(path)
        } else {
            console.log("checkloop without effect")
        }
    }
  })
}

// location, behavior
Game.swapOnLand = function(mesh) {
    var targetTileHex = mesh.hex
    var allTiles = Path.meshesAtHex(targetTileHex)
    var swappedTile = null
    if (allTiles.length > 2) {
      console.log("Expected length 1 or 2, got length:", allTiles.length)
    }
    if (allTiles.length > 1) {
      allTiles.forEach(function(tile) {
        if (tile !== mesh) {
          // TODO Animate
          // TODO Save old hex / position in mesh
          tile.hex = Input.originHex
          var position = Hex.hexToWorld(Input.originHex,  Hex.worldHexRatio)
          tile.position.x = position.x
          tile.position.z = position.z
          swappedTile = tile
        }
      })
    }
    return swappedTile
}

Game.lockToggle = function(mesh) {
    if (mesh.label === "lockSVG") {
        mesh.label = "unlockSVG"
        On.designLockTile = false
    } else if (mesh.label === "unlockSVG") {
        mesh.label = "lockSVG"
        On.designLockTile = true
    } else {
        console.log("Game.lockToggle fail")
    }
    Material.redraw(mesh)
}

Game.designLockTileOn = {
    pointerDown: Game.lockToggle
}

// appearance
Game.generateFrameTiles = function(frameGroup) {
    var tiles = frameGroup.map(function(frame) {
        return {
                name: "tile",
                type: "frame",
                hex: frame,
                strokes: [],
                backgroundColor: "#000000",
                hexEdgeColor: "#ffffff",
                y: State.yFrame
        }
    })
    return tiles
}

State.mainChoices = {}

Game.createDesign = [
  { label: 'C', game: "uDesign", boards: [{ name: "uDesign" }] },
  { label: 'R', game: "uDesign", boards: [{ name: "uDesign" }] },
  { label: 'E', game: "uDesign", boards: [{ name: "uDesign" }] },
  { label: 'A', game: "uDesign", boards: [{ name: "uDesign" }] },
  { label: 'T', game: "uDesign", boards: [{ name: "uDesign" }] },
  { label: 'E', game: "uDesign", boards: [{ name: "uDesign" }] },
]

State.gameList = {}

Game.getDesigns = function() {
  function blank() {
    return {
      label: '+',
      game: 'uDesign',
      boards: [{
        name: 'uDesign',
        history: []
      }]
    }
  }

  let history = State.history.uDesign
  let encoded = State.history.encoded

  const designs = history.map((v, i) => {
    if (v.length > 0 || (encoded[i] !== '' && encoded[i] !== undefined)) {
      return {
        label: i.toString(),
        game: 'uDesign',
        boards: [{
          name: 'uDesign',
          history: history[i]
        }]
      }
    } else {
      return blank()
    }
  })

 return designs
}

/*
// Enable paintcanvas to use
Games.marbleSwipe = [{
    name: "marbleSwipe"
}]
*/

Game.mazes = {
  label: "mazes",
  game: "mazes",
  boards: [{
    name: "mazeRectangle3x4",
    modify: Game.changeCurves,
    celebrate: "fastMarbles"
  },{
    name: "mazeDiamond",
    modify: Game.changeCurves,
    celebrate: "fastMarbles"
  },{
    name: "mazeRectangleToo7x13",
    modify: Generate.toRandomColorScheme,
    celebrate: "allSpinTrio"
  },{
    name: "hex4",
    modify: Game.ccc,
    celebrate: "allBackAndForth"
  },{
    name: "mazeRectangle3x4",
    modify: Game.changeCurves7,
    celebrate: "fastMarbles"
  },{
    name: "mazeHourglass",
    modify: Game.changeCurves,
    celebrate: "fastMarbles"
  },{
    name: "mazeRectangle7x8",
    modify: Game.cCC,
    celebrate: "fastMarbles"
  },{
    name: "mazeHexWithHoles",
    celebrate: "allBackAndForth"
  }]
}

Game.loop = {
  label: "loop",
  game: "loop",
  boards: [{
    name: "loopToo",
    modify: Game.colorBook
  }]
}

Game.billiards = {
  label: "billiards",
  game: "billiards",
  boards: [{
    name: "billiards"
  }]
}

State.games = {}

State.games.maze0 = Maze.games.line1x3
State.games.mazeRectangle3x4 = Maze.games.rectangle3x4
State.games.hex4 = Maze.games.hex4
State.games.mazeRectangle7x8 = Maze.games.rectangle7x8
State.games.mazeRectangleToo9x17 = Maze.games.rectangleToo9x17
State.games.mazeRectangleToo7x13 = Maze.games.rectangleToo7x13
State.games.mazeHexWithHoles = Maze.games.hexWithHoles

State.games.mazeDiamond = Maze.games.diamond
State.games.mazeHourglass = Maze.games.hourglass

State.games.uDesign = UDesign.main

// appearance, location, behavior
Game.zoomAwayPathAndRegenerate = function(path) {
    var defs = path.path.map(function(p) {
        return { def: p.mesh.def }
    })
    Action.zoomAwayPath(path)
    // Not a real mesh, only def is needed. Rewrite of Generate.regeneratePiece to just take def
    defs.forEach(function(mesh) { Generate.regeneratePiece(mesh) })
}

// appearance, location, behavior
Game.addMarble = function(path) {
    var marble = Draw.makeMarble(Color.colors[Random.generateIndex(Color.colors.length)])
    Action.rollMarblePath(path, marble)
}

Game.addMarbleToo = function(path) {
    var marble = Draw.makeMarble(Color.colors[Random.generateIndex(Color.colors.length-3)+3])
    Action.rollMarblePath(path, marble)
}

// behavior
Game.loopsOn = {
    tileLoop: Game.zoomAwayPathAndRegenerate,
    snap: Game.checkLoop,
    tap: Action.tapTile,
    pointerDown: Input.tapAndDragStart,
    pointerUp: Input.tapAndDragEnd,
    drag: function() {}
}

// behavior
Game.loopsTooOn = {
    tileLoop: Game.addMarbleToo,
    snap: Game.checkLoop,
    tap: Action.tapTile,
    pointerDown: Input.tapAndDragStart,
    pointerUp: Input.tapAndDragEnd,
    drag: function() {}
}

State.games.loopToo = {
    name: "loopToo",
    lockGround: true,
    pieces: function() {
        var colorScheme = Color.schemes[2]
        var tileStrokes = Tile.addColorToStrokes([[0,1]], Color.colors[colorScheme[0]], Color.colors[colorScheme[2]])
        var tiles = []
        HexGroup.rectangleToo([-3,3], [-6, 6]).forEach(function(hex) {
            tiles.push({
                name: "tile",
                hex: hex,
                strokes: tileStrokes,
                side: Random.generateIndex(6),
                backgroundColor: "#fff",
                y: State.yField,
                on: Game.loopsTooOn
            })
        })
        tiles.push(Define.TopMenu([1,5]))
        return tiles
    }()
}

// appearance, location, behavior
State.games.billiards = {
  name: "billiards",
  lockGround: true,
  upRotated: true,
  pieces: function() {  // Executed once, could be simply loaded from server
      var colors = Color.colors
      var colorScheme = Color.schemes[4]

      var tiles = Game.generateFrameTiles(HexGroup.hex(4))
      HexGroup.hex(4).forEach(function(hex) {
          tiles.push({
              name: "tile",
              hex: hex,
              strokes: [
                   { stroke: [0,1], bandColor: 0, edgeColor: 2 },
                   { stroke: [2,5], bandColor: 0, edgeColor: 2 },
                   { stroke: [3,4], bandColor: 0, edgeColor: 2 }
             ],
              side: Random.generateIndex(6),
              backgroundColor: 1,
              y: State.yField,
              on: Billiards.billiardsOn
          })
      })
      tiles.push({
              name: "marble",
              type: "marble",
              hex: [0,0],
              backgroundColor: "#c9c9c9",
              y: State.yField,
              fps: 60,
              on: Billiards.billiardsCueOn
      })
      var placement = [[-3,0],[-1,3],[3,-3]]
      Color.colors.slice(4,7).forEach(function(color, i) {
          tiles.push({
              name: "marble",
              type: "marble",
              hex: placement[i],
              backgroundColor: color,
              y: State.yField,
              fps: 60,
              on: Billiards.billiardsBallOn
          })
      })
      tiles.push(Define.TopMenu([1,4]))
      return tiles
  }(), // Notice: generated once
}

Game.noOn = {
    snap: function() {},
    tap: function() {},
    pointerDown: function() {},
    pointerUp: function() {},
    drag: function() {}
}

Game.keepOn = 0
Game.keepOnTruckin = function(mesh) {
    if (!State.paintCanvas) {
        if (Game.keepOn > -1000) {
            console.log("paintCanvas not enabled")
            Game.keepOn = -1000
        }
        return
    }
    if (!mesh.radius) {
        mesh.radius = Hex.smallArcRadius/2
    }
    var first = Arc.bounce(mesh.position, mesh.velocity, mesh.radius)
    var changed = first[2]
    if (changed) {
        mesh.position.x = first[0].x
        mesh.position.z = first[0].z
        mesh.velocity.x = first[1].x
        mesh.velocity.z = first[1].z
    }
    var count = 0
    var bounced = true
    while(bounced && count<10) {
        var again = Arc.bounce(mesh.position, mesh.velocity, mesh.radius)
        bounced = again[2]
        if (bounced) {
          //console.log("b:", again)
          mesh.position.x = again[0].x
          mesh.position.z = again[0].z
          mesh.velocity.x = again[1].x
          mesh.velocity.z = again[1].z
        }
        count++
    }
    if (count >= 5) {
        console.log(count, "bounces")
    }
    if (mesh.paintTrail) {
        //Draw.smallCircleOnCanvas(mesh.position, 4, mesh.backgroundColor)
        if (mesh.previousPosition) {
            Draw.trailOnCanvas(mesh.previousPosition, mesh.position, 4, mesh.backgroundColor)
        }
        mesh.previousPosition = mesh.position.clone()
    }
    Game.keepOn += 1
    return true
}

Game.marbleSwipeBounceOn =  {
    collide: function(mesh) {
        //console.log("collide")
    },
    tap: function(mesh) {
        console.log("tap")
        mesh.position = Hex.hexToWorld([1,2])
    },
    pointerDown: function(mesh) {
        console.log("pointerDown")
        mesh.startPush = Date.now()
        mesh.runningTotals = []
        if (mesh.animationHandle) {
            Animate.halt(mesh, mesh.animationHandle)
        }
    },
    pointerUp: function(mesh) {
        var ms = Date.now() - mesh.startPush
        var xTotal = 0
        var zTotal = 0
        mesh.runningTotals.forEach(function(diff) {
            xTotal += diff.x
            zTotal += diff.z
        })
        var xDelta = xTotal/ms*1000/60
        var zDelta = zTotal/ms*1000/60
        var hex = Hex.nearestHexCenter(mesh.position)
        var topMesh = Path.topMesh(hex)
        if (true || !topMesh || !topMesh.name || topMesh.name !== "tile") {
            mesh.animationHandle = Animate.move(mesh, xDelta, zDelta, Game.keepOnTruckin)
            //console.log("no tile")
        } else {
            var allowedSides = Hex.allowedSides(xDelta, zDelta)
            var normalizedArcs = Tile.normalizedArcs(topMesh.strokes, topMesh.side)
            var found = allowedSides.reduce(function(found, side) {
                if (!found) {
                    found = normalizedArcs.reduce(function(found, arc) {
                        if (!found) {
                          if (arc[0] === side) {
                            found = [arc[1], arc[0]]
                          } else if (arc[1] === side) {
                            found = [arc[0], arc[1]]
                          }
                        }
                        return found
                    }, undefined)
                }
                return found
            }, undefined)
            console.log(normalizedArcs, allowedSides, found)
            if (found) {
                var hexArc = {
                    hex: hex,
                    arc: found,
                    mesh: topMesh
                }
                var speed = Math.sqrt(xDelta*xDelta + zDelta*zDelta)
                // The slowest non-zero speed I could do was 0.3
                // The fastest: 10.6.
                console.log("speed:", speed)
                mesh.fps = 30 * speed
                Action.firstArc(mesh, hexArc)
                //mesh.animationHandle = Animate.move(mesh, xDelta, zDelta)
            }
        }
    },
    drag: function(mesh, diff) {
        mesh.position.x += diff.x
        mesh.position.z += diff.z
        mesh.runningTotals.unshift({
            x: diff.x,
            z: diff.z,
            now: Date.now()
        })
        if (mesh.runningTotals.length > 5) {
            var effectiveStart = mesh.runningTotals.pop()
            mesh.startPush = effectiveStart.now
        }
    }
}

State.games.marbleSwipe = {
    name: "marble swipe",
    lockGround: true,
    pieces: function() {
        var tiles = [{
                name: "marble",
                type: "marble",
                hex: [5,-5],
                strokes: [],
                backgroundColor: Color.named.colors["Green Haze"],
                y: State.yField,
                paintTrail: true,
                on: Game.marbleSwipeBounceOn
        }]
        var field = HexGroup.ring(5)
        HexGroup.ring(4, field)
        HexGroup.ring(3, field)
        field.forEach(function(hex, i) {
            tiles.push({
                name: "tile",
                type: "normal",
                hex: hex,
                bandColor: 2,
                edgeColor: 0,
                strokes: [
                    { stroke: [0,Random.generateIndex(2)+1], bandColor: 2, edgeColor: 0 }
                ],
                side: Random.generateIndex(6),
                backgroundColor: 1,
                y: State.yField,
                on: {
                    tap: Action.tapTile,
                    pointerDown: Input.tapAndDragStart,
                    pointerUp: Input.tapAndDragEnd,
                    drag: function() {}
                }
            })
        })

        return tiles
    }()
}

// behavior
Game.singleOn = {
    tap: function(mesh) {
        mesh.taps = mesh.taps || 0
        mesh.taps += 1
        Action.tapTile(mesh)
        if (mesh.taps === 3) {
            Game.celebrate()
        }
    },
    pointerDown: Input.tapAndDragStart,
    pointerUp: Input.tapAndDragEnd,
    drag: function() {}
}

// appearance, location
State.games.single = {
    name: "single",
    lockGround: true,
    pieces: function() {
        var colorScheme = Color.schemes[8]
        var tileStrokes = Tile.addColorToStrokes([[0,1]], Color.colors[colorScheme[0]], Color.colors[colorScheme[2]])

        var tiles = [{
                name: "tile",
                type: "normal",
                hex: [0,0],
                strokes: tileStrokes,
                backgroundColor: Color.colors[colorScheme[1]],
                y: State.yField,
                on: Game.singleOn
        }]
        return tiles
    }()
}

// appearance, location, behavior
State.games.loops = {
    name: "loops",
    lockGround: true,
    pieces: function() {  // Executed once, could be simply loaded from server
        var centerTileGroup = TileGroup.fromHexGroup(TileGroup.standardPhoneCenterHexGroup)

        centerTileGroup.forEach(function(obj) {
            obj.locked = true
            obj.on = Game.loopsOn
        })

        var headerMeterGroup = TileGroup.standardPhoneHeaderMeterHexGroup
        var footerMeterGroup = TileGroup.standardPhoneFooterMeterHexGroup
        return centerTileGroup
    }(), // Notice: generated once
}

State.games.mainChoices = {
    name: "mainChoices",
    lockGround: true,
    pieces: []
}

State.games.designChoices = {
    name: "designChoices",
    lockGround: true,
    pieces: []
}

// behavior
Game.designOn =  {
    tap: Action.tapTile,
    pointerDown: Input.tapAndDragStart,
    pointerUp: Game.designRegenerate,
    drag: Input.drag
}

// behavior
Game.designPaintbucketOn = {
    snap: function() {},
    pointerDown: Input.tapAndDragStart,
    pointerUp: Game.designRegenerate,
    drag: Input.drag
}

// (non) behavior
Game.designMarbleOn = {
    pointerUp: Game.designMarbleMove
}

// appearance, location, behavior
State.games.design = {
    name: "design",
    lockGround: true,
    pieces: function() {
        var tiles = Game.generateFrameTiles(HexGroup.single(-7,1))
        tiles.forEach(function(tile) {
            tile.on = Game.designOn
        })
        tiles.push({
                name: "tile",
                type: "icon",
                label: "unlockSVG",
                hex: [-10, 1],
                strokes: [],
                backgroundColor: 2,
                y: State.yField,
                on: Game.designLockTileOn
        })
        var phoneTiles = Define.PhoneFrameTiles()
        tiles = tiles.concat(phoneTiles)
        var colors = Color.colors
        var colorScheme = Color.schemes[1]
        var tileChoicePositions = HexGroup.move(HexGroup.rectangle([0,3],[0,4]),[-12,3])
        Tile.arcs.forEach(function(strokes,i) {
            var tileStrokes = Tile.addColorToStrokes(strokes, "#ffffff", "#000000")
            tiles.push({
                name: "tile",
                type: "normal",
                hex: tileChoicePositions[i],
                strokes: tileStrokes,
                backgroundColor: 2,
                y: State.yField,
                on: Game.designOn
            })
        })
        var colorChoicePositions = HexGroup.move(HexGroup.rectangle([0,1],[0,6]),[-9,-6])
        Color.colors.forEach(function(color, i) {
            tiles.push({
                name: "tile",
                type: "normal",
                hex: colorChoicePositions[i],
                strokes: [],
                backgroundColor: color,
                y: State.yField,
                on: Game.designColorOn
            })

        })
        var marbleChoicePositions = HexGroup.move(HexGroup.rectangle([0,1],[0,6]),[-5,-6])
        Color.colors.forEach(function(color, i) {
            tiles.push({
                name: "marble",
                type: "marble",
                hex: marbleChoicePositions[i],
                backgroundColor: color,
                y: State.yField,
                on: Game.designMarbleOn
            })
        })

        return tiles
    }()
}

// appearance, location, behavior
State.games.pools = {
  name: "pools",
  onTileLoop: Game.noop,
  tileList: Tile.curvesOnly,
  pickStrokes: "pickFromTileList",
  startingTiles: Game.generatePoolTilelist
}

// appearance, location, behavior
State.games.marble = {
  name: "marble",
  type: "marble",
  onTileLoop: Game.rollMarblePath,
  tileList: Tile.curvesOnly,
  pickStrokes: "pickFromTileList",
  startingTiles: Game.marbles
}

// appearance, location, behavior
State.games.loop = {
  name: "loop",
  onTileLoop: Action.zoomAwayPath,
  tileList: Draw.noBlank,
  pickStrokes: "pickFromTileList"
}

// appearance, location, behavior
Game.bigCircleDualStartingTiles = function() {
    var colorScheme = Color.schemes[2]
    var matchGrid = HexGroup.seven(-1,2)
    var tileStrokes = [[[0,2]], [[1,3]], [[5,1]], [], [[2,4]], [[4,0]], [[3,5]] ]
    var matchTiles = []
    matchGrid.forEach(function(hex, i) {
      var strokes = Tile.addColorToStrokes(tileStrokes[i], Color.colors[colorScheme[0]], Color.colors[colorScheme[2]])
      matchTiles.push(Game.tileAtHex(hex, colorScheme, strokes))
    })
    var otherGrid = HexGroup.seven(1,-2)
    otherGrid = Random.shuffle(otherGrid)
    var otherTiles = []
    matchTiles.forEach(function(tile, i) {
      otherTiles.push(Game.tileAtHex(otherGrid[i], colorScheme, tile.strokes))
    })
    var tiles = matchTiles.concat(otherTiles)
    return tiles
}

// appearance, location, behavior
State.games.bigCircleDual = {
  name: "bigCircleDual",
  onTileLoop: Game.noop,
  tileList: Tile.curvesOnly,
  pickStrokes: "pickFromTileList",
  grid: [
    ["seven", 1, -2],
    ["seven", -1, 2]
  ],
  startingTiles: Game.bigCircleDualStartingTiles,
  noSnap: function(mesh) {
    // Return if not on hex
    mesh.hex = Input.originHex
    var position = Hex.hexToWorld(Input.originHex,  Hex.worldHexRatio)
    position.y = mesh.position.y
    mesh.position = position
  },
  onSnap: function(mesh) {
    var targetTileHex = mesh.hex
    var swapped = Game.swapOnLand(mesh)
    var playGrid = HexGroup.seven(1,-2)
    var targetGrid = HexGroup.seven(-1, 2)
    if (Tile.topTileAllEqual(targetGrid, playGrid)) {
      playGrid.forEach(function(hex) {
        var mesh = Path.topMesh(hex)
        Action.zoomAway(mesh)
      })
    }
  }
}

// appearance, location, behavior
State.games.triDual = {
  name: "triDual",
  onTileLoop: Game.noop,
  tileList: Tile.curvesOnly,
  pickStrokes: "pickFromTileList",
  grid: [
    ["triSix", 1, -2],
    ["triSix", -1, 2]
  ],
  startingTiles: function() {
    var colorScheme = Color.schemes[2]
    var matchGrid = HexGroup.triSix(-1,2)
    var tileStrokes = [[[2,3]], [[0,2],[1,3]], [[2,4],[3,5]], [[0,1]], [[4,0],[5,1]], [[4,5]] ]
    var matchTiles = []
    matchGrid.forEach(function(hex, i) {
      var strokes = Tile.addColorToStrokes(tileStrokes[i], Color.colors[colorScheme[0]], Color.colors[colorScheme[2]])
      matchTiles.push(Game.tileAtHex(hex, colorScheme, strokes))
    })
    var otherGrid = HexGroup.triSix(1,-2)
    otherGrid = Random.shuffle(otherGrid)
    var otherTiles = []
    matchTiles.forEach(function(tile, i) {
      otherTiles.push(Game.tileAtHex(otherGrid[i], colorScheme, tile.strokes))
    })
    var tiles = matchTiles.concat(otherTiles)
    return tiles
  },
  noSnap: function(mesh) {
    // Return if not on hex
    mesh.hex = Input.originHex
    var position = Hex.hexToWorld(Input.originHex,  Hex.worldHexRatio)
    position.y = mesh.position.y
    mesh.position = position
  },
  onSnap: function(mesh) {
    var targetTileHex = mesh.hex
    var swapped = Game.swapOnLand(mesh)
    var playGrid = HexGroup.triSix(1,-2)
    var targetGrid = HexGroup.triSix(-1, 2)
    if (Tile.topTileAllEqual(targetGrid, playGrid)) {
      playGrid.forEach(function(hex) {
        var mesh = Path.topMesh(hex)
        Action.zoomAway(mesh)
      })
    }
  }
}

// appearance, location
Game.generateDefaultTiles = function() {
    var tiles = []
    var colorScheme = Color.schemes[4]
    var inGrid = HexGroup.hex(3)
    inGrid.forEach(function(hex) {
      var strokesA = Game.commands[Game.pickStrokes]()
      var strokes = Tile.addColorToStrokes(strokesA, Color.colors[colorScheme[0]], Color.colors[colorScheme[2]])
      var w = Hex.hexToWorld(hex,  Hex.worldHexRatio)
      w.y = State.yField
      tiles.push({
        strokes: strokes,
        backgroundColor: Color.colors[colorScheme[1]],
        position: w,
        hex: hex
      })
    })
    var andGrid = HexGroup.ring(5)
    andGrid.forEach(function(hex) {
      var strokesA = Game.commands[Game.pickStrokes]()
      var colorScheme = Color.schemes[Random.generateIndex(Color.schemes.length)]
      var strokes = Tile.addColorToStrokes(strokesA, Color.colors[colorScheme[0]], Color.colors[colorScheme[2]])
      var w = Hex.hexToWorld(hex,  Hex.worldHexRatio)
      w.y = State.yField
      tiles.push({
        strokes: strokes,
        backgroundColor: Color.colors[colorScheme[1]],
        position: w,
        hex: hex
      })
    })
    return tiles
}

// appearance, location, behavior
State.gamesDefaults = {
  pickStrokes: function() {
    return Tile.arcs[Random.generateIndex(Tile.arcs.length)]
  },
  pickColors: Game.randomColorScheme,
  grid: [["hex", 4]],
  startingTiles: Game.generateDefaultTiles,
  onSnap: Game.checkLoop,
  noSnap: Game.noop,
  onTileLoop: Game.noop
}

module.exports = Game
