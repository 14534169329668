var State = require('../State.js')
var Color = require('../Color.js')

var HexShader = {}


function color(candidate, backup) {
    if (Color.Vector3[candidate] !== undefined) {
        return Color.Vector3[candidate]
    }
    if (Color.hexDir[candidate] !== undefined) {
        var index = Color.hexDir[candidate]
        return Color.Vector3[index]
    }
    if (typeof backup !== 'undefined') {
        return Color.Vector3[backup]
    }
    console.log('backup unspecified')
    return Color.Vector3[0]
}

HexShader.setBackgroundColor = function(material, c) {
    material.setVector3('backgroundColor', color(c, 0))
}

HexShader.setBackgroundAndHexEdgeColors = function(material, d) {
    material.setVector3('backgroundColor', color(d.backgroundColor, 0));
    material.setVector3('hexEdgeColor', State.hexEdgesOn 
        ? color(d.hexEdgeColor, 1)
        : color(d.backgroundColor, 0)
    );
}

function length(stroke) {
    let len = Math.abs(stroke[1]-stroke[0])
    return len == 0 ? 4 : len
}

HexShader.setStrokes = function(material, d) {
    if (d.strokes && d.strokes.length >= 1) {
        material.setVector3('band1Color', color(d.strokes[0].bandColor));
        material.setVector3('edge1Color', color(d.strokes[0].edgeColor));
        material.setVector2('stroke1', new BABYLON.Vector2(
            d.strokes[0].stroke[0],
            length(d.strokes[0].stroke)))
    }
    if (d.strokes && d.strokes.length >= 2) {
        material.setVector3('band2Color', color(d.strokes[1].bandColor));
        material.setVector3('edge2Color', color(d.strokes[1].edgeColor));
        material.setVector2('stroke2', new BABYLON.Vector2(
            d.strokes[1].stroke[0],
            length(d.strokes[1].stroke)))
    }
    if (d.strokes && d.strokes.length >= 3) {
        material.setVector3('band3Color', color(d.strokes[2].bandColor));
        material.setVector3('edge3Color', color(d.strokes[2].edgeColor));
        material.setVector2('stroke3', new BABYLON.Vector2(
            d.strokes[2].stroke[0],
            length(d.strokes[2].stroke)))
    }
    return material
}

State.backgroundWhich = 0
State.backgroundColors = [
    { backgroundColor: 0, hexEdgeColor: 15 },
    { backgroundColor: 16, hexEdgeColor: 15 },
    { backgroundColor: 0, hexEdgeColor: 0 },
    { backgroundColor: 16, hexEdgeColor: 16 }
]

State.generatorColors = { backgroundColor: 2, hexEdgeColor: 2, bandColor: 2, edgeColor: 0 }

// State.generatorColors = [
//     { backgroundColor: 0, hexEdgeColor: 2, bandColor: 0, edgeColor: 2 },
//     { backgroundColor: 2, hexEdgeColor: 0, bandColor: 2, edgeColor: 0 },
//     { backgroundColor: 0, hexEdgeColor: 0, bandColor: 0, edgeColor: 2 },
//     { backgroundColor: 2, hexEdgeColor: 2, bandColor: 2, edgeColor: 0 }
// ]

HexShader.setAllHexEdges = function() {
    for (var i=0; i<State.scene.meshes.length; i++) {
        var mesh = State.scene.meshes[i]
        if (mesh.def && mesh.def.shader == 'track' && mesh.type !== 'frame' && mesh.material.setVector3) {
            if (State.hexEdgesOn) {
                HexShader.setBackgroundAndHexEdgeColors(mesh.material, mesh)
            } else {
                HexShader.setBackgroundAndHexEdgeColors(mesh.material, {
                    backgroundColor: mesh.backgroundColor,
                    hexEdgeColor: mesh.backgroundColor
                })
            }
        }
      }
}

HexShader.setGeneratorColors = function() {
    //let colors = State.generatorColors[State.backgroundWhich%4]
    let colors = State.generatorColors
    for (var i=0; i<State.scene.meshes.length; i++) {
        var mesh = State.scene.meshes[i]
        if (mesh.def && mesh.def.shader == 'track' && mesh.type == 'generator' && mesh.material.setVector3) {
            mesh.backgroundColor = colors.backgroundColor
            mesh.hexEdgeColor = colors.hexEdgeColor
            HexShader.setBackgroundAndHexEdgeColors(mesh.material, mesh)
            let strokes = mesh.strokes.map(function(stroke) {
                return {
                    stroke: stroke.stroke,
                    bandColor: colors.bandColor,
                    edgeColor: colors.edgeColor
                }
            })
            mesh.strokes = strokes
            mesh.def.strokes = strokes
            HexShader.setStrokes(mesh.material, mesh)
        }
    }
}

HexShader.centerWidth = function(type) {
    const defaultCenterWidth = 12.6;
    const strongEdgeCenterWidth = 12.4;
    const generatorCenterWidth = 11.0;
    const thinnestConsistentLines = 13.13; // Screen-size matters 13.18 worked too.
    const centerWidth = type && (type.includes('generator') || type.includes('color'))
    ? generatorCenterWidth
    : defaultCenterWidth;
    return centerWidth
}

HexShader.setHexEdgeWidth = function(material, centerWidth) {
    material.setFloat('centerWidth', centerWidth)
}

HexShader.setupTrack = function(material, d) {
    HexShader.setBackgroundAndHexEdgeColors(material, d)
    material.setVector3('band1Color', Color.Vector3[0]);
    material.setVector3('band2Color', Color.Vector3[0]);
    material.setVector3('band3Color', Color.Vector3[0]);
    material.setVector3('edge1Color', Color.Vector3[0]);
    material.setVector3('edge2Color', Color.Vector3[0]);
    material.setVector3('edge3Color', Color.Vector3[0]);
    material.setVector2('stroke1', new BABYLON.Vector2(0.0, 0.0))
    material.setVector2('stroke2', new BABYLON.Vector2(0.0, 0.0))
    material.setVector2('stroke3', new BABYLON.Vector2(0.0, 0.0))
    HexShader.setStrokes(material, d)
    const centerWidth = HexShader.centerWidth(d.type)
    HexShader.setHexEdgeWidth(material, centerWidth)
    return material
}

HexShader.setupWeb = function(material, d) {
    material.setVector3('color1', Color.Vector3[0]); // black
    material.setVector3('color2', Color.Vector3[2]); // white
    material.setFloat('centerWidth', 0.1)
    material.setFloat('frequency', 0.1)
    return material
}

HexShader.setWebColor = function(material, c) {
    material.setVector3('color2', color(c, 2))
}

BABYLON.Effect.ShadersStore.trackFragmentShader = `precision highp float;

const float PI = 3.1415926535897932384626433832795;
const float PI_3 = PI/3.0;

// Varying
varying vec3 vPosition;
varying vec3 vNormal;
varying vec2 vUV;

// Uniforms
uniform mat4 world;
uniform vec3 backgroundColor;
uniform vec3 hexEdgeColor;
uniform vec3 band1Color;
uniform vec3 band2Color;
uniform vec3 band3Color;
uniform vec3 edge1Color;
uniform vec3 edge2Color;
uniform vec3 edge3Color;
uniform vec2 stroke1;
uniform vec2 stroke2;
uniform vec2 stroke3;
uniform float centerWidth;

//uniform vec3 cameraPosition;

mat2 rotate2d(float _angle){
	float s = sin(_angle);
	float c = cos(_angle);
	return mat2(c, -s, s, c);
}

// Mask a angle
//float mask = step(abs(atan(st.x,st.y)), 3.1);

float hex(in vec2 p){
    float hexSize = 0.5;
    const vec2 s = vec2(1.0, 1.73205080757);
    
    p = abs(p);
    return max(dot(p, s*.5), p.x) - hexSize;
}

float circle(in vec2 _st, in float _radius, in vec2 _offset){
    float dist = distance(_st, _offset);
	return -smoothstep(_radius-(_radius*0.02),
                         _radius+(_radius*0.02),
                         dist);
}

// _radius is center
float band(in vec2 _st, in float _radius, in float _width, in vec2 _offset){
    float _half = _width*0.5;
    float val = circle(_st,_radius+_half,_offset);
    val -= circle(_st, _radius-_half,_offset);
    return val;
}

vec3 addTrack(in vec2 _st, in vec3 _original, in vec3 _edges, in vec3 _center, in vec2 _offset, in float _radius) {
    float val = band(_st,_radius,6.0,_offset);
    float val2 = band(_st,_radius,3.5,_offset);

    float mask = step(abs(atan(_st.x,_st.y)), 3.1);
    
    vec3 color = _original;
    color = mix(_original, _edges, val);
    color = mix(color, _center, val2);
    return color;
}

vec2 shortSideToOff(in float side) {
    // if (side == 0) return vec2(0.0, 16.0);
    // if (side == 3) return vec2(0.0, -16.0);
    return vec2(-sin((side+4.0)*PI_3)*16.0, cos((side+4.0)*PI_3)*16.0);
}

vec3 shortTrack(in vec2 _st, in vec3 _o, in vec3 _edges, in vec3 _center, in float side) {
    vec2 off = shortSideToOff(side); //vec2(0.0, -16.0);
    float radius = 8.0;
    return addTrack(_st, _o, _edges, _center, off, radius);
}

const float distoff = 14.310835056*2.0;
vec3 longTrack(in vec2 _st, in vec3 _o, in vec3 _c1, in vec3 _c2, in float side) {
    vec2 off = vec2(-sin((side+4.5)*PI_3)*distoff, cos((side+4.5)*PI_3)*distoff);
    float radius = 24.8;
    return addTrack(_st, _o, _c1, _c2, off, radius);
}

float ribbon(in vec2 _st, in float _width){
    float _half = _width*0.5;
    vec2 a = step(vec2(_half),_st);
    vec2 b = step(vec2(-_half),_st);
    float val = -a.x + b.x;
    return val;
}

vec3 ribbonTrack(in vec2 _st, in vec3 _original, in vec3 _edges, in vec3 _center, in float side) {
    vec2 rot = _st * rotate2d((-side-0.5)*PI_3);
    float val = ribbon(rot, 6.0);
    float val2 = ribbon(rot, 3.5);
    vec3 color = _original;
    color = mix(_original, _edges, val);
    color = mix(color, _center, val2);
    return color;
}

void main(void) {

    vec3 vLightPosition = vec3(0, 200, 10);

    // World values
    vec3 vPositionW = vec3(world * vec4(vPosition, 1.0));
    vec3 vNormalW = normalize(vec3(world * vec4(vNormal, 0.0)));
    // vec3 viewDirectionW = normalize(cameraPosition - vPositionW);

    // Light
    vec3 lightVectorW = normalize(vLightPosition - vPositionW);

    // diffuse
    float ndl = max(0., dot(vNormalW, lightVectorW));

    // Specular
    // vec3 angleW = normalize(viewDirectionW + lightVectorW);
    // float specComp = max(0., dot(vNormalW, angleW));
    // specComp = pow(specComp, max(1., 64.)) * 2.;

    vec2 uv = vPosition.yx;

    float hole = smoothstep(centerWidth+0.05,centerWidth-0.05, hex(uv));
    vec3 background = mix(hexEdgeColor, backgroundColor, hole);

    vec3 color = background;
    if (stroke1[1] == 1.0) {
        color = shortTrack(uv, color, edge1Color, band1Color, stroke1[0]);
    } else if (stroke1[1] == 2.0) {
        color = longTrack(uv, color, edge1Color, band1Color, stroke1[0]);
    } else if (stroke1[1] == 3.0) {
        color = ribbonTrack(uv, color, edge1Color, band1Color, stroke1[0]);
    } else if (stroke1[1] == 4.0) {
        color = ribbonTrack(uv, color, edge1Color, band1Color, stroke1[0]);
    }
    if (stroke2[1] == 1.0) {
        color = shortTrack(uv, color, edge2Color, band2Color, stroke2[0]);
    } else if (stroke2[1] == 2.0) {
        color = longTrack(uv, color, edge2Color, band2Color, stroke2[0]);
    } else if (stroke2[1] == 3.0) {
        color = ribbonTrack(uv, color, edge2Color, band2Color, stroke2[0]);
    }
    if (stroke3[1] == 1.0) {
        color = shortTrack(uv, color, edge3Color, band3Color, stroke3[0]);
    } else if (stroke3[1] == 2.0) {
        color = longTrack(uv, color, edge3Color, band3Color, stroke3[0]);
    } else if (stroke3[1] == 3.0) {
        color = ribbonTrack(uv, color, edge3Color, band3Color, stroke3[0]);
    }

    gl_FragColor = vec4(color*ndl, 1. );
}
`

BABYLON.Effect.ShadersStore.webFragmentShader = `precision highp float;

// More hex stuff see: https://www.shadertoy.com/view/XdSyzK

const float PI = 3.1415926535897932384626433832795;
const float PI_3 = PI/3.0;

// Varying
varying vec3 vPosition;
varying vec3 vNormal;
varying vec2 vUV;

// Uniforms
uniform mat4 world;
uniform vec3 color1;
uniform vec3 color2;
uniform float centerWidth;
uniform float frequency;

//uniform vec3 cameraPosition;

mat2 rotate2d(float _angle){
	float s = sin(_angle);
	float c = cos(_angle);
	return mat2(c, -s, s, c);
}

float tri(float x) {
    return abs(0.5*x-floor(0.5*x)-0.5)*2.0;
}

const float hex_factor = 0.8660254037844386;
float hexDistPointUp(vec2 p) {
    p = abs(p);
    return max(dot(p, vec2(0.5,hex_factor)), p.x) - 10.0;
}

float hexDistPointRight(vec2 p) {
    p = abs(p);
    return max(dot(p, vec2(hex_factor, 0.5)), p.y) - 0.5;
}

void main(void) {

    vec3 vLightPosition = vec3(0, 200, 10);

    // World values
    vec3 vPositionW = vec3(world * vec4(vPosition, 1.0));
    vec3 vNormalW = normalize(vec3(world * vec4(vNormal, 0.0)));
    // vec3 viewDirectionW = normalize(cameraPosition - vPositionW);

    // Light
    vec3 lightVectorW = normalize(vLightPosition - vPositionW);

    // diffuse
    float ndl = max(0., dot(vNormalW, lightVectorW));

    // Specular
    // vec3 angleW = normalize(viewDirectionW + lightVectorW);
    // float specComp = max(0., dot(vNormalW, angleW));
    // specComp = pow(specComp, max(1., 64.)) * 2.;

    vec2 uv = vPosition.yx;

    //float hole = smoothstep(centerWidth+0.05,centerWidth-0.05, hex(uv));

    float d = hexDistPointUp(uv);
    //float k = tri(100.0*d*(mod(u_time,10.)/10.) + 10.0);
    float k = tri(0.35*d); // 0.49
    float b = smoothstep(0.5-0.02,0.5+0.02,k);
    //vec3 color = mix(vec3(0.1,0.5,0.5),vec3(0.5,0.1,0.5),b);
    vec3 color = mix(color2,color1,b);

    gl_FragColor = vec4(color*ndl, 1. );
}
`

BABYLON.Effect.ShadersStore.hexVertexShader = `precision highp float;

// Attributes
attribute vec3 position;
attribute vec3 normal;
attribute vec2 uv;

// Uniforms
uniform mat4 worldViewProjection;

// Varying
varying vec3 vPosition;
varying vec3 vNormal;
varying vec2 vUV;

void main() {

    vec4 p = vec4( position, 1. );
    gl_Position = worldViewProjection * p;

    vPosition = position;
    vNormal = normal;
    vUV = uv;
}
`

module.exports = HexShader