var State = require('./State.js')
var Board = require('./Board.js')
var Game = require('./Game.js')
var Log = require('./Log.js')
const Color = require('./Color.js')
const HexGroup = require('./HexGroup.js')

const knotBanner = require('./home/Banner')
const Input = require('./Input.js')
const Move = require('./Move.js')
const Material = require('./Material.js')
const Path = require('./Path.js')
const UDesign = require('./UDesign.js')
const Store = require('./Store.js')
const Hex = require('./Hex.js')

var Manager = {}

// appearance, location, behavior
Manager.startNewGame = function(gameName) {
    Game.clear()
    Log.gameChange({
      type: "newGame",
      game: gameName
    })
    State.boards = State.gameList[gameName]
    State.currentBoard = 0
    var boards = State.gameList[gameName]
    var board = boards[0]
    if (State.games[board.name].pieces) {
      if (board.modify) {
        State.games[board.name].modify = board.modify
      }
      if (board.dynamicPieces) {
        State.games[board.name].dynamicPieces = board.dynamicPieces
      }
      State.game = {
          name: board.name
      }
      Board.start(State.games[board.name], board.history)
    } else {
        console.log("bad setup")
    }
}

// appearance, location, behavior
Manager.startNewGameToo = function(def) {
    Game.clear()
    Log.gameChange({
      type: "newGame",
      game: def.game
    })
    State.boards = def.boards
    State.currentBoard = 0
    var board = def.boards[0]
    if (State.games[board.name]) {
        if (State.games[board.name].pieces) {
            if (board.modify) {
              State.games[board.name].modify = board.modify
            }
            if (board.dynamicPieces) {
              State.games[board.name].dynamicPieces = board.dynamicPieces
            }
            State.game = {
                name: board.name
            }
            Board.start(State.games[board.name], board.history)
        }
        else {
            console.log('State.games.'+board.name+' without pieces')
        }
    } else {
        console.log("bad setup")
    }
}

Manager.simple = function(board) {
    Game.clear()
    if (!State.game) State.game = {}
    State.game.cameraPositionY = UDesign.main.cameraPositionY
    State.game.rotation = UDesign.main.rotation
    State.ground.locked = !!UDesign.main.lockGround

    State.game.upRotated = false
    State.game.widthAdjustment = -Hex.hexWorldWidth*0.75

    const base = UDesign.main.pieces.slice()
    State.game.dimensions = Board.dimensions(base)
    Board.setCamera(window.innerWidth, window.innerHeight)

    Log.snapshotEnabled = false
    Board.start(board)
    Log.snapshotEnabled = true
}

Manager.next = function() {
    Board.celebrate(State.boards[State.currentBoard], Manager.nextBoard)
}

// appearance, location, behavior
Manager.nextBoard = function(newGame) {
    Board.clear(State.scene)
    State.currentBoard = (State.currentBoard+1)%State.boards.length
    var board = State.boards[State.currentBoard]
    if (State.games[board.name].pieces) {
        // TODO Fix this.
        if (board.modify) {
            State.games[board.name].modify = board.modify
        }
        if (board.dynamicPieces) {
            State.games[board.name].dynamicPieces = board.dynamicPieces
        }
        Board.start(State.games[board.name])
    } else {
        console.log("Bad setup")
    }
}

Manager.lookupGameAndGo = function(mesh) {
    if (mesh && mesh.label) {
        Manager.startNewGameToo(mesh.def.def)
    }
}

function trashIf(mesh) {
    let atHex = Path.meshesAtHex(mesh.hex)
    const destroy = atHex.reduce(
        (tf,stackmate) => tf || (stackmate !== mesh && stackmate.label == 'trashcanSVG'),
            false
    );
    return destroy
}

Manager.mainChoiceOn = {
    snap: function(mesh) {
        let remove = trashIf(mesh)
        if (remove) {
            let history = []
            mesh.def.def.boards[0].history = history
            State.history.uDesign[mesh.label] = history
            State.history.encoded[mesh.label] = ""
            mesh.label = '+'
            mesh.def.def.label = '+'
            Store.persist()
            Material.redraw(mesh)
        }
        Move.toHex(mesh, mesh.originHex)
    },
    tap: function(mesh) {
        if (State.history.uDesign[mesh.label].length == 0) {
            let history = [{
                command: "boardLoad",
                data: UDesign.main.pieces.slice()
            }]
            mesh.def.def.boards[0].history = history
            State.history.uDesign[mesh.label] = history
            State.history.encoded[mesh.label] = ""
            Store.persist()
        }
        let label = mesh.id-1 // Cheating: order of generation dependent
        mesh.def.def.label = label
        mesh.label = label
        const route = 'd/'+(mesh.id-1)
        State.router.navigate(route)
    },
    pointerDown: Input.tapAndDragStart,
    pointerUp: Input.tapAndDragEnd,
    drag: Input.drag
}

Manager.createOn = {
    pointerDown: function(mesh) {
        let label = mesh.id-1 // Cheating: order of generation dependent
        let history = [{
            command: "boardLoad",
            data: UDesign.main.pieces.slice()
        }]
        mesh.def.def.boards[0].history = history
        State.history.uDesign[mesh.label] = history
        State.history.encoded[mesh.label] = ""
        Store.persist()
        mesh.def.def.label = label
        mesh.label = label
        const route = 'd/'+(mesh.id-1)
        State.router.navigate(route)
    },
    drag: function() {}
}

Manager.gameOn = {
//    snap: function() {},
    pointerDown: function(mesh) {
        const route = mesh.label
        State.router.navigate(route)
    },
//    pointerDown: function() {},
//    pointerUp: function() {},
    drag: function() {}
}

Manager.mainChoices = function() {

    const backgroundColors = ["Green Haze", "Turbo", "Endeavour", "Mulberry", "Tango", "Supernova"]

    function tileDef(def, hex, i, locked, on) {
        return {
            name: "tile",
            type: "labeled",
            label: def.label,
            hex: hex,
            locked: locked,
            strokes: [],
            backgroundColor: Color.named.colors[backgroundColors[i%backgroundColors.length]],
            hexEdgeColor: "#333",
            y: State.yField,
            on: on,
            def: def
        }
    }

    const designs = Game.getDesigns()
    const billiardsHex = [6, -3]
    const mazesHex = [5, -2]
    const trashcanHex = [6, -4]
    const designHexes = HexGroup.move(
        HexGroup.parallelogramB([-4,-1],[-2,6],[]).reverse(),
        [1, 0]).filter((e) =>
          ((e[0] !== billiardsHex[0]) || (e[1] !== billiardsHex[1])) &&
          ((e[0] !== mazesHex[0]) || (e[1] !== mazesHex[1])) &&
          ((e[0] !== trashcanHex[0]) || (e[1] !== trashcanHex[1]))
        )

    const designTiles = designs.length <= designHexes.length
        ? designs.map(function(d,i) {
            return tileDef(d, designHexes[i], i, d.label == '+', d.label == '+' ? Manager.createOn : Manager.mainChoiceOn)
        })
        : designHexes.map(function(h,i) {
            return tileDef(designs[i], h, i, false, Manager.mainChoiceOn)
        })

    const trashcanTile = [{
            strokes: [],
            backgroundColor: Color.colors[Color.schemes[3][1]],
            hexEdgeColor: Color.colors[Color.schemes[3][2]],
            name: "tile",
            label: "trashcanSVG",
            type: "icon",
            hex: trashcanHex,
            locked: true,
            y: State.yGenerator,
            on: {
                snap: function() {},
                pointerDown: function() {},
                pointerUp: function() {},
                drag: function() {}
            }
    }]
    
    const infoTile = [{
        name: "tile",
        type: "labeled",
        label: "?",
        hex: [5, -1],
        locked: true,
        strokes: [],
        backgroundColor: Color.named.colors[backgroundColors[0%backgroundColors.length]],
        hexEdgeColor: "#333",
        y: State.yField,
        on: {
            snap: function() {},
            pointerDown: function() {
                State.router.navigate('/info')
            },
            pointerUp: function() {},
            drag: function() {}
        }
        //def: def
    }]



    const mazeTile = [tileDef(Game.mazes, mazesHex, 1, true, Manager.gameOn)]
    const loopTile = [tileDef(Game.loop, [6, -2], 2, true, Manager.gameOn)]
    const billiardsTile = [tileDef(Game.billiards, billiardsHex, 4, true, Manager.gameOn)]

    let all = designTiles.concat(trashcanTile)
        .concat(knotBanner)
        .concat(infoTile)
        .concat(mazeTile)
        .concat(billiardsTile)
        .concat(loopTile)

    return all
}

Manager.restartMainChoices= function() {
    State.router.navigate('/');
}

State.gameList.mainChoices = [{
    name: "mainChoices",
    dynamicPieces: Manager.mainChoices
}]

module.exports = Manager
