var State = require('./State.js')
var Color = require('./Color.js')
var Hex = require('./Hex.js')
var Tile = require('./Tile.js')
var Path = require('./Path.js')
var Material = require('./Material.js')
var Draw = require('./Draw.js')
var Log = require('./Log.js')
var Animate = require('./Animate.js')
var Collision = require('./Collision.js')
var Store = require('./Store.js')
const HexShader = require('./shaders/HexShader.js')
var Move = require('./Move.js')
//var Thumbnails = require('./Thumbnails.js')
//var Board = require('./Board.js')
//var Manager = require('./Manager.js')

// Add and subtract things from environment and move things
// TODO Move on-behavior changes to On
// TODO Move generative routines to Generate
// TODO Move development utility routines (labeling) to DevUtil

var Action = {}

// appearance
Action.dispose = function(mesh) {
  Log.gameChange({
    type: "dispose",
    meshId: mesh.id
  })
  mesh.dispose()
  mesh = null
}

// appearance, location, behavior
Action.removeAndZoom = function(mesh){
    // Forcefully move from other collisions
    var i = Collision.agents.indexOf(mesh)
	if (i > -1) { Collision.agents.splice(i, 1) }
    Action.zoomAway(mesh)
}

// appearance, location, behavior
Action.bounce = function(meshes){
  meshes.forEach(function(mesh) {
    Action.removeAndZoom(mesh)
  })
}

// appearance, location, behavior
Action.zoomAway = function(mesh) {
    mesh.disposing = true
    var position = mesh.position.clone()
    position.z += 105
    position.y += 500
    mesh.hex = [-1000, -1000]
    Animate.worldMove(mesh, position, 80, function() {}) //, Action.dispose)
    Log.gameChange({
      type: "zoomAway",
      meshId: mesh.id
    })
}

// appearance, location, behavior
Action.zoomAwayPath = function(path) {
    path.path.forEach(function(p) {
	  Action.zoomAway(p.mesh)
	})
}

// location, behavior
Action.marbleTile = function(hexArc) {
  var marble = Draw.makeMarble("#000000")
  marble.id = State.tileId++
  Collision.agents.push(marble)
  // TODO fix jump at end
  //var positions = Path.spiral(hexArc.hex)
  var positions = []
  positions.push(Hex.hexToWorld(hexArc.hex))
  positions.push(Hex.hexToWorld(hexArc.hex))
  positions.push(Hex.hexToWorld(hexArc.hex))
  Animate.meshToPositionNextHexArc(marble, positions, Action.nextArc, hexArc)
}

Action.report = {}
Action.report.all = false
Action.report.colorMarbleDrop = false

// TODO Make debugging reports such as Action.report.all part of the Log module
// TODO Of the form Log.debug("Action", func) or Log.debug("Action.colorMarbleDrop",func)
// TODO func code lives here. The flags for running that code are in log. The func code
// TODO needs to return the output (don't console.log itself)
// appearance, location, behavior
Action.colorMarbleDrop = function(marble) {
    console.log("colorMarbleDrop")
    var report = (Action.report.all || Action.report.colorMarbleDrop)
    if (report) {
        console.log("animationCount:", marble.animationCount)
    }
    if (marble.animationCount > 0) {
        if (marble.myAnimation) {
            marble.myAnimation.pause()
        }
        if (report) {
        }
        marble.animationCount = -1
    } else {
      marble.hex = Hex.worldToFractionalHex(marble.position)
      var hex = Hex.nearestHexCenter(marble.position)
      var hexWorld = Hex.hexToWorld(hex)
      var offsetFromCenter = hexWorld.subtract(marble.position)

      var topMesh = Path.topMesh(hex)
      if (!topMesh || topMesh.name !== "tile") {
          return
      }
      var arc = Path.closestArcFromTile(topMesh, offsetFromCenter)
      marble.animationCount = 0
      Action.firstArc(marble,{
        hex: hex,
        arc: arc,
        mesh: topMesh,
        animationCount: 0
      })
    }
}

// appearance
Action.colorTileHere = function(allTiles, mesh) {
    if (allTiles.length > 2) {
      allTiles.forEach(function(tile) {
        if (tile.def.type !== 'frame' && mesh.id !== tile.id) {
          tile.backgroundColor = mesh.def.backgroundColor
          if (tile.def.shader == 'track') {
            HexShader.setBackgroundAndHexEdgeColors(tile.material, tile)
          } else if (tile.def.shader == 'web') {
            HexShader.setWebColor(tile.material, mesh.def.backgroundColor)
          } else {
            Material.redraw(tile)
          }
          var id = tile.id
          var oldcolor = tile.backgroundColor
          var newcolor = mesh.def.backgroundColor
          //Thumbnails.updateCurrent("uDesign")
          Store.localChange({
                command: "color background",
                data: {
                  id: id,
                  oldcolor: oldcolor,
                  newcolor: newcolor
                }
          })
          Store.persist()
        }
      })
    }
}

// appearance
Action.newCenterColor = function(path, colorStrokeBandColor) {
  var mesh = path.mesh
  var temp = [(path.arc[0]-path.mesh.side+6)%6,(path.arc[1]-path.mesh.side+6)%6]
  var colorStroke = (temp[0]>temp[1]?[temp[1], temp[0]]:[temp[0],temp[1]])
  var backgroundColor = mesh.backgroundColor // Color.colors[colorScheme[1]]
  var hexEdgeColor = mesh.hexEdgeColor
  for (var i=0; i < mesh.strokes.length; i++) {
    var s = mesh.strokes[i]
    if (s.stroke[0] === colorStroke[0] && s.stroke[1] === colorStroke[1]) {
        if (colorStrokeBandColor === s.bandColor) {
            // Early abort
            return;
        }
    }
  }
  var strokes = mesh.strokes.map(function(s) {
    if (s.stroke[0] === colorStroke[0] && s.stroke[1] === colorStroke[1]) {
        return {
            stroke: s.stroke,
            edgeColor: s.edgeColor,
            bandColor: colorStrokeBandColor
        }
    } else {
        return {
            stroke: s.stroke,
            edgeColor: s.edgeColor,
            bandColor: s.bandColor
        }
    }
  })
  var oldStrokes = mesh.strokes.slice()
  var newStrokes = strokes.slice()
  mesh.strokes = strokes
  if (mesh.def.shader == 'track' && mesh.def.type !== 'frame') {
    HexShader.setStrokes(mesh.material, mesh)
  } else {
    Material.redraw(mesh)
  }
  //Thumbnails.updateCurrent("uDesign")
  if (State.game.name == "uDesign") {
    Store.localChange({
      command: "color strokes",
      data: {
                  id: mesh.id,
                  oldStrokes: oldStrokes,
                  newStrokes: newStrokes
      }
    })
    Store.persist()
  }
}

// TODO: Test
// appearance
Action.removeCurveHereViaTemplate = function(template) {
    var targetTileHex = template.hex
    var allTiles = Path.meshesAtHex(targetTileHex)
    if (allTiles.length > 1) {
      allTiles.forEach(function(tile) {
        if (tile !== template) {
            var numberDeleted = Tile.deleteArcsIfExist(tile, template)
            if (numberDeleted > 0) {
              Material.redraw(tile)
            }
        }
      })
    }
}

Action.checkFinish = function(mesh) {
    if (mesh.type && mesh.type === "agentHereFinish") {
      mesh.type = "finish"
      return true;
    }
    return false;
}

// location, behavior
Action.rollMarblePath = function(path, marble) {
    var marble = marble || Draw.makeMarble("#000000")
    marble.id = State.tileId++
    Collision.agents.push(marble)

    var positions = Path.curve(path.path[0])
    Action.newCenterColor(path.path[0], marble.color)

    if (Action.checkFinish(path.path[0].mesh)) {
        State.Manager.next()
        return;
    }

    Animate.meshToPositionNextHexArc(marble, positions, Action.nextArc, path.path[0])
}

// location, behavior
Action.firstArc = function(mesh, hexArc) {
  if (mesh.fps === -1) {
      console.log("Animation count", mesh.animationCount)
      return
  }
  hexArc.animationCount = mesh.animationCount

  var positions = hexArc.mesh.type == "web"
      ? Path.halfCurve(hexArc)
      : Path.curve(hexArc)

  // This doesn't work because it gives it a odd bounce. So: teleport to far end
  // on drop instead
  //   positions.unshift(mesh.position)
  mesh.hex = hexArc.hex
  var fps = mesh.fps || 30

  if (hexArc.mesh.type == "web") {
    Animate.meshToPositionNextHexArc(mesh, positions, Action.exit, hexArc, fps)
  } else {
    Action.nextArc(mesh, hexArc)
  }
}

// location, behavior
Action.nextArc = function(mesh, hexArc) {
  // Multiple callbacks, get rid of previous iterations simply by returning
  if (hexArc.mesh.type !== "web") {
    Action.newCenterColor(hexArc, mesh.color)
  }
  if (Action.checkFinish(hexArc.mesh)) {
    State.Manager.next()
    //return;  Commented out to keep the ball rolling.
  }

  if (mesh.animationCount !== hexArc.animationCount) { return }
  if (mesh.disposing) { return }
  State.scene.stopAnimation(mesh)
  var next = Path.next(hexArc)
  mesh.hex = hexArc.hex
  mesh.animationCount = (mesh.animationCount | 0) + 1
  if (next === null) {
    // Bounce (go the other way)
    next = {
      hex: hexArc.hex,
      arc: [hexArc.arc[1], hexArc.arc[0]],
      mesh: hexArc.mesh,
      animationCount: mesh.animationCount
    }
  } else {
    next.animationCount = mesh.animationCount
  }

  var positions = hexArc.mesh.type == "web"
      ? Path.halfCurve(hexArc)
      : Path.curve(hexArc)

  var fps = mesh.fps || 30

  if (hexArc.mesh.type == "web") {
    Animate.meshToPositionNextHexArc(mesh, positions, Action.exit, next, fps)
  } else {
    Animate.meshToPositionNextHexArc(mesh, positions, Action.nextArc, next, fps)
  }

}

Action.exit = function(mesh, hexArc) {
  State.scene.stopAnimation(mesh)
}

// location, behavior
Action.moveMeshOneTile = function(mesh, hexArc, atNextTile) {
  var triple = Path.hexArcToWorld(hexArc)
  var positions = Path.makeEvenCurve(triple)
  Animate.meshToPositions(mesh, positions, atNextTile)
}

// behavior
Action.tapTile = function(mesh) {
  Log.gameChange({
    type: "tapTile",
    meshId: mesh.id // Generated from State.tileId in Generate.hexTile
  })
  mesh.side = Tile.rotateByOneSide(mesh.side)
  Animate.rotateTile(mesh)
}

// appearance, location
Action.randomHexTile = function(scene, parent) {
  var strokesA = Game.pickTileStrokes()
  var colorScheme = Color.schemes[Random.generateIndex(Color.schemes.length)]

  var strokes = Game.addColorToStrokes(strokesA, Color.colors[colorScheme[0]], Color.colors[colorScheme[2]])

  var q = Random.generateIndex(5) -2
  var r = Random.generateIndex(5) -2

  var w = Hex.hexToWorld([q,r],  Hex.worldHexRatio)

  Generate.hexTile({
    strokes: strokes,
    backgroundColor: Color.colors[colorScheme[1]],
    hexEdgeColor: Color.colors[colorScheme[2]],
	  x: w.x,
	  y: State.yField,
	  z: w.z,
	  parent: parent,
	  name: "tile",
    type: "normal",
    hex: [q, r]
  })
}

// appearance, location
Action.randomGridHexTile = function() {
  var colorScheme = Color.schemes[4]
  var inGrid = HexGroup.hex(3)
  inGrid.forEach(function(hex) {
      var strokesA = Game.pickTileStrokes()
      var strokes = Game.addColorToStrokes(strokesA, Color.colors[colorScheme[0]], Color.colors[colorScheme[2]])
      var w = Hex.hexToWorld(hex,  Hex.worldHexRatio)
      Generate.hexTile({
        strokes: strokes,
        backgroundColor: Color.colors[colorScheme[1]],
        hexEdgeColor: Color.colors[colorScheme[2]],
	      x: w.x,
	      y: State.yField,
	      z: w.z,
	      parent: State.ground,
		    name: "tile",
        type: "normal",
        hex: hex
      })
  })
  var andGrid = HexGroup.ring(5)
  andGrid.forEach(function(hex) {
      var strokesA = Game.pickTileStrokes()
      var colorScheme = Color.schemes[Random.generateIndex(Color.schemes.length)]
      var strokes = Game.addColorToStrokes(strokesA, Color.colors[colorScheme[0]], Color.colors[colorScheme[2]])

      var w = Hex.hexToWorld(hex,  Hex.worldHexRatio)
      Generate.hexTile({
        strokes: strokes,
        backgroundColor: Color.colors[colorScheme[1]],
        hexEdgeColor: Color.colors[colorScheme[2]],
	      x: w.x,
	      y: State.yField,
	      z: w.z,
	      parent: State.ground,
		    name: "tile",
        type: "normal",
        hex: hex
      })
  })
}

// appearance, location, behavior
Action.addRandomHexTileEveryX = function() {
    if (!State.waitTime) { State.waitTime = 3000 }

	Action.randomHexTile(scene, State.ground)
    //var mesh = scene.meshes[Random.generateIndex(scene.meshes.length)]
    //var newPosition = new BABYLON.Vector3(mesh.position.x+Random.generateIndex(20)-10,mesh.position.y, mesh.position.z+Random.generateIndex(20)-10)
    //Animate.worldMove(mesh, newPosition)

    if (State.waitTime > 1000) { State.waitTime-- }

    // TODO Change pause when off page (GPU loop)
    setTimeout(Draw.addRandomHexTileEveryX, State.waitTime)
}

module.exports = Action;
