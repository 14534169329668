var Color = require('./Color.js')
var Tile = require('./Tile.js')

var Share = {}

Share.encodeColor = function(c) {
   if (+c == c) return c
   if (Color.hexDir[c] !== undefined) return Color.hexDir[c]
   //console.log('Unknown color:', c)
   return 0
}

Share.encodeStroke = function(stroke) {
    const b = Share.encodeColor(stroke.bandColor)
    const e = Share.encodeColor(stroke.edgeColor)
    const s1 = stroke.stroke[0]
    const s2 = stroke.stroke[1]
    const result = 's'+s1+s2+'b'+b+'e'+e
    return result   
}

Share.encodeStrokes = function(strokes) {
    const result = strokes.reduce((a, s)=> a + Share.encodeStroke(s), "")
    return result
}

// Align with def
Share.encodeTile = function(mesh) {
    if (!mesh.hex) return '';
    const q = mesh.hex[0]
    const r = mesh.hex[1]
    const i = mesh.side
    const b = Share.encodeColor(mesh.backgroundColor)
    const e = Share.encodeColor(mesh.hexEdgeColor)
    const prefix = 'q'+q+'r'+r+'i'+i+'b'+b+'e'+e
    
    if (mesh.type == 'web') {
        return prefix + 'w'
    } else {
        const ss = Share.encodeStrokes(mesh.strokes)
        return prefix + ss
    }
}

// q, r - hex coordinates
// i - side 0-5
// b - hex background color
// e - hex edge color
// s... s... - strokes [0-5] start side, [0-3] length, b band color, e band edge color
Share.encode = function(tiles) {
    const result = tiles.reduce((a, s)=> a + Share.encodeTile(s), "")
    return result
}

Share.strokes = function(s) {
        const strokeMatch = /s[^s]*/g
        const ss = [...s.matchAll(strokeMatch)]
        const sss = ss.map((s) => s[0])
        return sss
}

Share.parseStroke = function(s) {
    const strokeParse = /(s([0-5])([0-5])b([0-9]+)e([0-9]+))/
    const ss = s.match(strokeParse)
    return ss
}

Share.matchStroke = function(s) {
    const ss = Share.strokes(s)
    const sss = ss.map((s) => Share.parseStroke(s))
    return sss
}

Share.matchTile = function(s) {
    const tileMatch = /(q(\-?[0-9]+)r(\-?[0-9]+)i([0-5])b([0-9]+)e([0-9]+))([^q]*)/g
    const ts = [...s.matchAll(tileMatch)]
    return ts
}

Share.decodeStroke = function(s) {
    const stroke = [+s[2], +s[3]]
    const bandColor = +s[4]
    const edgeColor = +s[5]
    const result = {
        stroke,
        bandColor,
        edgeColor
    }
    return result
}

Share.decodeStrokes = function(s) {
    if (s[0] == 'w') {
        const strokes = Tile.webSideAndStrokes()
        return {type: 'web', shader: 'web', strokes: strokes}
    } else {
        const ss = Share.matchStroke(s)
        const strokes = ss.map(Share.decodeStroke)
        return {type: 'normal', shader: 'track', strokes: strokes}   
    }
}

Share.decodeTile = function(p) {
    const hex = [+p[2], +p[3]]
    const side = +p[4]
    const backgroundColor = Color.colors[+p[5]]
    const hexEdgeColor = Color.colors[+p[6]]
    const typeAndStrokes = Share.decodeStrokes(p[7])

    const result = {
        hex,
        side,
        backgroundColor,
        hexEdgeColor,
        shader: typeAndStrokes.shader,
        strokes: typeAndStrokes.strokes,
        type: typeAndStrokes.type
    }
    return result
}

Share.decode = function(s) {
    const ts = Share.matchTile(s)
    const tiles = ts.map(Share.decodeTile)
    return tiles
}

Share.decodeAndExtra = function(s, locked, on) {
    const name = "tile"
    const y = 0.3

    const result = Share.decode(s).map((def) => {
        Object.assign(def, {
            name,
            y,
            locked,
            on
        })
        return def
    })
    return result
}

Share.encodeAllNormal = function() {
    const normal = State.scene.meshes.reduce((a, m) => {
        if (m.type == 'normal' || (m.type == 'web' && !m.generator)) {
            a.push(m)
        }
        return a
    }, [])
    const result = Share.encode(normal)
    return result
}

module.exports = Share