var State = require('../State.js')
var HexShader = require('./HexShader.js')

var Shaders = {}

Shaders.init = function() {
    Shaders.createMaterial('track', 'hex')
    Shaders.createMaterial('web', 'hex')
    Shaders.createMaterial('font', 'font')
}


Shaders.createMaterial = function(fragment, vertex) {
    //BABYLON.Effect.ShadersStore[vertex+'VertexShader'] = HexShader[vertex+'VertexShader']
    //BABYLON.Effect.ShadersStore[fragment+'FragmentShader'] = HexShader[fragment+'FragmentShader']
    var shaderMaterial = new BABYLON.ShaderMaterial(fragment, State.scene, {
        vertex: vertex,
        fragment: fragment,
    },{
            attributes: ["position", "normal", "uv"],
            uniforms: ["world", "worldView", "worldViewProjection"],
            samplers: ["map"]
    //        uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"]
    });
    Shaders[fragment] = shaderMaterial
}

// Shaders.buildShader = function(name) {
//     if (Shaders[name]) { return Shaders[name].clone()};
//     var shaderMaterial = new BABYLON.ShaderMaterial(name, State.scene, {
//         vertex: name,
//         fragment: name,
//     },
//     {
//         attributes: ["position", "normal", "uv"],
//         uniforms: ["world", "worldView", "worldViewProjection"]
// //        uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"]
//     });

    // var refTexture = new BABYLON.Texture("assets/ref.jpg", State.scene);
    // refTexture.wrapU = BABYLON.Texture.CLAMP_ADDRESSMODE;
    // refTexture.wrapV = BABYLON.Texture.CLAMP_ADDRESSMODE;

    // var amigaTexture = new BABYLON.Texture("assets/amiga.jpg", State.scene);

    // shaderMaterial.setTexture("textureSampler", amigaTexture);
    // shaderMaterial.setTexture("refSampler", refTexture);
    // shaderMaterial.setFloat("time", 0);
    // shaderMaterial.setVector3("cameraPosition", BABYLON.Vector3.Zero());
    // shaderMaterial.backFaceCulling = false;

//     Shaders[name] = shaderMaterial
//     return shaderMaterial
// }

Shaders.getShaderMaterial = function(d) {
    if (!Shaders[d.shader]) {
        console.log(`Shader ${d.shader} not found`)
    }
    let name = d.shader ? d.shader : 'track'
    let mat = Shaders[name].clone()
    if (name == 'track') {
        HexShader.setupTrack(mat, d)
    } else if (name == 'web') {
        HexShader.setupWeb(mat, d)
    } else if (name == 'font') {
        Fonts.setupFont(mat, d)
    }
    return mat
}

BABYLON.Effect.ShadersStore.realFragmentShader = `precision highp float;

varying vec4 vPosition;

float hex(in vec2 p){
    float hexSize = 0.5;
    const vec2 s = vec2(1.0, 1.73205080757);
    
    p = abs(p);
    return max(dot(p, s*.5), p.x) - hexSize;
}

void main(void) {

    vec2 uv = vPosition.yx;
    float limit = 1. - step(1.0, hex(uv));
    float hole = step(3.0, hex(uv));
    float contained = step(1.4, mod(hex(uv),3.0)) * hole;// * limit;
    vec3 base = vec3(contained);

    gl_FragColor = vec4( base, 1. );
}
`
BABYLON.Effect.ShadersStore.realVertexShader = `precision highp float;

// Attributes
attribute vec3 position;

// Uniforms
uniform mat4 worldViewProjection;

// Varying
varying vec4 vPosition;

void main() {

    vec4 p = vec4( position, 1. );

    vPosition = p;

    gl_Position = worldViewProjection * p;

}
`

BABYLON.Effect.ShadersStore.customFragmentShader = `precision highp float;

varying vec2 vUV;

uniform sampler2D textureSampler;

void main(void) {
  vec2 one = vUV + vec2(0.5, 0.0);
  vec2 two = vUV + vec2(-0.5, 0.0);
  float pct = 0.0;
  pct = distance(two,vec2(0.0));
  float g = 1.0 - step(1.0, -step(0.3,pct) + step(0.2, pct));
  float a = step(1.0, -step(0.3,pct) + step(0.28, pct));
  float b = step(1.0, -step(0.28,pct) + step(0.22, pct));
  float c = step(1.0, -step(0.22,pct) + step(0.2, pct));
  vec4 fore = vec4(1.0, 0.0, 0.0, 1.0);
  vec4 black = vec4(0.0, 0.0, 0.0, 1.0);
  vec4 background = vec4(1.0, 1.0, 1.0, 0.5);
  vec4 color = a*black + b*fore + c*black + g*background; //vec3(y);
  gl_FragColor = color;
  //gl_FragColor = texture2D(textureSampler, vUV);
}
`
BABYLON.Effect.ShadersStore.customVertexShader = `precision highp float;

// Attributes
attribute vec3 position;
attribute vec2 uv;

// Uniforms
uniform mat4 worldViewProjection;

// Varying
varying vec2 vUV;

void main(void) {
    gl_Position = worldViewProjection * vec4(position, 1.0);

    vUV = uv + vec2(-0.5); // Center
}
`

BABYLON.Effect.ShadersStore.blobVertexShader =
`precision highp float;
precision highp int;
attribute vec3 position;
attribute vec3 normal;
attribute vec4 color;
attribute vec2 uv;

uniform mat4 worldViewProjection;
uniform sampler2D textureSampler;
uniform int passState;
uniform vec2 uCameraDepths;
uniform vec3 uCamPosition;

uniform float time;

varying vec3 vPosition;
varying vec3 vNormal;
varying float vHeight;
varying vec2 vUV;
varying vec4 vColor;
//varying float vDepth;

//Thanks IQ. <3
vec3 hash( vec3 p ) // replace this by something better
{
	p = vec3( dot(p,vec3(127.1,311.7, 74.7)),
			  dot(p,vec3(269.5,183.3,246.1)),
			  dot(p,vec3(113.5,271.9,124.6)));

	return -1.0 + 2.0*fract(sin(p)*43758.5453123);
}
float noise( in vec3 p )
{
    vec3 i = floor( p );
    vec3 f = fract( p );
	
	vec3 u = f*f*(3.0-2.0*f);

    return mix( mix( mix( dot( hash( i + vec3(0.0,0.0,0.0) ), f - vec3(0.0,0.0,0.0) ), 
                          dot( hash( i + vec3(1.0,0.0,0.0) ), f - vec3(1.0,0.0,0.0) ), u.x),
                     mix( dot( hash( i + vec3(0.0,1.0,0.0) ), f - vec3(0.0,1.0,0.0) ), 
                          dot( hash( i + vec3(1.0,1.0,0.0) ), f - vec3(1.0,1.0,0.0) ), u.x), u.y),
                mix( mix( dot( hash( i + vec3(0.0,0.0,1.0) ), f - vec3(0.0,0.0,1.0) ), 
                          dot( hash( i + vec3(1.0,0.0,1.0) ), f - vec3(1.0,0.0,1.0) ), u.x),
                     mix( dot( hash( i + vec3(0.0,1.0,1.0) ), f - vec3(0.0,1.0,1.0) ), 
                          dot( hash( i + vec3(1.0,1.0,1.0) ), f - vec3(1.0,1.0,1.0) ), u.x), u.y), u.z );
}

void main() {
    vec3 p = position;
    vHeight = noise(p+sin(time));
    p+=vHeight*0.2;

	vPosition = position;	
	vNormal = normal;   
    vUV = uv;
    vColor = vec4(vHeight,vec3(0.0));
    vec4 finalPos = worldViewProjection * vec4( p, 1. );
    gl_Position = finalPos;
    //vDepth = 1.0-((gl_Position.z + uCameraDepths.x) / (uCameraDepths.y));
}`

BABYLON.Effect.ShadersStore.blobFragmentShader =
`precision highp float;
precision highp int;

varying vec3 vPosition;
varying vec3 vNormal;
varying float vHeight;
varying vec4 vColor;
varying vec2 vUV;
//varying float vDepth;

uniform float time;


//	Classic Perlin 3D Noise 
//	by Stefan Gustavson
//
vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
vec3 fade(vec3 t) {return t*t*t*(t*(t*6.0-15.0)+10.0);}

float cnoise(vec3 P){
  vec3 Pi0 = floor(P); // Integer part for indexing
  vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
  Pi0 = mod(Pi0, 289.0);
  Pi1 = mod(Pi1, 289.0);
  vec3 Pf0 = fract(P); // Fractional part for interpolation
  vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
  vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
  vec4 iy = vec4(Pi0.yy, Pi1.yy);
  vec4 iz0 = Pi0.zzzz;
  vec4 iz1 = Pi1.zzzz;

  vec4 ixy = permute(permute(ix) + iy);
  vec4 ixy0 = permute(ixy + iz0);
  vec4 ixy1 = permute(ixy + iz1);

  vec4 gx0 = ixy0 / 7.0;
  vec4 gy0 = fract(floor(gx0) / 7.0) - 0.5;
  gx0 = fract(gx0);
  vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
  vec4 sz0 = step(gz0, vec4(0.0));
  gx0 -= sz0 * (step(0.0, gx0) - 0.5);
  gy0 -= sz0 * (step(0.0, gy0) - 0.5);

  vec4 gx1 = ixy1 / 7.0;
  vec4 gy1 = fract(floor(gx1) / 7.0) - 0.5;
  gx1 = fract(gx1);
  vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
  vec4 sz1 = step(gz1, vec4(0.0));
  gx1 -= sz1 * (step(0.0, gx1) - 0.5);
  gy1 -= sz1 * (step(0.0, gy1) - 0.5);

  vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
  vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
  vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
  vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
  vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
  vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
  vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
  vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

  vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
  g000 *= norm0.x;
  g010 *= norm0.y;
  g100 *= norm0.z;
  g110 *= norm0.w;
  vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
  g001 *= norm1.x;
  g011 *= norm1.y;
  g101 *= norm1.z;
  g111 *= norm1.w;

  float n000 = dot(g000, Pf0);
  float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
  float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
  float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
  float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
  float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
  float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
  float n111 = dot(g111, Pf1);

  vec3 fade_xyz = fade(Pf0);
  vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
  vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
  float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x); 
  return 2.2 * n_xyz;
}

uniform sampler2D textureSampler;

void main() {    
   vec4(1.,1.,1.,1.);
    
    float c1 = cnoise(vPosition+cnoise(vNormal+time));
    float c2 = cnoise(vPosition+cnoise(vNormal-time));

    vec3 co1 = vec3(1.0, 0.0, 0.0);
    vec3 co2 = vec3(0.0, 1.0, 0.0);
    vec3 co3 = vec3(0.0, 0.0, 1.0);

    vec4 color = clamp(vec4(mix(mix(co1, co2, c1),  co3, c2), 1.0), 0.0, 1.0);
    color.rgb*=1.0-pow(vColor.r*0.5+0.5, 3.0);

	gl_FragColor = color;	
}
`

let circles = `
// Author @patriciogv - 2015
// http://patriciogonzalezvivo.com

#ifdef GL_ES
precision mediump float;
#endif

uniform vec2 u_resolution;
uniform vec2 u_mouse;
uniform float u_time;

float circle(in vec2 _st, in float _radius, in vec2 _offset){
    vec2 dist = _st-vec2(0.5)+_offset;
	return 1.-smoothstep(_radius-(_radius*0.01),
                         _radius+(_radius*0.01),
                         dot(dist,dist)*3.6);
}

// _radius is outer radius
float band(in vec2 _st, in float _radius, in float _width, vec2 _offset){
    float val = circle(_st,_radius,_offset);
    val -= circle(_st, _radius-_width,_offset);
    return val;
}

vec3 addTrack(in vec2 _st, in vec3 _original, in vec3 _color1, in vec3 color2, vec2 _offset) {
    float val = band(_st,0.9,0.4,_offset);
    float val2 = band(_st,0.8,0.2,_offset);
    
    vec3 color = (1.0 - val) * _original;
    color += val * _color1;
    color = (1.0 - val2) * color;
    color += val2 * color2;
    return color;
}

void main(){
	vec2 st = gl_FragCoord.xy/u_resolution.xy;
    
    vec2 offset = vec2(.0,.0);
    
    vec3 background = vec3(0.0,0.0,0.0);
    vec3 color1 = vec3(0.7,0.7,0.0);
    vec3 color2 = vec3(0.0,0.0,0.7);
    vec3 color = addTrack(st, background,color1,color2,offset);
    color = addTrack(st,color,color1,color2,vec2(0.4,0.4));

	gl_FragColor = vec4( color, 1.0 );
}
`
module.exports = Shaders;
