import * as BABYLON from 'babylonjs';
import 'babylonjs-procedural-textures';
import Navigo from 'navigo';

var State = require('./State.js')
var Hex = require('./Hex.js')
var Game = require('./Game.js')
var Board = require('./Board.js')
var Input = require('./Input.js')
var Collision = require('./Collision.js')
var Animate = require('./Animate.js')
var Manager = require('./Manager.js')
var Shot = require('./Shot.js')
var Color = require('./Color.js')
var Material = require('./Material.js')
var Info = require('./Info.js')
var Store = require('./Store.js')
var Share = require('./Share.js')
var UDesign = require('./UDesign.js')

const router = new Navigo("/", { hash: true });

// createScene function that creates and return the scene
function create(glContext, BABYLON) {
    var engine = new BABYLON.Engine(glContext, true, {
        preserveDrawingBuffer: true,
        stencil: true,
        //deterministicLockstep: true,
        //lockstepMaxSteps: 4
    });

    var scene = new BABYLON.Scene(engine);

    //scene.autoClearDepthAndStencil = false;
    //scene.autoclear = false;
    scene.clearColor = new BABYLON.Color3(1.0, 0.5, 0.5) // Behind the ground

    var camera = new BABYLON.TargetCamera("Camera", new BABYLON.Vector3(0, 0, 0), scene);
    camera.position = new BABYLON.Vector3(0, 520, 1)
    camera.setTarget(new BABYLON.Vector3(0, 0, 1))

    // Light
    var light = new BABYLON.PointLight("omni", new BABYLON.Vector3(0, 800, 0), scene);
    var light2 = new BABYLON.HemisphericLight("hemi", new BABYLON.Vector3(0, 1600, 0), scene)
    light.intensity = 0.2  // 1.0
    light2.intensity = 0.6

    // Ground
    // appearance, location
	  var groundSize = 10000
    var ground = BABYLON.Mesh.CreateGround("ground", groundSize, groundSize, 1, scene, false);
    ground.position.y = State.yGround
	  ground.material = new BABYLON.StandardMaterial("groundMaterial", scene);
	  ground.material.specularColor = new BABYLON.Color3(0, 0, 0)
    ground.material.diffuseColor = new BABYLON.Color3(0.1, 0.1, 0.1)
    //ground.material.useLogarithmicDepth = true // Improves layering/depth calculation
    ground.locked = true
    ground.on = {
      pointerDown: () => {
        console.log('ground onPointerDown')
      },
      drag: () => {},
      pointerUp: () => {
        console.log('ground onPointerUp')
      }
    }

    scene.clearColor = new BABYLON.Color3(0.5, 1.0, 0.5) // Behind the ground
    Material.generateKnownMaterials()
    return {
        engine: engine,
        scene: scene,
        ground: ground,
        camera: camera
    }
};

function init(canvas, BABYLON, scene) {

  // Events
  // behavior
  State.canvas = canvas;
 // TODO if using PEP, add touch-action: none; to canvas
  canvas.addEventListener("pointerdown", Input.onPointerDown, false);
  canvas.addEventListener("pointermove", Input.onPointerMove, false);
  canvas.addEventListener("pointerup", Input.onPointerUp, false);
  canvas.addEventListener("mousedown", Input.onPointerDown, false);
  // Treat these like pointerup (finish any current action)y
  canvas.addEventListener("pointercancel", Input.onPointerUp, false);
  canvas.addEventListener("pointerout", Input.onPointerUp, false);
  canvas.addEventListener("pointerleave", Input.onPointerUp, false);

  State.scene.onDispose = function () {
    canvas.removeEventListener("pointerdown", Input.onPointerDown, false);
    canvas.removeEventListener("pointerup", Input.onPointerUp, false);
    canvas.removeEventListener("pointermove", Input.onPointerMove, false);
    canvas.removeEventListener("pointercancel", Input.onPointerUp, false);
    canvas.removeEventListener("pointerout", Input.onPointerUp, false);
    canvas.removeEventListener("pointerleave", Input.onPointerUp, false);
  }

/*
  scene.onBeforeStepObservable.add(function(theScene){
       //console.log("Performing game logic, BEFORE animations and physics for stepId: "+theScene.getStepId());
  });

  State.lastTick = Date.now()
  State.delta = Date.now() - State.lastTick
  scene.onAfterStepObservable.add(function(theScene){
       //console.log("Performing game logic, AFTER animations and physics for stepId: "+theScene.getStepId());
       var now = Date.now()
       var delta = now - State.lastTick
       //if (delta !== State.delta) { console.log(delta) }
       State.delta = now - State.lastTick
       State.lastTick = now
  });
*/
  State.tileId = 0
  State.maxMoveForTap = Hex.worldTileDiameter  // For movable tiles

  State.lastDrag = {
    mesh: null,
    hex: null
  }
  State.colorDragHexes = []

  State.Manager = Manager
  State.Hex = Hex

  // appearance
  Board.colorPrototypes = [] // Disabled // Board.pieceColorPrototypes()

  // behavior
  scene.registerBeforeRender(Collision.check)
  scene.registerBeforeRender(Animate.beforeRender)
}

var scene;
var engine;

function post(canvas) {
    // Create the scene (engine, scene, ground, lights, camera)
    var state = create(canvas, BABYLON);
    State = Object.assign(State, state)
    scene = state.scene
    engine = state.engine
    init(canvas, BABYLON, scene)
    var runningAverageTotal = 0
    var runningAverageCount = 0
    var fpsLabel = document.getElementById("fpsLabel");
    engine.runRenderLoop(function(){
          scene.render();
          // var currentFps = engine.getFps()
          // if (currentFps > 60) { currentFps = 60 }
          // runningAverageTotal += currentFps
          // runningAverageCount += 1
          // fpsLabel.innerHTML = (runningAverageTotal/runningAverageCount).toFixed() + "/"+ currentFps.toFixed() + " fps";
    });
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    window.addEventListener('resize', function(e) {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      State.Board.setCamera(window.innerWidth, window.innerHeight)
    });
    State.router = router
    var info = document.getElementById('info');
    info.onclick = function () {
      Info.stop()
      router.navigate('/');
    }
    Store.initialize(function(data) {
      let route = document.location.hash.slice(1)
      router.navigate(route)
    })
}

function go(canvas) {
  router.on("/", function (match) {
      Manager.startNewGame('mainChoices')
  });

  router.on("d/:design", function (match) {
    const design = match.data.design
    State.design = design
    const encoded = State.history.encoded[design]
    if (encoded !== undefined && encoded !== '') {
      State.design = match.data.design
      Board.design(encoded)
    } else {
      const designs = Game.getDesigns()
      Manager.startNewGameToo(designs[design])
      const encoded = Share.encodeAllNormal();
      State.history.encoded[design] = encoded
      State.Store.persist()
      Board.design(encoded)
    }
  });

  function findAndNavigateSpec(spec) {
    const encoded = State.history.encoded
    var empty = ""
    const found = Object.keys(encoded).reduce(function (previous, current) {
      if (previous !== "") return previous
      if (encoded[current] === spec) return current;
      if (empty == "" && encoded[current] == "") {
        empty = current
      }
      return ""
    }, "");
    if (found !== "") {
      router.navigate('d/'+found)
    } else {
      State.history.encoded[empty] = spec
      State.history.uDesign[empty] = [{
        command: "boardLoad",
        data: UDesign.main.pieces.slice()
      }]
      State.Store.persist()
      router.navigate('d/'+empty)
    }
  }

  router.on("/share/:spec", function (match) {
    const spec = match.data.spec
    const tiles = Share.decodeAndExtra(spec, true, {
      pointerDown: () => {
        State.ground.on.pointerDown = () => {}
        findAndNavigateSpec(spec)
        //router.navigate('l/'+spec)
      },
    })
    console.log(tiles)
    const board = {
      cameraPositionY: 498,
      modify: () => {
        State.ground.on.pointerDown = () => {
          State.ground.on.pointerDown = () => {}
          findAndNavigateSpec(spec)
          //router.navigate('l/'+spec)
        }
      },
      lockground: true,
      pieces: tiles
    }
    Manager.simple(board);
  });

  router.on("loop", function (match) {
    const def = Game.loop
    Manager.startNewGameToo(def)
  })

  router.on("billiards", function (match) {
    const def = Game.billiards
    Manager.startNewGameToo(def)
  })

  router.on("mazes", function (match) {
    const def = Game.mazes
    Manager.startNewGameToo(def)
  })

  State.games['test'] = {
    lockground: true,
    pieces: [{
        name: "tile",
        type: "icon",
        label: "shareSVG",
        hex: [0, 0],
        strokes: [],
        backgroundColor: '#fff',
        hexEdgeColor: '#000',
        y: State.yField,
        locked: true,
        on: {}
      }]
  }

  router.on("/info", function(match) {
    Info.start()
  })
  
  router.on("/test", function (match) {
    Manager.startNewGameToo({
      name: 'test',
      boards: [{ name: 'test'}]
    })
  });

  post(canvas);
}

export { go };
