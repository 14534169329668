var Hex = require('./Hex.js')
var Path = require('./Path.js')
var Log = require('./Log.js')
var Animate = require('./Animate.js')

function yReorder(mesh, atHex) {
    atHex.forEach(function(stackmate, i) {
      if (stackmate !== mesh) {
        if (stackmate.type == 'generator' || stackmate.type == 'color' || stackmate.generator) {
          if (!stackmate.generator) {
            console.log('Not marked as generator')
            console.trace()
            console.log(stackmate.def)
            console.log(stackmate)
          }
        } else if (stackmate.type != 'frame') {
          stackmate.position.y = State.yField + (atHex.length - i) * State.yIncrement
        }
      }
    })
  }

function toHex(mesh, hex) {
    let atHex = Path.meshesAtHex(hex)
    yReorder(mesh, atHex)
    var position = Hex.hexToWorld(hex,  Hex.worldHexRatio)
    mesh.position = position
    mesh.position.y = State.yField + (atHex.length+1) * State.yIncrement
    mesh.hex = [hex[0],hex[1]]
    Animate.worldMove(mesh, position, 3)
    return hex
}

function snapToGrid(mesh) {
    var fractionalHex = Hex.worldToFractionalHex(mesh.position,  Hex.worldHexRatio)
    var snapHex = Hex.round(fractionalHex)
    var snapPosition = Hex.hexToWorld(snapHex,  Hex.worldHexRatio)
    var distanceFromCenter = BABYLON.Vector3.Distance(mesh.position, snapPosition)
    if (distanceFromCenter < State.maxMoveForTap) {
        toHex(mesh, snapHex)        
        Log.gameChange({
          type: "moveTile",
          meshId: mesh.id,
          hex: snapHex
        })
        Log.gameChange({
          type: "snappedTile",
          meshId: mesh.id,
          hex: snapHex
        })
        return snapHex
    } else {
        mesh.hex = fractionalHex
        Log.gameChange({
          type: "moveTile",
          meshId: mesh.id,
          hex: fractionalHex
        })
        return false
    }
}

module.exports = { snapToGrid, toHex }
