var Warp = {}

Warp.pullStart = function(mesh) {
    let pos = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
    var numberOfVertices = pos.length/3;
    var result = Array(numberOfVertices);
    for(var i = 0; i<numberOfVertices; i++) {
        result[i] = new BABYLON.Vector3(pos[i*3], pos[i*3+1], pos[i*3+2])
    }
    mesh.startingPoints = result;
}

Warp.pull = function(mesh, diff) {
    var pullDistance = BABYLON.Vector3.Distance(BABYLON.Vector3.ZeroReadOnly, mesh.totalDiff);
    
    // Ignore these positions. All positions are calculated from start
    var positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);

    var numberOfVertices = positions.length/3;
    for(var i = 0; i<numberOfVertices; i++) {
        var startingPoint = mesh.startingPoints[i] //.add(mesh.position)
        var pointDistance = BABYLON.Vector3.Distance(startingPoint, mesh.totalDiff)
        var scale = (pointDistance - mesh.diameter/2.0)/(pullDistance);
        if (scale < 0.0) { scale = 0.0 }
        if (scale > 1.0) {
            scale = 1.0
        }
        let target = startingPoint.add(mesh.totalDiff)
        let newPos = BABYLON.Vector3.Lerp(startingPoint, target, 1.0 - scale)
        positions[i*3] = newPos.x;
        // positions[i*3+1] = newPos.y; // Don't update
        positions[i*3+2] = newPos.z;
    }
    mesh.updateVerticesData(BABYLON.VertexBuffer.PositionKind, positions);
    //console.log('update mesh')
}

Warp.snapBack = function(mesh) {
    let pos = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
    var numberOfVertices = pos.length/3;
    var result = Array(numberOfVertices);
    for(var i = 0; i<numberOfVertices; i++) {
        var startingPoint = mesh.startingPoints[i]
        pos[i*3] = startingPoint.x;
        pos[i*3+1] = startingPoint.y;
        pos[i*3+2] = startingPoint.z;
    }
    mesh.updateVerticesData(BABYLON.VertexBuffer.PositionKind, pos);
    // console.log('snapBack')
}

module.exports = Warp