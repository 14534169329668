var State = {}
State.hexEdgesOn = true;
State.moveEpsilon = 1.0;

State.games = []

State.textureCache = []
State.materialCache = []

State.yGround = 0.0
State.yFrame = 0.1
State.yGenerator = 0.2
State.yField = 0.3
State.yMoving = 3.0
State.yIncrement = 0.1
//State.yyy = State.yCovered;

window.State = State

module.exports = State;
