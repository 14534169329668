var Hex = require('./Hex.js')
var Random = require('./Random.js')
// Hex address array generator
var HexGroup = {}

// One way, there are other orientations
HexGroup.parallelogram = function(qRange, rRange, grid) {
  var grid = grid || []
  for (var q=qRange[0]; q < qRange[1]+1; q++) {
    for (var r=rRange[0]; r < rRange[1]+1; r++) {
      grid.push([q,r])
    }
  }
  return grid
}

HexGroup.parallelogramB = function(qRange, sRange, grid) {
  var grid = grid || []
  for (var q=qRange[0]; q < qRange[1]+1; q++) {
    for (var s=sRange[0]; s < sRange[1]+1; s++) {
      var r = -s - q
      grid.push([r, q])
    }
  }
  return grid
}

HexGroup.diamond = function(qRange, sRange, grid) {
  var grid = grid || []
  for (var q=qRange[0]; q < qRange[1]+1; q++) {
    for (var s=sRange[0]; s < sRange[1]+1; s++) {
      var r = -s - q
      grid.push([q, r])
    }
  }
  return grid
}

HexGroup.rectangle = function(qRange, rRange, grid) {
  var grid = grid || []
  for (var r = rRange[0]; r < rRange[1]+1; r++) {
    var rOffset = Math.floor(r/2); // or r>>1
    for (var q = qRange[0]-rOffset; q < qRange[1]+1 - rOffset; q++) {
        grid.push([q, r])
    }
  }
  return grid
}

HexGroup.rectangleToo = function(qRange, rRange, grid) {
  var grid = grid || []
  for (var r = rRange[0]; r < rRange[1]+1; r++) {
    var rOffset = Math.floor(r/2); // or r>>1
    for (var q = qRange[0]-rOffset; q < qRange[1]+(r%2===0?1:0) - rOffset; q++) {
        grid.push([q, r])
    }
  }
  return grid
}
    
// At radius r
HexGroup.ring = function(r, grid) {
  var canidates = HexGroup.parallelogram([-r,r],[-r,r])
  var grid = grid || []
  canidates.forEach(function(p) {
    var distance = Hex.cubeDistance([0,0],p)
    if (distance === r) {
      grid.push(p)
    }
  })
  return grid
}

// Of radius r
HexGroup.hex = function(r, grid) {
  var canidates = HexGroup.parallelogram([-r,r],[-r,r])
  var grid = grid || []
  canidates.forEach(function(p) {
    var distance = Hex.cubeDistance([0,0],p)
    if (distance <= r) {
      grid.push(p)
    }
  })
  return grid
}

HexGroup.single = function(q, r, grid) {
  var grid = grid || []
  grid.push([q, r])
  return grid
}

// q, r as tip
HexGroup.three = function(q, r, grid) {
  var grid = grid || []
  grid.push([q, r])
  grid.push([q+1, r-1])
  grid.push([q, r-1])
  return grid
}

HexGroup.four = function(q, r, grid) {
  var grid = grid || []
  grid.push([q, r])
  grid.push([q, r+1])
  grid.push([q+1, r])
  grid.push([q+1, r-1])
  return grid
}

HexGroup.triSix = function(q, r, grid) {
  var grid = grid || []-
  grid.push(Hex.add([q, r], [0, 0]))
  grid.push(Hex.add([q, r], [0, -1]))
  grid.push(Hex.add([q, r], [1, -1]))
  grid.push(Hex.add([q, r], [0, -2]))
  grid.push(Hex.add([q, r], [1, -2]))
  grid.push(Hex.add([q, r], [2, -2]))
  return grid
}

HexGroup.seven = function(q, r, grid) {
  var grid = grid || []
  var hex = HexGroup.hex(1)
  hex.forEach(function(t) {
    grid.push(Hex.add(t, [q,r]))
  })
  //HexGroup.hex(1, grid)
  return grid
}

// line starting at [0,0], going in direction, for count hexes
HexGroup.line = function(direction, count, grid) {
    var grid = grid || []
    var hex = [0,0]
    for (var i=0; i < count; i++) {
        grid.push(hex)
        hex = Hex.add(hex, Hex.directions[direction])
    }
    return grid
}

// line starting at last element of grid, going in direction, for count hexes
HexGroup.appendLine = function(direction, count, grid) {
    var grid = grid || [0, 0]
    var hex = grid[grid.length-1]
    for (var i=0; i < count; i++) {
        hex = Hex.add(hex, Hex.directions[direction])
        grid.push(hex)
    }
    return grid
}


HexGroup.move = function(group, hexShift) {
    var result = group.map(function(hex) {
        return Hex.add(hex, hexShift)
    })
    return result
}

// Bounding-box-like (bounding parallelogram)
HexGroup.hexBounds = function(group) {
    var minR = Number.MAX_SAFE_INTEGER
    var maxR = Number.MIN_SAFE_INTEGER
    var minQ = Number.MAX_SAFE_INTEGER
    var maxQ = Number.MIN_SAFE_INTEGER
    group.forEach(function(hex) {
        if (hex[0] > maxQ) { maxQ = hex[0] }
        if (hex[0] < minQ) { minQ = hex[0] }
        if (hex[1] > maxR) { maxR = hex[1] }
        if (hex[1] < minR) { minR = hex[1] }        
    })
    return [[minQ, minR], [maxQ, maxR]]
}

HexGroup.hexWithHoles = function(size, nHoles) {
    // First, create inner hex
    var innerHex = HexGroup.hex(size-2)
    // make holes in it
    var removeCount = Math.floor(innerHex.length*0.33)
    var withHoles = HexGroup.removeRandomN(innerHex, removeCount)
    // add two solid bands around it
    HexGroup.ring(size-1, withHoles)
    HexGroup.ring(size, withHoles)
    return withHoles
}

HexGroup.removeRandomN = function(group, n) {
    var shuffled = Random.shuffle(group)
    for (var i = 0; i < n; i++) {
        shuffled.pop()
    }
    return shuffled
}

HexGroup.intersection = function(groupA, groupB) {
    var result = []
    groupA.forEach(function(hexA) {
        groupB.forEach(function(hexB) {
            if (Hex.equal(hexA, hexB)) {
                result.push(hexA)
            }
        })
    })
    return result
}

HexGroup.triangle = function(size, grid) {
    var grid = grid || []
    var range = [-size, size]
    var parallelogramA = HexGroup.move(HexGroup.parallelogram(range, range),[-size,0])
    var parallelogramB = HexGroup.parallelogramB(range, range)
    var triangle = HexGroup.intersection(parallelogramA, parallelogramB)
    triangle.forEach(function(hex) {
        grid.push(Hex.add(hex,[Math.round(size/2),0]))
    })
    return grid
}

HexGroup.mirror = function(toMirror, grid) {
    var grid = grid || []
    toMirror.forEach(function(hex) {
        grid.push([-hex[0], -hex[1]])
    })
    return grid
}

HexGroup.hourglass = function(size, grid) {
    var grid = grid || []
    var triangle = HexGroup.move(HexGroup.triangle(size),[-Math.round(size/2),size])
    triangle.forEach(function(hex) {
        if (hex[0] !== 0 || hex[1] !== 0) { 
          grid.push(hex)
        }
    })
    HexGroup.mirror(triangle, grid)
    return grid
}

module.exports = HexGroup