const HexGroup = require('../HexGroup.js')
const Tile = require('../Tile.js')
const Color = require('../Color.js')
const Random = require('../Random.js')

const scheme = Color.schemes[9]
const backgroundColor = Color.colors[scheme[1]]

const knot = HexGroup.parallelogramB([2,5],[-6,2])
const strokes2 = [
    "c4","C5","b0","c5","c5","c4","cca4","c0","l2",
    "C2","llxa1","Clxa3","Clxb0","llxb1","llxa1","CCxa1","l1","CCxa5",
    "l1","llxb1","cCb2","b0","C2","c1","b0","Clxa5","Clxa4",
    "b0","C2","c1","b0","b0","b0","c3","cca1","c1",
]

const knotTiles = knot.map(function(hex, i) {
    const sideAndStrokes = Tile.sideAndStokesFromDef(strokes2[i] || "Clxb")
    return {
    name: "tile",
    type: "normal",
    hex: hex,
    locked: true,
    shader: 'track',
    side: sideAndStrokes.side,
    strokes: sideAndStrokes.strokes.map(function(s) { return {
        stroke: s,
        bandColor: 0, //bandColor,
        edgeColor: 0
    }}),
    backgroundColor: backgroundColor,
    hexEdgeColor: backgroundColor, //edgeColor,
    y: State.yField
}})

const ktMarblesColor = Color.colors[Random.generateIndex(12)+1]
const ktMarbles = [8, 18].map(function (i) {
    return {
        name: 'marble',
        type: 'marble',
        backgroundColor: ktMarblesColor,
        hex: knot[i],
        inMotion: 2.0,
        y: State.yField
    }
})

const noMarbles = [3, 5].map(function (i) {
    return {
        name: 'marble',
        type: 'marble',
        backgroundColor: Color.colors[Random.generateIndex(12)+1],
        hex: knot[i],
        inMotion: 2.0,
        y: State.yField
    }
})

const knotBanner = knotTiles.concat(ktMarbles).concat(noMarbles)

module.exports = knotBanner