var State = require('./State.js')
var Draw = require('./Draw.js')
var Shaders = require('./shaders/Shaders.js')
var Material = {}

Material.checkCache = function(p) {
    if (p.nocache) {
        return null
    }
    for (var i=0; i < State.materialCache.length; i++) {
        if (Draw.pEqual(p, State.materialCache[i].p)) {
            return State.materialCache[i]
        }
    }
    return null
}

Material.generateTextureMaterial = function(p) {
    var cacheHit = Material.checkCache(p)
    if (cacheHit !== null) {
        return cacheHit.material
    }
    var texture = Draw.generateHexTexture(p)
    var material = Draw.generateMaterial(p.name, texture)
    State.materialCache.push({
        p: p,
        material: material
    })
    return material
}

Material.generateKnownMaterials = function() {
    Shaders.init()
}

Material.redraw = function(tile) {
    if (tile.type === "gridOnOff") {
    } else if (tile.type == "labeled") {
        var material = Material.generateTextureMaterial({
              name: "tileMat",
              type: tile.type,
              strokes: tile.strokes,
              backgroundColor: tile.backgroundColor,
              hexEdgeColor: tile.hexEdgeColor,
              hex_outline: tile.hex_outline,
              textureSize: 64,
              nocache: true
        })
        tile.material = material
        Draw.addLabelToTexture(tile.material.diffuseTexture, tile.label)
        return;
    } else {
        var material = Material.generateTextureMaterial({
        //var texture = Draw.redrawHexTexture(tile.material.diffuseTexture, {
            name: tile.name,
            type: tile.type,
	        strokes: tile.strokes,
            backgroundColor: tile.backgroundColor,
            hexEdgeColor: tile.hexEdgeColor,
		    hex_outline: tile.hex_outline,
		    textureSize: 64
        })
        tile.material = material
        return;
        // TODO Fix for icons
    }
    if (tile.type === "icon") {
        //console.log("Draw.redraw", tile.type, tile.label, Draw[tile.label])
        var iconSVG = Draw[tile.label]
        //Draw.addLabelToTexture(texture, tile.label)
        Draw.addIconToTexture(texture, iconSVG, tile.hexEdgeColor, tile.hexEdgeColor)
        //Draw.addIconToTexture(texture, iconSVG, tile.bandColor, tile.edgeColor)
    }// else if (tile.label) {
    //    Draw.addLabelToTexture(texture, tile.label)
    //}
    tile.material.diffuseTexture = texture
    tile.material.specularTexture = texture
    tile.material.emissiveTexture = texture
    tile.material.ambientTexture = texture
}

module.exports = Material

