import * as localForage from 'localforage'
var Persist = {}

Persist.rawFetch = function(name, callback) {
    localForage.getItem(name, function(err, data) {
        // console.log("localForage.getItem err", err)
        // console.log("localForage.getItem data", data)
        if (data) {
           callback(data)
        } else {
            var store = localStorage.getItem(name)
            var decode = JSON.parse(store)
            callback(decode)
        }
    })
}

Persist.rawPersist = function(name, data) {
    // var store = localStorage.getItem(name)
    // if (store && store.length) {
    //     if  (store.length <  4000000) {
    //         // Failing quietly... not so good
    //         var encode = JSON.stringify(data)
    //         localStorage.setItem(name, encode)
    //     } else {
    //         // console.log(`size of ${name}: ${store.length}`)
    //         // let o = JSON.parse(store)
    //         // console.log(o)
    //     }
    // } else {
    //     var encode = JSON.stringify(data)
    //     localStorage.setItem(name, encode)
    // }
    localForage.setItem(name, data, function() {
        // localForage.getItem(name, function(err, data) {
        //     console.log("localForge.getItem err", err)
        //     console.log("localForge.getItem data", data)
        // })    
    })
}

module.exports = Persist