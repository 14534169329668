var State = require('./State.js')

var Shot = {}

State.Shot = Shot

Shot.thumbnailData = function(callback) {
    BABYLON.Tools.CreateScreenshot(State.scene.getEngine(), State.camera, { precision: 0.5 }, callback)
}

Shot.image = function(callback) {
    var id = State.game.name+State.game.version
    Shot.thumbnailData(function(data) {
        var img = new Image()
        img.src = data
        img.id = id
        callback(img)
    })
}

Shot.append = function(element) {
    Shot.image(function(img) {
        element.appendChild(img)
    })
}

Shot.updateTexture = function(dynamicTexture) {
    var textureContext = dynamicTexture.getContext()
    Shot.thumbnailData(function(d) {
        textureContext.putImageData(d, 0, 0, 512, 512)
    })
}

module.exports = Shot
