var Info = {}
Info.start = function() {
    var canvas = document.getElementById('babylonCanvas');
    var info = document.getElementById('info');
    canvas.hidden = true;
    info.hidden = false;
}
Info.stop = function() {
    var canvas = document.getElementById('babylonCanvas');
    var info = document.getElementById('info');
    canvas.hidden = false;
    info.hidden = true;
}

Info.message = function(status, err) {
    var message = document.getElementById('message')
    if (status) {
        message.innerText = 'Copied to clipboard'
    } else {
        message.innerText = 'Copy url to share'
    }
    message.className = "message"
    message.hidden = false;
    setTimeout(function() {
        message.hidden = true;
    }, 2000);
}

module.exports = Info
