var State = require('./State.js')
var Color = require('./Color.js')
var Action = require('./Action.js')
var Log = require('./Log.js')
var Hex = require('./Hex.js')
var Draw = require('./Draw.js')
var HexGroup = require('./HexGroup.js')
var Collision = require('./Collision.js')
var Generate = require('./Generate.js')
var Animate = require('./Animate.js')
var Store = require('./Store.js')
var HexShader = require('./shaders/HexShader.js')
var Replay = require('./Replay.js')
const UDesign = require('./UDesign.js')
const Share = require('./Share.js')

// Board driver / manager (sub-manager to Game/Manager)
// TODO Move dispose behavior to piece (get rid of conditional removes) OR put in array on creation

var Board = {}

State.tileDict = {}

// TODO add boardName logic - when there are multiple boards on the same screen
// appearance
Board.clear = function(scene) {
  var scene = State.scene;
  // TODO Make meshes that are part of a board be in the board group
  // TODO Thus clearing is removing everything on a list
  Log.gameChange({
      type: "Board.clear"
  })
  Collision.agents = []
  State.tileDict = {}
  State.tileId = 0
  for (var i=scene.meshes.length-1; i > -1; i--) {
    var mesh = scene.meshes[i]
    if (mesh.name === "tile") {
      mesh.dispose()
    } else if (mesh.name == "frame") {
      mesh.dispose()
    } else if (mesh.name == "marble") {
      mesh.disposing = true
      mesh.dispose()
    } else if (mesh.name == "color") {
      mesh.dispose()
    } else if (mesh.name === "removeMe") {
      mesh.dispose()
    } else if (mesh.name === "tile_merged") {
      mesh.dispose()
    } else if (mesh.name === "web") {
      mesh.dispose()
    } else if ( mesh.name !== "ground" &&
                mesh.name !== "choice" &&
                mesh.name !== "disc") {
      console.log("Unknown mesh name:", mesh.name)
    }
  }
}

// TODO Add boardName logic
// appearance, location
Board.redraw = function(scene, boardName) {
  var board = JSON.parse(localStorage.getItem("snapshot"))
  Board.clear(scene)
  setTimeout(function() { Board.drawPieces(board.pieces) }, 10)
}

State.Board = Board

Board.design = function(encoded) {
  Board.clear()
  if (!State.game) State.game = {}
  State.game.name = 'uDesign'
  State.game.cameraPositionY = UDesign.main.cameraPositionY
  State.game.rotation = UDesign.main.rotation
  State.ground.locked = !!UDesign.main.lockGround

  State.game.upRotated = false
  State.game.widthAdjustment = -Hex.hexWorldWidth*0.75

  const base = UDesign.main.pieces.slice()
  State.game.dimensions = Board.dimensions(base)
  Board.setCamera(window.innerWidth, window.innerHeight)

  const tiles = Share.decodeAndExtra(encoded, false, "uDesignNormalOn")
  Log.snapshotEnabled = false
  Generate.pieces(base)
  tiles.forEach((tile) => {
      var mesh = Generate.piece(tile)
      if (mesh.shader == "track") {
        const centerWidth = HexShader.centerWidth(mesh.type)
        HexShader.setHexEdgeWidth(mesh.material, centerWidth)
      }
  })
  Log.snapshotEnabled = true
}

// appearance, location
Board.start = function(game, history) {
    // TODO Move back button on camera change
    // TODO Move all these to board definitions
    if (game.name === "mainChoices") {
        State.game.cameraPositionY = 321
    } else if (game.name === "loopToo") {
        State.game.cameraPositionY = 400
    } else if (!game.cameraPositionY) {
        // Default
        State.game.cameraPositionY = 400
    } else {
        State.game.cameraPositionY = game.cameraPositionY
    }

    State.game.rotation = game.rotation || 0

    if (game.upRotated) {
      State.game.upRotated = game.upRotated
    } else if (game.name === "mainChoices") {
      State.game.upRotated = true
    } else {
      State.game.upRotated = false
    }

    if (game.name === "mainChoices") {
      State.game.widthAdjustment = 0
    } else if (game.name === "loopToo") {
      State.game.widthAdjustment = -Hex.hexWorldWidth*0.5
    } else if (game.name === "uDesign") {
      State.game.widthAdjustment = -Hex.hexWorldWidth*0.75
    } else {
      State.game.widthAdjustment = 0
    }

    if (history) {
        let [base, rest] = Replay.pieces(history)
        //console.log(base.length, rest.length)
        Log.snapshotEnabled = false
        Board.drawPieces(base)
        Log.snapshotEnabled = true
        Replay.playHistory(history) // TODO remove
        // Board.drawPieces(rest) // TODO places the rest
    } else {
        Board.newBoard(game)
    }
    State.Board.setCamera(window.innerWidth, window.innerHeight)
}

Board.newBoard = function(game) {
    Log.gameChange({
      type: "newBoard",
      board: game.name
    })
    // Lock the ground, or not.
    State.ground.locked = !!game.lockGround
    var pieces = game.pieces.slice()
    if (game.modify) {
        if (game.modify === "toRandomColorScheme") {
          Generate.toRandomColorScheme(pieces)
        } else if (game.modify) { // assume function
          game.modify(pieces)
        }
    }
    if (game.dynamicPieces) {
        var dynamic = game.dynamicPieces()
        pieces = pieces.concat(dynamic)
    }
    Log.snapshotEnabled = false
    Board.drawPieces(pieces)
    Log.snapshotEnabled = true
}

Board.dimensions = function(pieces) {
    var world = pieces.map(function(p) {
        var xyz = Hex.hexToWorld(p.hex)
        return xyz
    })
    var x_min = 0
    var x_max = 0
    var z_min = 0
    var z_max = 0
    world.forEach(function(w) {
        if (x_min > w.x) { x_min = w.x }
        if (x_max < w.x) { x_max = w.x }
        if (z_min > w.z) { z_min = w.z }
        if (z_max < w.z) { z_max = w.z }
    })
    var halfWidth = x_max
    var halfHeight = z_max
    if (-x_min > x_max) {
        halfWidth = -x_min
    }
    if (-z_min > z_max) {
        halfHeight = -z_min
    }
    return {
        width: halfWidth*2,
        height: halfHeight*2 
    }
}

Board.setCamera = function(viewWidth, viewHeight) {
    var boardWidth = State.game.dimensions.width
    var boardHeight = State.game.dimensions.height
    let boardRatio = (boardWidth+State.game.widthAdjustment)/boardHeight

    let heightDominate = viewWidth < viewHeight
    let ratioBoard = true //State.game.name == 'uDesign' || State.game.name == 'loopToo'

    if ((heightDominate && !State.game.upRotated) ||
        (!heightDominate && State.game.upRotated)) {
      State.camera.rotation.y = 0 + State.game.rotation
      let viewRatio = viewWidth / viewHeight
      let diff = boardRatio - viewRatio
      let adjustment = (diff > 0  && ratioBoard)
          ? (diff * 64)**2 : 0
      let position = State.game.cameraPositionY + adjustment
      State.camera.position = new BABYLON.Vector3(0, position, 1)
      // TODO Set return tile rotation
      State.camera.fovMode = BABYLON.Camera.FOVMODE_VERTICAL_FIXED
    } else {
      State.camera.rotation.y = Math.PI/2 + State.game.rotation
      let viewRatio = viewHeight / viewWidth
      let diff = boardRatio - viewRatio
      let adjustment = (diff > 0  && ratioBoard)
          ? (diff * 64)**2 : 0
      let position = State.game.cameraPositionY + adjustment
      State.camera.position = new BABYLON.Vector3(0, position, 1)
      State.camera.fovMode = BABYLON.Camera.FOVMODE_HORIZONTAL_FIXED
    }
}

// appearance, location, behavior
Board.drawPieces = function(pieces) {
    State.game.dimensions = Board.dimensions(pieces)
    Board.setCamera(document.body.clientWidth, document.body.clientHeight)
    Generate.pieces(pieces)
    
    HexShader.setGeneratorColors()
}

// location
Board.boundaries = function(scene, boardName) {
  var meshes = scene.meshes
  // TODO Use actual max and min numbers for JavaScript numbers
  var minX = 1000000000
  var maxX = -1000000000
  var minZ = 1000000000
  var maxZ = -1000000000
  meshes.forEach(function(mesh) {
    var positionX = mesh.position.x
    var positionZ = mesh.position.z
    if (minX > positionX) { minX = positionX }
    if (minZ > positionZ) { minZ = positionZ }
    if (maxX < positionX) { maxX = positionX }
    if (maxZ < positionZ) { maxZ = positionZ }
  })
  return {
      minX: minX,
      maxX: maxX,
      minZ: minZ,
      maxZ: maxZ
  }
}

// // Keeper (eyes):
//       var strokes = [{
//           stroke: [0,1],
//           bandColor: 2,
//           edgeColor: 0
//       }]

//       var strokesA = [{
//           stroke: [0,1],
//           bandColor: 2,
//           edgeColor: 0
//       },{
//           stroke: [2,3],
//           bandColor: 2,
//           edgeColor: 0
//       },{
//           stroke: [4,5],
//           bandColor: 2,
//           edgeColor: 0
//       }]
//       var strokesB = [{
//           stroke: [1,2],
//           bandColor: 2,
//           edgeColor: 0
//       },{
//           stroke: [3,4],
//           bandColor: 2,
//           edgeColor: 0
//       },{
//           stroke: [5,0],
//           bandColor: 2,
//           edgeColor: 0
//       }]

// location
Board.meshesAtHex = function(hex) {
  var meshes = []
  for (var i=0; i<State.scene.meshes.length; i++) {
    var mesh = State.scene.meshes[i]
    if (mesh.name === "removeMe" && (Hex.equal(mesh.hex,hex))) {
      meshes.push(mesh)
    }
  }
  Path.sortByY(meshes)
  return meshes
}

// location
Board.topMesh = function(hex) {
  var meshes = Board.meshesAtHex(hex)
  if (meshes.length === 0 ) {
    return null
  } else {
    return meshes[0]
  }
}

// behavior
Board.rotateOnePiece = function() {
    var hexGroup = HexGroup.rectangleToo([-4,4],[-8,8])
    var randomHex = hexGroup[Random.generateIndex(hexGroup.length)]
    var topMesh = Board.topMesh(randomHex)
    if (topMesh && topMesh.name === "removeMe") {
        topMesh.rotate(BABYLON.Axis.Y, Math.PI/3, BABYLON.Space.WORLD)
    }
}

// appearance, location
Board.aboveOnePiece = function() {
    if (Board.colorPrototypes.length === 0) {
        return;
    }
    var hexGroup = HexGroup.rectangleToo([-4,4],[-8,8])
    var randomHex = hexGroup[Random.generateIndex(hexGroup.length)]
    var randomColor = Board.colorPrototypes[Random.generateIndex(Board.colorPrototypes.length)].clone()
    randomColor.name = "removeMe"
    var position = Hex.hexToWorld(randomHex)
    position.y = 1.0
    randomColor.hex = randomHex
    randomColor.position = position
}

// appearance, location, behavior
Board.celebrateWithColor = function(boardDef, nextThing) {
    console.log("celebrateWithColor", boardDef)
    Board.celebrateBoard = State.games[boardDef.name]
    Animate.beforeRenderFunctions.push(Board.aboveOnePiece)
    setTimeout(function() {
        Animate.beforeRenderFunctions.pop()
        Animate.beforeRenderFunctions.push(Board.rotateOnePiece)
        setTimeout(function() {
           Animate.beforeRenderFunctions.pop()
           nextThing(boardDef)
        }, 10000)
   }, 10000)
}


// appearance, location, behavior
Board.celebrate = function(boardDef, nextThing) {
    console.log("Board.celebrate", boardDef, nextThing)
    var scene = State.scene;
    if (boardDef.celebrate) {
        if (boardDef.celebrate === "allSpin") {
            Animate.beforeRenderFunctions.push(Animate.allSpin)
            setTimeout(function() {
                Animate.beforeRenderFunctions.pop()
                setTimeout(function() {
                   nextThing(boardDef)
                }, 100)
            }, 8000)
        } else if (boardDef.celebrate === "allSpinSlower") {
            Animate.beforeRenderFunctions.push(Animate.allSpinSlower)
            setTimeout(function() {
                Animate.beforeRenderFunctions.pop()
                setTimeout(function() {
                   nextThing(boardDef)
                }, 100)
            }, 8000)
        } else if (boardDef.celebrate === "allSpinTrio") {
            Animate.beforeRenderFunctions.push(Animate.allSpinTrio)
            setTimeout(function() {
                Animate.beforeRenderFunctions.pop()
                setTimeout(function() {
                   nextThing(boardDef)
                }, 100)
            }, 8000)
        } else if (boardDef.celebrate === "withColor") {
            Board.celebrateWithColor(boardDef, nextThing)
        } else if (boardDef.celebrate === "allBackAndForth") {
            Animate.tick = 0
            Animate.beforeRenderFunctions.push(Animate.allBackAndForth)
            setTimeout(function() {
                Animate.beforeRenderFunctions.pop()
                setTimeout(function() {
                   nextThing(boardDef)
                }, 100)
            }, 8000)
        } else if (boardDef.celebrate === "fastMarbles") {
            for (var i=0; i< scene.meshes.length; i++) {
                if (scene.meshes[i].name === "marble") {
                    console.log("marble found", i)
                    scene.meshes[i].fps = 400
                }
            }
            setTimeout(function() {
               nextThing(boardDef)
            }, 8000)
        } else {
          console.log("Board.celebrate: Yay!")
          nextThing(boardDef)
        }
    } else {
        console.log("Board.celebrate: Not so much.")
        nextThing(boardDef)
    }
}

module.exports = Board
