var Clipboard = {}

function iosCopyToClipboard(el) {
  var oldContentEditable = el.contentEditable,
      oldReadOnly = el.readOnly,
      range = document.createRange();

  el.contentEditable = true;
  el.readOnly = false;
  range.selectNodeContents(el);

  var s = window.getSelection();
  s.removeAllRanges();
  s.addRange(range);

  el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

  el.contentEditable = oldContentEditable;
  el.readOnly = oldReadOnly;

  document.execCommand('copy');
}

function fallbackCopyTextToClipboard(text, callback) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      callback(successful)
    } catch (err) {
      callback(false, err)
    }  
    document.body.removeChild(textArea);
}

function copyTextToClipboard(text, callback) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text, callback);
    } else {
      navigator.clipboard.writeText(text).then(function() {
        callback(true)
      }, function(err) {
        callback(false, err)
      });
    }
}
  
//   var copyBobBtn = document.querySelector('.js-copy-bob-btn'),
//     copyJaneBtn = document.querySelector('.js-copy-jane-btn');
  
//   copyBobBtn.addEventListener('click', function(event) {
//     copyTextToClipboard('Bob');
//   });
  
  
//   copyJaneBtn.addEventListener('click', function(event) {
//     copyTextToClipboard('Jane');
//   });

export { copyTextToClipboard }
