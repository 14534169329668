var State = require('./State.js')

// Simple collision manager
// TODO Replace with production grade
var Collision = {}

Collision.agents = []

Collision.check = function() {
  var agents = Collision.agents || []
  var collided = []
  for (var i=0; i<agents.length; i++) {
    for (var j=(i+1); j<agents.length; j++) {
	  if (agents[i].position.equalsWithEpsilon(agents[j].position, 2)) {
	    console.log("Within 2")
		collided.push([agents[i], agents[j]])
	  }
	}
  }
  collided.forEach(function(b) {
    if ((b[0].on && b[0].on.collide) || (b[1].on && b[1].on.collide)) {
      if (b[0].on && b[0].on.collide) {
        b[0].on.collide(b[0])
      }
      if (b[1].on && b[1].on.collide) {
        b[1].on.collide(b[1])
      }
    } else {
      console.log("Collision.check: No action defined for either agent in Collision set.")
    }
  })
}
module.exports = Collision