// Random library
// TODO Use reproducible (seed-oriented) random instead
var Random = {
  generateIndex: function(length) {
    return Math.floor(Math.random() * Math.floor(length))
  },

  generateInRange: function(min, max) {
    return (Math.random() * (max - min)) + min
  },

  side: function() {
    return Random.generateIndex(6)
  },

  shuffle: function(array) {
    var shuffle = array
    var counter = shuffle.length;

    // From the end to the beginning, do a random swap
    // This is the Fisher-Yates (+Durstenfeld / Knuth) Algorithm
    while (counter > 0) {
      var index = Math.floor(Math.random() * counter);

      counter--;
    
      var temp = shuffle[counter];
      shuffle[counter] = shuffle[index];
      shuffle[index] = temp;
    }
    return shuffle
  },

  generateIndexFromDistribution: function(array) {
    // array is relative counts for the distribution
    var sum = array.reduce(function(a, b) { return a + b }, 0)
    var target = sum*Math.random() // number from 0 to sum
    for (var index = 0; index < array.length; index++) {
        target -= array[index]
        if (target < 0) {
            return index
        }
    }
    // Array must be empty or contain negative numbers
    Log.debug("Random.generateIndexFromDistribution fail",array)
    return null
  },

  generateNameFromDistribution: function(obj) {
    // obj has relative counts for the distribution as values
    var sum = Object.values(obj).reduce(function(a, b) { return a + b }, 0)
    var target = sum*Math.random() // number from 0 to sum
    var keys = Object.keys(obj)
    for (var i = 0; i < keys.length; i++) {
        target -= obj[keys[i]]
        if (target < 0) {
            return keys[i]
        }
    }
    Log.debug("Random.generateNameFromDistribution fail",obj)
    return null
  },

  generateIndex: function(length) {
    return Math.floor(Math.random() * Math.floor(length))
  },

  generateInRange: function(min, max) {
    return (Math.random() * (max - min)) + min
  },

  side: function() {
    return Random.generateIndex(6)
  },

  shuffle: function(array) {
    var shuffle = array
    var counter = shuffle.length;

    // From the end to the beginning, do a random swap
    // This is the Fisher-Yates (+Durstenfeld / Knuth) Algorithm
    while (counter > 0) {
      var index = Math.floor(Math.random() * counter);

      counter--;
    
      var temp = shuffle[counter];
      shuffle[counter] = shuffle[index];
      shuffle[index] = temp;
    }
    return shuffle
  },

  generateIndexFromDistribution: function(array) {
    // array is relative counts for the distribution
    var sum = array.reduce(function(a, b) { return a + b }, 0)
    var target = sum*Math.random() // number from 0 to sum
    for (var index = 0; index < array.length; index++) {
        target -= array[index]
        if (target < 0) {
            return index
        }
    }
    // Array must be empty or contain negative numbers
    Log.debug("Random.generateIndexFromDistribution fail",array)
    return null
  },

  generateNameFromDistribution: function(obj) {
    // obj has relative counts for the distribution as values
    var sum = Object.values(obj).reduce(function(a, b) { return a + b }, 0)
    var target = sum*Math.random() // number from 0 to sum
    var keys = Object.keys(obj)
    for (var i = 0; i < keys.length; i++) {
        target -= obj[keys[i]]
        if (target < 0) {
            return keys[i]
        }
    }
    Log.debug("Random.generateNameFromDistribution fail",obj)
    return null
  }
}

module.exports = Random