var State = require('./State.js')
var Action = require('./Action.js')
var Move = require('./Move.js')
var Log = require('./Log.js')
//var Game = require('./Game.js')

// Input Events
var Input = {}

// Assume State.scene
// Assume State.canvas is set.
Input.originPointerPosition = null
Input.lastPointerPosition = null
Input.pickedMesh = null
Input.originHex = null

Input.isGround = function(mesh) {
     return mesh === State.ground
}
// TODO Move to Pick.js
Input.getGroundPosition = function (evt) {
    // Use a predicate to get position on the ground and not anywhere else
    var pickinfo;
    if (false) { //('ontouchstart' in window ) {
        var touches = evt.changedTouches
        pickinfo = State.scene.pick(touches[0].pageX, touches[0].pageY, Input.isGround)
    } else {
        pickinfo = State.scene.pick(State.scene.pointerX, State.scene.pointerY, Input.isGround)
    }
    if (pickinfo.hit) {
        return pickinfo.pickedPoint
    }
    console.log(State.scene.pointerX, State.scene.pointerY, pickinfo)
    console.log("Warning: no ground hit in getGroundPosition")
    return null
}

// Move to Pick.js
Input.report = {}
Input.report.all = false
Input.report.onPointerDown = false

Input.reportAllHere = function() {
    var scene = State.scene
    var multiPick = scene.multiPick(scene.pointerX, scene.pointerY, null)
    multiPick.forEach(function(pickInfo) {
      console.log("all:",pickInfo.pickedMesh.name, pickInfo.pickedMesh.position)
    })

    var pick = scene.pick(scene.pointerX, scene.pointerY, null)
}

// TODO Move to On as behavior, named "touchStart"
// TODO Removed "locked" in favor of simply no "touchStart" selected
// For things that can be tapped and dragged
// behavior
Input.tapAndDragStart = function(mesh) {
    if (!mesh.locked) {
        mesh.position.y = State.yMoving
        if (mesh.hex) {
          mesh.originHex = [mesh.hex[0], mesh.hex[1]]
        }
        setTimeout(function () {
            State.camera.detachControl(State.canvas);
        }, 0);
    }
}

// location, behavior
// TODO Move to On behavior
Input.drag = function(mesh, diff) {
      mesh.position.addInPlace(diff)
}

// location, behavior
Input.tapAndDragEnd = function(mesh) {
    var originPointerPosition = Input.originPointerPosition
    var pointerPosition = Input.lastPointerPosition
    var moveDistance = BABYLON.Vector3.Distance(pointerPosition, originPointerPosition)
    mesh.position.y = State.yField
    if (mesh.locked) {
      console.log("mesh locked, no tap")
    } else {
      if (mesh.on.tap) {
        // TODO if (mesh.name === color), onSnap, change color and move back.
        // TODO on noSnap, just move back
        // Short move is considered tap
        if (moveDistance < State.maxMoveForTap) {
          mesh.on.tap(mesh)
          Log.gameChange({
            type: "tapped",
            meshId: mesh.id
          })
        }
      }
      // Still not locked
      // All moves are candidates for snap to grid
      // Moves and Snaps are logged in snapToGrid
      var snapPosition = Move.snapToGrid(mesh)
      if (snapPosition) {
        if (mesh.on.snap) {
          mesh.on.snap(mesh)
        } else if (mesh.on.noSnap) {
          mesh.on.noSnap(mesh)
        }
      }
    }
}

// behavior
// TODO Move all conditionals to meshes
Input.onPointerDownOrFirstMove = function(evt) {
    var scene = State.scene;
    // Log the pointer down.
    // TODO Support multi-touch
    Input.positions = []
    Input.originPointerPosition = Input.getGroundPosition(evt)
    if (Input.originPointerPosition === null) {
        return;
    }
    Input.lastPointerPosition = Input.originPointerPosition.clone()
    Input.positions.push(Input.originPointerPosition)
    if (evt.button !== 0) {
        return;
    }
    Input.downTimeStamp = evt.timeStamp
    // Move tiles, not grid, plane
    var pickInfo = scene.pick(scene.pointerX, scene.pointerY, function(mesh) {
        return (mesh.name !== "frame" && mesh.name !== "plane")
    })
    if (!pickInfo.hit || !pickInfo.pickedMesh) {
        return;
    }
    var mesh = pickInfo.pickedMesh;

    if (mesh.locked) {
        if (mesh.on && mesh.on.pointerDown) {
            mesh.on.pointerDown(mesh)
        } else {
          // Do nothing if the locked tile is on top.
          console.log("locked",mesh.name,mesh.type)
        }
      return;
    }
    Input.pickedMesh = mesh

    if (mesh.on && mesh.on.pointerDown) {
        mesh.on.pointerDown(mesh)
    } else {
        if (mesh !== State.ground) {
            mesh.position.y += 0.01 // Higher than 100 increments
            if (mesh.name === "tile") {
                Input.originHex = [mesh.hex[0], mesh.hex[1]]
            }
        } else { // ground
          console.log("pointerDown ground unlocked")
        }
        //Log.DOMChange("Detach camera from canvas")
        setTimeout(function () {
            State.camera.detachControl(State.canvas);
        }, 0);
    }
}

Input.onPointerDown = function (evt) {
    Log.aBrowserEvent(evt)
    if (Input.report.all || Input.report.onPointerDown) {
        Input.reportAllHere()
    }
    Input.onPointerDownOrFirstMove(evt)
}

// location, behavior
Input.onPointerUp = function (evt) {
    Log.aBrowserEvent(evt)
    if (Input.pickedMesh) {
      if (Input.pickedMesh.on && Input.pickedMesh.on.pointerUp) {
          // TODO Make all
          if (typeof Input.pickedMesh.on.pointerUp === "string") {
            Action.on[Input.pickedMesh.on.pointerUp](Input.pickedMesh)
          } else {
            Input.pickedMesh.on.pointerUp(Input.pickedMesh)
          }
          //console.log("Picked on pointer up: "+Input.pickedMesh.position.y)
          // if (Input.pickedMesh.position.y > 0.01) {
          //   Input.pickedMesh.position.y -= State.yyy
          // }
      } else {
        console.log(Input.pickedMesh.name, Input.pickedMesh.on)
        var originPointerPosition = Input.originPointerPosition
        var pointerPosition = Input.lastPointerPosition
        var moveDistance = BABYLON.Vector3.Distance(pointerPosition, originPointerPosition)
        var mesh = Input.pickedMesh
        if (mesh.locked) {
          console.log("mesh locked, no tap")
        }
        if (mesh !== State.ground) {
          mesh.position.y = State.yField
        }
        // TODO if (mesh.name === color), onSnap, change color and move back.
        // TODO on noSnap, just move back
        if (mesh.name === "tile" || mesh.name === "color" && !mesh.locked) {
			// Short move is considered tap
			if (moveDistance < State.maxMoveForTap) {
			    Action.tapTile(mesh)
            }
            // All moves are candidates for snap to grid
            var snapPosition = Move.snapToGrid(mesh)
            if (snapPosition) { Input.positions.push(snapPosition) }
            //if (snapPosition) {
              // TODO: switch all Game.on* to mesh characteristics
            //    if (Game.onSnap) {
            //      Game.onSnap(mesh)
            //    }
            //} else {
            //    if (Game.noSnap) {
            //      Game.noSnap(mesh)
            //    }
            //}
            Log.snapshot(State.scene)
        }
        else if (mesh.name === "marble") {
            Action.colorMarbleDrop(mesh)
        }
      }
    }
    if (Input.positions && Input.positions.length > 0) {
        // TODO: Log positions
    }
    Input.positions = []
    Input.originPointerPosition = null
    Input.lastPointerPosition = null
    Input.pickedMesh = null
    State.camera.attachControl(State.canvas, true)
}

// Drag
// location, behavior
Input.onPointerMove = function (evt) {
    Log.aBrowserEvent(evt)
    if (!Input.originPointerPosition) {
        Input.onPointerDownOrFirstMove(evt)
        return;
    }
    if (!Input.pickedMesh || Input.pickedMesh.locked) {
        return;
    }

    var pointerPosition = Input.getGroundPosition(evt);
    if (!pointerPosition) {
            return;
    }
    //Log.intersection("onPointerMove pointerPosition", pointerPosition)

    // TODO? Make this call new function Action.partialDrag(mesh, lastPostion, position)?

    // TODO implement Drag boundaries. Start with Board.boundaries
    // TODO: make non-specified console.log message, migrate all
    if (Input.pickedMesh.on && Input.pickedMesh.on.drag) {
        var diff = pointerPosition.subtract(Input.lastPointerPosition)
        diff.y = 0
        Input.pickedMesh.on.drag(Input.pickedMesh, diff)
    } else {
        var diff = pointerPosition.subtract(Input.lastPointerPosition)
        diff.y = 0
        console.log('default drag')
        Input.pickedMesh.position.addInPlace(diff)
    }

    //if (Input.pickedMesh.position.y < y) {
    //  console.log("tile.y y:",Input.pickedMesh.position.y, y)
    //}
    Input.lastPointerPosition = pointerPosition
    Input.positions.push(pointerPosition.clone())
}
module.exports = Input;
