var State = require('./State.js')
var Action = require('./Action.js')
var Move = require('./Move.js')
var Generate = require('./Generate.js')
var HexShader = require('./shaders/HexShader.js')
var Replay = {}

Replay.pieces = function(history) {
    if (history[0] && history[0].command == "boardLoad") {
        let base = history[0].data.map(function(p) {
          if (typeof p.y == 'undefined') {
            p.y = State.yGenerator
          }
          return p
        })
        const [, ...rest] = history;
        // TODO Replay rest on base to generate pieces to render 
        return [base, rest]
    } else {
        return [[], history]
    }
}

Replay.playHistory = function(commands) {
    //console.log("history", commands)
    let base = commands[0] ? commands[0].data : []
    let table = base.reduce((result, item) => {
        result[item.id] = item 
        return result
    }, {})
    
    for (var i = 0; i < commands.length; i++) {
        var fixPair = Replay.replay(commands[i], table)
        if (fixPair) {
            for (var j = i+1; j < commands.length; j++) {
                var ent = commands[j]
                if (ent.command === "moveclone" && ent.data.pair[0] === fixPair[0]) {
                    ent.data.pair[0] = fixPair[1]
                } else if (ent.command === "move" && ent.data.id === fixPair[0]) {
                    ent.data.id = fixPair[1]
                } else if (ent.command === "tap" && ent.data.id === fixPair[0]) {
                    ent.data.id = fixPair[1]
                } else if (ent.command === "color background" && ent.data.id === fixPair[0]) {
                    ent.data.id = fixPair[1]
                } else if (ent.command === "color strokes" && ent.data.id === fixPair[0]) {
                    ent.data.id = fixPair[1]
                }
            }
        }
    }
    //commands.forEach((command) => Board.replay(name, command))
}

function compare(a, b) {
    var diff = []
    if (a.hex[0] !== b.hex[0]) {
        diff.push([a.hex, b.hex])
    }
    return diff
}

function lookup(meshId, table) {
    //let a = table[meshId]
    let b = State.tileDict[meshId]
    //let diff = compare(a, b)
    //console.log(diff)
    return b
}
    
// Dispatch table. Translate record back to actions/animations.
var replayCommandTable = {
    boardLoad: function(data) {
        //console.log(data)
    },
    tap: function(data) {
      var mesh = lookup(data.id)
      if (mesh) {
        Action.tapTile(mesh)
      } else {
        console.log(data.id + " not found")
      }
    },
    move: function(data) {
      var mesh = lookup(data.id)
      if (mesh) {
        Move.toHex(mesh, data.to)
      } else {
          console.log(data.id, "not found")
      }
    },
    moveclone: function(data) {
      var mesh = lookup(data.pair[0])
      if (!mesh) {
          console.log("could not find", data.pair[0])
          console.log(data)
      } else {
        Move.toHex(mesh, data.to) // TODO: Delete if generator
        var newMesh = Generate.regenerateAndClear(mesh)
        if (newMesh === null) {
            console.log("moveclone didn't generate new tile (minor corruption of history)")
        } else if (newMesh.id !== data.pair[1]) {
            console.log("rewrite?", newMesh.id, data.pair[1])
            //return [data.pair[1], newMesh.id]
        }
        mesh.type = 'normal'
        const centerWidth = HexShader.centerWidth(mesh.type)
        HexShader.setHexEdgeWidth(mesh.material, centerWidth)
      }
    },
    create: function(data) {
        var mesh = Genereate.piece(data)
        const centerWidth = HexShader.centerWidth(mesh.type)
        HexShader.setHexEdgeWidth(mesh.material, centerWidth)
    },
    "color background": function(data) {
      var mesh = lookup(data.id)
      if (mesh !== undefined) {
          mesh.backgroundColor = data.newcolor
          HexShader.setBackgroundColor(mesh.material, mesh.backgroundColor)
      } else {
          console.log('color background could not find', data.id)
      }
    },
    "color strokes": function(data) {
      var mesh = lookup(data.id)
      if (mesh !== undefined) {
          mesh.strokes = data.newStrokes
          HexShader.setStrokes(mesh.material, mesh)
      } else {
          console.log('color strokes could not find', data.id)
      }
    }
}

Replay.replay = function(change) {
  var command = change.command
  var data = change.data
  if (!replayCommandTable[command]) {
      //var mesh = lookup(data.meshId)
      console.log("Unimplemented Action", command)
  } else {
      replayCommandTable[command](data)
  }
}

module.exports = Replay