var HexGroup = require('./HexGroup.js')
// Create a tile group from a hex group, start of meter code
// TODO finish meter code

var TileGroup = {}

// Add .meter value to each member of group
// value between 0 and 1
TileGroup.meter = function(group, domainValue) {
    var rangeValue = d3.scaleLinear()
      .domain([0,1])
      .range([0,group.length])(domainValue)
    var result = group.map(function(obj, i) {
      var diff = rangeValue-i
      obj.meter = (diff>1.0?1.0:(diff<0.0?0.0:diff))  // [0.0,...,0.0,0.?,1.0,...,1.0]
      return obj
    })
    return result
}

// Just turn into object
TileGroup.fromHexGroup = function(hexGroup) {
    return hexGroup.map(function(hex) { return {
        name: "tile",
        type: "normal",
        hex: hex
    } })
}

TileGroup.standardPhoneHexGroup = HexGroup.rectangleToo([-4,4],[-8,8])

TileGroup.standardPhoneHeaderHexGroup = HexGroup.rectangleToo([-4,4],[-8,-7])
TileGroup.standardPhoneCenterHexGroup = HexGroup.rectangleToo([-4,4],[-6,6])
TileGroup.standardPhoneFooterHexGroup = HexGroup.rectangleToo([-4,4],[7,8])

TileGroup.standardPhoneHeaderMeterHexGroup = HexGroup.rectangleToo([-4,4],[-7,-7])
TileGroup.standardPhoneFooterMeterHexGroup = HexGroup.rectangleToo([-4,4],[7,7])

module.exports = TileGroup;