var Define = require('./Define.js')
// Data Module for UDesign Board
var UDesign = {}

UDesign.board = [
     ["empty","Clxa","l1","Clxb","cCa","Bay of Many marble","cCb","cca4","C","CC2","empty"],
    ["BlackWhite","share","empty","empty","empty","empty","empty","empty","c2","topMenu"],
//    ["BlackWhite","empty","empty","empty","empty","empty","empty","empty","c2","topMenu"],
     ["empty","empty","empty","empty","empty","empty","empty","empty","empty","empty","empty"],
    ["Turbo","empty","empty","empty","empty","empty","empty","empty","empty","Green Haze"],
     ["empty","empty","empty","empty","empty","empty","empty","empty","empty","empty","empty"],
    ["Endeavour","empty","empty","empty","empty","empty","empty","empty","empty","Silver Chalice"],
     ["empty","empty","empty","empty","empty","empty","empty","empty","empty","empty","empty"],
    ["White","empty","empty","empty","empty","empty","empty","empty","empty","Black"],
     ["empty","empty","empty","empty","empty","empty","empty","empty","empty","empty","empty"],
    ["Mulberry","empty","empty","empty","empty","empty","empty","empty","empty","Razzmatazz"],
     ["empty","empty","empty","empty","empty","empty","empty","empty","empty","empty","empty"],
    ["Bay of Many","empty","empty","empty","empty","empty","empty","empty","empty","Tango"],
     ["empty","empty","empty","empty","empty","empty","empty","empty","empty","empty","empty"],
    ["Supernova","empty","empty","empty","empty","empty","empty","empty","empty","French Pass"],
     ["empty","empty","empty","empty","empty","empty","empty","empty","empty","empty","empty"],
    ["Cotton Candy","cl5","empty","empty","empty","empty","empty","empty","cCCxa","Pacific Blue"],
     ["empty","llxa5","llxb5","clc5","ccc","Web","ccb2","cCCxb","CCxa","CCxb","empty"]
]

// appearance, location, behavior
UDesign.main = {
    name: "uDesign",
    lockGround: true,
    rotation: 0,
    cameraPositionY: 498,
    pieces: function() {
        let elements = Define.ElementsFromArray(UDesign.board)
        let pieces = elements.map(Define.BoardTile).flat()
        return pieces
    }(), // Notice: generated once
}

module.exports = UDesign;
