var State = require('./State.js')
var Color = require('./Color.js')
var Action = require('./Action.js')
var Input = require('./Input.js')
var Tile = require('./Tile.js')
var HexGroup = require('./HexGroup.js')
var Random = require('./Random.js')
var Hex = require('./Hex.js')
var Define = require('./Define.js')
// Mazes
// TODO Move generative functions to Generate
// TODO (What is left here are simply the Maze board definitions)

var Maze = {}

Maze.games = {}

Maze.tileOn = {
    snap: function(mesh) { console.log("snap") },
    noSnap: function(mesh) { console.log("noSnap") },
    tap: Action.tapTile,
    pointerDown: Input.tapAndDragStart,
    pointerUp: Input.tapAndDragEnd,
    drag: function() {}
}

Maze.marbleOn = {
    drag: function() {}
}

Maze.curveStrokes = Tile.curvesOnly.map(function(i) {
    return Tile.arcs[i]
})

Maze.generateStartTile = function(hex, colorScheme, side) {
    var side = side || 1
   var tileStrokes = Tile.addColorToStrokes([[side,side]], Color.colors[colorScheme[0]], Color.colors[colorScheme[2]])
   return {
                name: "tile",
                type: "start",
                hex: hex,
                strokes: tileStrokes,
                backgroundColor: 2,
                y: State.yField,
                locked: true,
                on: Maze.tileOn
    }
}

Maze.generateMarble = function(hex) {
    return {
                name: "marble",
                type: "marble",
                backgroundColor: Color.colors[Random.generateIndex(16)],
                hex: hex,
                y: State.yField,
                on: Maze.marbleOn
    }
}

Maze.generateAgentHereFinishTile = function(hex, colorScheme, side) {
   var side = side || 4
   var tileStrokes = Tile.addColorToStrokes([[side,side]], Color.colors[colorScheme[0]], Color.colors[colorScheme[2]])
   return {
                name: "tile",
                type: "agentHereFinish",
                hex: hex,
                strokes: tileStrokes,
                backgroundColor: 2,
                y: State.yField,
                on: Maze.tileOn
    }
}

Maze.generateTile = function(hex, strokes, colorScheme) {
    var tileStrokes = Tile.addColorToStrokes(strokes, Color.colors[colorScheme[0]], Color.colors[colorScheme[2]])
    return {
                name: "tile",
                type: "normal",
                hex: hex,
                strokes: tileStrokes,
                backgroundColor: 2,
                y: State.yField,
                on: Maze.tileOn
    }
}

Maze.games.line1x3 = {
  modify: "toRandomColorScheme",
  lockground: true,
  pieces: function() {
    var tiles = []
    var colors = Color.colors
    var colorScheme = Color.schemes[4]

    var strokes = [[1,4]]
    tiles.push(Maze.generateTile([0,0], strokes, colorScheme))
    
    tiles.push(Maze.generateStartTile([-1,0], colorScheme))
    tiles.push(Maze.generateMarble([-1,0]))
    tiles.push(Maze.generateAgentHereFinishTile([1,0], colorScheme))
    tiles.push(Define.TopMenu([1,5]))
    return tiles
  }()
}
        
Maze.games.hex4 = {
  modify: "toRandomColorScheme",
  lockground: true,
  pieces: function() {
    var tiles = []
    var colors = Color.colors
    var colorScheme = Color.schemes[4]
    var inGrid = HexGroup.hex(4)
    inGrid.forEach(function(hex) {
        //var strokes = Tile.arcs[
        //  Tile.curvesOnly[Random.generateIndex(Tile.curvesOnly.length)]
        //]
        var strokes = Maze.curveStrokes[Random.generateIndex(Maze.curveStrokes.length)]
        tiles.push(Maze.generateTile(hex, strokes, colorScheme))
    })
    
    tiles.push(Maze.generateStartTile([-5,3], colorScheme))
    tiles.push(Maze.generateMarble([-5,3]))
    tiles.push(Maze.generateAgentHereFinishTile([5,-4], colorScheme))
    tiles.push(Define.TopMenu([1,5]))

    return tiles
  }()
}

Maze.games.rectangle7x8 = {
  modify: "toRandomColorScheme",
  lockground: true,
  pieces: function() {
    var tiles = []
    var colors = Color.colors
    var colorScheme = Color.schemes[4]
    var inGrid = HexGroup.rectangle([-3,3],[-3,4])
    inGrid.forEach(function(hex) {
        var strokes = Tile.arcs[
          Tile.curvesOnly[Random.generateIndex(Tile.curvesOnly.length)]
        ]
        tiles.push(Maze.generateTile(hex, strokes, colorScheme))
    })
    tiles.push(Maze.generateStartTile([-5,3], colorScheme))
    tiles.push(Maze.generateMarble([-5,3]))
    tiles.push(Maze.generateAgentHereFinishTile([5,-2], colorScheme))
    tiles.push(Define.TopMenu([1,5]))
    return tiles
  }()
}

Maze.games.rectangle3x4 = {
  modify: "toRandomColorScheme",
  lockground: true,
  pieces: function() {
    var tiles = []
    var colors = Color.colors
    var colorScheme = Color.schemes[4]
    var inGrid = HexGroup.rectangle([-1,1],[-1,2])
    inGrid.forEach(function(hex) {
        var strokes = Tile.arcs[
          Tile.curvesOnly[Random.generateIndex(Tile.curvesOnly.length)]
        ]
        tiles.push(Maze.generateTile(hex, strokes, colorScheme))
    })
    tiles.push(Maze.generateStartTile([-2,1], colorScheme))
    tiles.push(Maze.generateMarble([-2,1]))
    tiles.push(Maze.generateAgentHereFinishTile([2,0], colorScheme))
    tiles.push(Define.TopMenu([1,5]))
    
    return tiles
  }()
}

Maze.games.rectangleToo7x13 = {
  //modify: "toRandomColorScheme",
  lockground: true,
  pieces: function() {
    var tiles = []
    var colors = Color.colors
    var colorScheme = Color.schemes[4]
    var inGrid = HexGroup.rectangleToo([-3,3],[-6,6])
    inGrid.forEach(function(hex) {
        var strokes = Tile.arcs[
          Tile.curvesOnly[Random.generateIndex(Tile.curvesOnly.length)]
        ]
        var tile = Maze.generateTile(hex, strokes, colorScheme)
        if (Hex.equal(hex, [-6,6])) {
            tile = Maze.generateStartTile([-6,6], colorScheme)
        } else if (Hex.equal(hex, [6,-6])) {
            tile = Maze.generateAgentHereFinishTile([6,-6], colorScheme)
        }
        tiles.push(tile)
    })
    tiles.push(Maze.generateMarble([-6,6]))
    tiles.push(Define.TopMenu([1,5]))
    return tiles
  }()
}

/*
Maze.games.rectangleToo9x17 = {
  //modify: "toRandomColorScheme",
  lockground: true,
  pieces: function() {
    var tiles = []
    var colors = Color.colors
    var colorScheme = Color.schemes[4]
    var inGrid = HexGroup.rectangleToo([-3,3],[-7,7])
    inGrid.forEach(function(hex) {
        var strokes = Tile.arcs[
          Tile.curvesOnly[Random.generateIndex(Tile.curvesOnly.length)]
        ]
        var tile = Maze.generateTile(hex, strokes, colorScheme)
        if (Hex.equal(hex, [-7,7])) {
            tile = Maze.generateStartTile([-7,7], colorScheme)
        } else if (Hex.equal(hex, [7,-7])) {
            tile = Maze.generateAgentHereFinishTile([7,-7], colorScheme)
        }
        tiles.push(tile)
    })
    tiles.push(Maze.generateMarble([-7,7]))
    tiles.push(Define.TopMenu([1,5]))
    return tiles
  }()
}
*/

Maze.games.hexWithHoles = {
  modify: "toRandomColorScheme",
  lockground: true,
  pieces: function() {
    var tiles = []
    var colors = Color.colors
    var colorScheme = Color.schemes[4]
    var inGrid = HexGroup.hexWithHoles(4)
    inGrid.forEach(function(hex) {
        //var strokes = Tile.arcs[
        //  Tile.curvesOnly[Random.generateIndex(Tile.curvesOnly.length)]
        //]
        var strokes = Maze.curveStrokes[Random.generateIndex(Maze.curveStrokes.length)]
        tiles.push(Maze.generateTile(hex, strokes, colorScheme))
    })
    
    tiles.push(Maze.generateStartTile([-5,3], colorScheme))
    tiles.push(Maze.generateMarble([-5,3]))
    tiles.push(Maze.generateAgentHereFinishTile([5,-4], colorScheme))
    tiles.push(Define.TopMenu([1,5]))
    
    return tiles
  }()
}

Maze.games.diamond = {
  lockground: true,
  pieces: function() {
    var tiles = []
    var colors = Color.colors
    var colorScheme = Color.schemes[4]
    var inGrid = HexGroup.diamond([-3,3],[-3,3])
    inGrid.forEach(function(hex) {
        var strokes = Tile.arcs[
          Tile.curvesOnly[Random.generateIndex(Tile.curvesOnly.length)]
        ]
        var tile = Maze.generateTile(hex, strokes, colorScheme)
        if (Hex.equal(hex, [-3,6])) {
            tile = Maze.generateStartTile([-3,6], colorScheme, 2)
        } else if (Hex.equal(hex, [3,-6])) {
            tile = Maze.generateAgentHereFinishTile([3,-6], colorScheme, 5)
        }
        tiles.push(tile)
    })
    tiles.push(Maze.generateMarble([-3,6]))
    tiles.push(Define.TopMenu([1,5]))
    return tiles
  }()
}

Maze.games.hourglass = {
  lockground: true,
  pieces: function() {
    var tiles = []
    var colors = Color.colors
    var colorScheme = Color.schemes[4]
    var inGrid = HexGroup.hourglass(3)
    inGrid.forEach(function(hex) {
        var strokes = Tile.arcs[
          Tile.curvesOnly[Random.generateIndex(Tile.curvesOnly.length)]
        ]
        var tile = Maze.generateTile(hex, strokes, colorScheme)
        if (Hex.equal(hex, [0,6])) {
            tile = Maze.generateStartTile([0,6], colorScheme, 4)
        } else if (Hex.equal(hex, [0,-6])) {
            tile = Maze.generateAgentHereFinishTile([0,-6], colorScheme, 1)
        }
        tiles.push(tile)
    })
    tiles.push(Maze.generateMarble([0,6]))
    tiles.push(Define.TopMenu([1,5]))
    return tiles
  }()
}
module.exports = Maze;