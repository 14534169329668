var Action = require('./Action.js')
var Input = require('./Input.js')

var Billiards = {}
// behavior
Billiards.billiardsOn =  {
    snap: function(mesh) { console.log("snap") },
    noSnap: function(mesh) { console.log("noSnap") },
    tap: Action.tapTile,
    pointerDown: Input.tapAndDragStart,
    pointerUp: Input.tapAndDragEnd,
    drag: function() {}
}

// behavior
Billiards.billiardsCueOn =  {
    collide: function() {},
    //tap: function() {},
    //pointerDown: function() {},
    //pointerUp: function() {},
    //drag: function() {}
}

// behavior
Billiards.billiardsBallOn = {
    collide: Action.removeAndZoom,
    //tap: function() {},
    //pointerDown: function() {},
    //pointerUp: function() {},
    //drag: function() {}
}


module.exports = Billiards
