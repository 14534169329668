var Color = {}

Color.colors = [
"#000000",
"#aaaaaa",
"#ffffff",
"#02b153",
"#fff100",
"#0166b4",
"#eb115d",
"#00b5be",
"#2f3293",
"#c856a3",
"#ceeefd",
"#f47b22",
"#FEBAD4",
"#fbc30c",
"#c9c9c9", // "extra" gray
"#333333", // "extra-extra" gray - frame
"#f0f0f0"  // nearly white - frame
]

Color.Color3 = Color.colors.map((hex) => BABYLON.Color3.FromHexString(hex));
Color.Vector3 = Color.Color3.map((c3) => new BABYLON.Vector3(c3.r,c3.g,c3.b));

Color.schemes = [
[ 2, 1, 0, 0], // sampler tile color
[ 1, 2, 0], // start side colors
[ 2, 1, 0],
[ 2, 0, 1],
[ 2, 2, 0],
[10, 2, 5],
[ 4, 2, 5],
[ 4, 5, 6],
[ 6,11,10], // end side colors
[ 5,11, 4], // start top colors
[ 6, 8, 4],
[ 9, 5, 8],
[13, 6,10],
[ 4,12, 5],
[12,10, 9],
[ 8, 9,10],
[13, 4, 8],
[10, 7, 8],
[ 8, 3, 4],
[ 3,13, 5]  // end top colors
]

Color.named = {}

Color.named.colorStrings = ["Black", "Silver Chalice", "White", "Green Haze", "Turbo", "Endeavour", "Razzmatazz", "Pacific Blue", "Bay of Many", "Mulberry", "French Pass", "Tango", "Cotton Candy", "Supernova"]

Color.named.colors = {}
Color.named.colorStrings.forEach(function(string, i) {
    Color.named.colors[string] = Color.colors[i]
})

Color.hexDir = {}
Color.colors.forEach(function(hexString,i) {
    Color.hexDir[hexString] = i
})

module.exports = Color