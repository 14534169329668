var State = require('./State.js')
var Hex = require('./Hex.js')
var Tile = require('./Tile.js')

// Determine arc-paths from tiles
// TODO Keep a graph representation of all the arcs at time of movement
// TODO Use *that* graph to determine paths
// TODO Use THREE.js point/path routines

var Path = {}

Path.meshesAtHex = function(hex) {
  var meshes = []
  for (var i=0; i<State.scene.meshes.length; i++) {
    var mesh = State.scene.meshes[i]
    if ((mesh.name === "tile" || mesh.name == "web") && (Hex.equal(mesh.hex,hex))) {
      meshes.push(mesh)
    }
  }
  Path.sortByY(meshes)
  return meshes
}

Path.hexTopMeshCache = {}

Path.meshHexCache = {}

Path.toHexLookupKey = function(hex) {
    return "q"+hex[0]+"r"+hex[1]
}

Path.topMeshCandidate = function(mesh) {
    var y = mesh.y
    var hex = mesh.hex
    var key = Path.toHexLookupKey(hex)
    var oldPlace = Path.meshHexCache[mesh.id]
    if (!Path.hexTopMeshCache[key] || Path.hexTopMeshCache[key].y <= mesh.y) {
        Path.hexTopMeshCache[key] = mesh
        Path.meshHexCache[mesh.id] = key
        if (oldPlace && oldPlace !== key) {
            Path.hexTopMeshCache[oldPlace] = undefined
        }
    }
}
    
Path.topMesh = function(hex) {
    //var lookupKey = Path.toHexLookupKey(hex)
    //var result = Path.hexTopMeshCache[lookupKey]
    //return result
    
    var result = null
    //if (!Path.hexTopMeshCache[lookupKey]) {
        var meshes = Path.meshesAtHex(hex)
        if (meshes.length > 0 ) {
            result = meshes[0]
        }
    //    Path.hexTopMeshCache[lookupKey] = result
    //} else {
    //    result = Path.hexTopMeshCache[lookupKey]
    //}
    return result
    
}

Path.neighborHexTiles = function(hex) {
  var neighbor = []
  for (var i=0; i<scene.meshes.length; i++) {
    var mesh = scene.meshes[i]
    if (mesh.name === "tile" && (Hex.cubeDistance(mesh.hex,hex) === 1)) {
      neighbor.push(mesh)
    }
  }
  return neighbor
}

// Descending
Path.sortByY = function(meshes) {
  meshes.sort(function (a, b) {
    return b.position.y - a.position.y
  })
}

Path.uniqueByFirst = function(meshes) {
    var seen = {};
    var unique = [];
    var len = meshes.length;
    var j = 0;
    for(var i = 0; i < len; i++) {
        var hex = meshes[i].hex
        var compare = hex[0]+","+hex[i]
        if(seen[compare] !== 1) {
            seen[compare] = 1
            unique[j++] = meshes[i]
        }
    }
    return unique;
}

Path.neighborTopTiles = function(mesh) {
  var hex = mesh.hex
  if (!Hex.isWhole(hex)) {
    return []
  }
  var canidates = Path.neighborHexTiles(hex)
  Path.sortByY(canidates) // Descending
  var neighbors = Path.uniqueByFirst(canidates)
  return neighbors
}

Path.meshToArcs = function(mesh) {
  return Tile.normalizedArcs(mesh.strokes, mesh.side)
}

Path.paths = function(mesh, meshFromHex) {
  meshFromHex = meshFromHex || Path.topMesh
  var arcs = Path.meshToArcs(mesh)
  var paths = []
  for (var i=0; i < arcs.length; i++) {
    var path = Path.arcToPath(arcs[i], mesh, meshFromHex)
    paths.push(path) // May simply be just this mesh
  }
  return paths
}

// { hex: hex, arc: arc } => { hex: hex', side: side }
Path.arcToOtherAddress = function(p) {
  // Ignore existence, simply translate
  var side = p.arc[1]
  var otherSide = (side + 3) % 6
  if (!Number.isInteger(side)) {
    console.log("Side not integer:", p)
  }
  var otherHex = Hex.add(p.hex, Hex.directions[side])
  return { hex: otherHex, side: otherSide };
}

// { hex: hex, side: side} if arc ==> { hex: hex, arc: [side, otherSideOfArc] } else null
Path.pointToSubpath = function(p, meshFromHex) {
  var topMesh = meshFromHex(p.hex)
  if (!topMesh) { return null }
  var strokes = Path.meshToArcs(topMesh)
  for (var i=0; i< strokes.length; i++) {
    var stroke = strokes[i]
    if (stroke[0] === p.side) {
      return {
        hex: p.hex,
        arc: [p.side, stroke[1]],
        mesh: topMesh
      };
    } else if (stroke[1] === p.side) {
      return {
        hex: p.hex,
        arc: [p.side, stroke[0]],
        mesh: topMesh
      };
    }
  }
  return null;
}

Path.next = function(hexArc) {
  var p = Path.arcToOtherAddress(hexArc)
  var subpath = Path.pointToSubpath(p, Path.topMesh)
  return subpath
}

Path.arcEqual = function(a, b) {
  return a !== null && Hex.equal(a.hex, b.hex) && (a.arc[0] === b.arc[0]);
}

// Path is a [[hex, arc],...]
// points are [hex, side] === [[q,r], side]
// arc is expected to be translated by meshToArcs
Path.arcToPath = function(arc, mesh, meshFromHex) {
  var hex = mesh.hex
  var start = {
    hex: hex,
    arc: arc,
    mesh: mesh
  }
  var path = [start]
  // Reads second value of arc as other address
  var p = Path.arcToOtherAddress(start)
  var subpath = Path.pointToSubpath(p, meshFromHex)
  // subpath is now the next tile's arc (that matches)
  while (subpath !== null && !Path.arcEqual(subpath, start)) {
      path.push(subpath)
      // Address of the next tile's point (that matches)
      p = Path.arcToOtherAddress(subpath)
      subpath = Path.pointToSubpath(p, meshFromHex)
  }
  // We completed the loop to the start
  if (Path.arcEqual(subpath, start)) {
    return { loop: true, path: path };
  }
  // There might some length of path the other way
  // There should be no loop
  var reverse = {
    hex: start.hex,
    arc: [start.arc[1], start.arc[0]],
  }
  p = Path.arcToOtherAddress(reverse)
  subpath = Path.pointToSubpath(p, meshFromHex)
  while (subpath !== null) {
      path.unshift(subpath)
      // Address of the next tile's point (that matches)
      p = Path.arcToOtherAddress(subpath)
      subpath = Path.pointToSubpath(p, meshFromHex)
  }
  return { loop: false, path: path };
}

Path.arcLookup = {
    "01": [0,1],
    "10": [0,1],
    "02": [0,2],
    "20": [0,2],
    "03": [0,3],
    "30": [0,3],
    "04": [4,0],
    "40": [4,0],
    "05": [5,0],
    "50": [5,0],
    "12": [1,2],
    "21": [1,2],
    "13": [1,3],
    "31": [1,3],
    "14": [1,4],
    "41": [1,4],
    "15": [5,1],
    "51": [5,1],
    "23": [2,3],
    "32": [2,3],
    "24": [2,4],
    "42": [2,4],
    "25": [2,5],
    "52": [2,5],
    "34": [3,4],
    "43": [3,4],
    "35": [3,5],
    "53": [3,5],
    "45": [4,5],
    "54": [4,5],
    "00": [0,0],
    "11": [1,1],
    "22": [2,2],
    "33": [3,3],
    "44": [4,4],
    "55": [5,5]
}

Path.sidesToArc = function(sideA, sideB) {
    var arc = Path.arcLookup[""+sideA+sideB+""]
    return arc
}

Path.testPathHexToMesh = function(hex) {
  var key = hex[0]+","+hex[1]
  var mesh = Path.testMeshes[key]
  if (!mesh) {
    return null
  } else {
    return mesh
  }
}

Path.closestArcFromTile = function(tile, offsetFromCenter) {
  var arcs = Path.meshToArcs(tile)
  var distances = Hex.pointToNeighborDistances(offsetFromCenter)
  var sorted = distances.sort(function(a, b) { return a.distance - b.distance })
  for (var i=0; i < sorted.length; i++) {
    for (var j=0; j < arcs.length; j++) {
      if (sorted[i].side === arcs[j][0]) {
        return arcs[j]
      } else if (sorted[i].side === arcs[j][1]) {
        return [arcs[j][1], arcs[j][0]]
      }
    }
  }
  return [0,0] // No stroke found - do something weird. :)
}


// Move to Animate.makeEvenCurve?
Path.makeEvenCurve = function(path) {
  var positions = path.path.map(function(p) {
    return p.mesh.position
  })
  if (path.loop) {
    // Loop back to first position
    positions.push(path.path[0].mesh.position)
    positions.push(path.path[1].mesh.position)
  }
  var curvedPositions = []
  var last
  for (var i=1; i < positions.length-1; i++) {
     var origin = BABYLON.Vector3.Center(positions[i-1],positions[i])
	 var destination = BABYLON.Vector3.Center(positions[i], positions[i+1])
	 var bezier2 = BABYLON.Curve3.CreateQuadraticBezier(origin, positions[i], destination, 4)
	 var path = bezier2.getPoints()
	 path.forEach(function(p) { curvedPositions.push(p) })
	 last = curvedPositions.pop() // remove the last, it will be added back on next segment
  }
  curvedPositions.push(last)
  return curvedPositions
}

Path.positionAtSide = function(side, radius) {
  return new BABYLON.Vector3(
    Math.sin(Math.PI/3 * (side) + Math.PI/6) * radius,
    1,
    Math.cos(Math.PI/3 * (side) + Math.PI/6) * radius
  )
}

Path.centerToEdgeLength = 14

Path.curve = function(hexArc) {
  var center = Hex.hexToWorld(hexArc.hex,  Hex.worldHexRatio)
  var origin = Path.positionAtSide(hexArc.arc[0], Path.centerToEdgeLength).add(center)
  var destination = Path.positionAtSide(hexArc.arc[1], Path.centerToEdgeLength).add(center)
  var path = BABYLON.Curve3.CreateQuadraticBezier(origin, center, destination, 4)
  return path.getPoints()
}

Path.halfCurve = function(hexArc) {
  var center = Hex.hexToWorld(hexArc.hex,  Hex.worldHexRatio)
  var origin = Path.positionAtSide(hexArc.arc[0], Path.centerToEdgeLength).add(center)
  var path = BABYLON.Curve3.CreateQuadraticBezier(origin, center, center, 4)
  return path.getPoints()
}


/*
Path.ribbon = function(hexArc) {
  var center = Hex.hexToWorld(hexArc.hex,  Hex.worldHexRatio)
  var origin = Path.positionAtSide(hexArc.arc[0], Path.centerToEdgeLength-5).add(center)
  var destination = Path.positionAtSide(hexArc.arc[1], Path.centerToEdgeLength-5).add(center)
  var path = BABYLON.Curve3.CreateQuadraticBezier(origin, center, destination, 4)
  origin = Path.positionAtSide(hexArc.arc[0], Path.centerToEdgeLength+5).add(center)
  destination = Path.positionAtSide(hexArc.arc[1], Path.centerToEdgeLength+5).add(center)
  var path2 = BABYLON.Curve3.CreateQuadraticBezier(origin, center, destination, 4)
  var result = []
  var p1 = path.getPoints()
  var p2 = path2.getPoints()
  p1.forEach(function(p, i) {
    result.push(p)
    result.push(p2[i])
  })
  return result
}
*/

/*
Path.spiral = function(hex) {
  var initialRadius = 1
  var finalRadius = Path.centerToEdgeLength
  var numberOfTurns = 6
  var initalAngle = 0
  var spiralGrowthRate = (finalRadius - initialRadius)/(2*Math.PI*numberOfTurns)
  var finalAngle = (finalRadius - initialRadius)/spiralGrowthRate
  
  var xFun = function(s) {
    return (initialRadius + s*spiralGrowthRate)*Math.cos(s)
  }
  var zFun = function(s) {
    return (initialRadius + s*spiralGrowthRate)*Math.sin(s)
  }
  
  var center = Hex.hexToWorld(hex,  Hex.worldHexRatio)
  var numberOfPointsPerTurn = 5
  var finalS = Math.PI*2*numberOfTurns
  var increment = finalS/(numberOfTurns*numberOfPointsPerTurn)
  var minIncrement = increment/5
  
  var points = []
  var s = 0
  while (s <= finalS) {
    var point = { x: xFun(s) + center.x, y: 0.0, z: zFun(s) + center.y }
    points.push(point)
    s += increment
    if (increment / minIncrement) {
      increment *= 0.98  // 0.96 is death
    }
  } 
  return points
}
*/

/*
//
//  Testing ==================================================
//

// Side 0, 1, 2, 3, 4, 5
//Hex.directions = [
//    [0, 1], [1, 0], [1, -1],
//    [0, -1], [-1, 0], [-1, 1]
//]

Path.test = function() {
  var mesh = { hex: [0,0], strokes: [[0,3]], side: 0 }
  Path.testMeshes = {
    "0,0" : mesh
  }
  var result = Path.arcToPath([0,3], mesh, Path.testPathHexToMesh)
  if (result.path.length !== 1) { console.log("expected length 1, got:", result) }
  Path.testMeshes["0,1"] = {
    hex: [0, 1],
    strokes: [[0,3]],
    side: 0
  }
  mesh = Path.testMeshes["0,0"]
  var result = Path.arcToPath([0,3], mesh, Path.testPathHexToMesh)
  if (result.path.length !== 2) { console.log("expected length 2, got:", result) }
  mesh = Path.testMeshes["0,1"]
  var result = Path.arcToPath([0,3], mesh, Path.testPathHexToMesh)
  if (result.path.length !== 2) { console.log("expected length 2, got:", result) }

  Path.testMeshes["0,0"].side = 1
  mesh = Path.testMeshes["0,0"]
  var result = Path.arcToPath([1,4], mesh, Path.testPathHexToMesh)
  if (result.path.length !== 1) { console.log("expected length 1, got:", result) }
  //console.log(Path.meshToArcs(mesh))
  //console.log(Path.arcToOtherAddress({ hex: [0,0], arc: [1, 4]}))
  //console.log(Path.arcToOtherAddress({ hex: [0,0], arc: [4, 1]}))
  mesh = Path.testMeshes["0,1"]
  var result = Path.arcToPath([0,3], mesh, Path.testPathHexToMesh)
  if (result.path.length !== 1) { console.log("expected length 1, got:", result) }
  
  Path.testMeshes["0,0"] = {
    hex: [0, 0],
    strokes: [[0,1]],
    side: 0
  }
  Path.testMeshes["0,1"] = {
    hex: [0, 1],
    strokes: [[0,1]],
    side: 0
  }
  Path.testMeshes["1,0"] = {
    hex: [1, 0],
    strokes: [[0,1]],
    side: 0
  }
  mesh = Path.testMeshes["0,0"]
  var result = Path.arcToPath([0,1], mesh, Path.testPathHexToMesh)
  if (result.path.length !== 1) { console.log("expected length 1, got:", result) }
  mesh = Path.testMeshes["0,1"]
  var result = Path.arcToPath([0,1], mesh, Path.testPathHexToMesh)
  if (result.path.length !== 1) { console.log("expected length 1, got:", result) }
  mesh = Path.testMeshes["1,0"]
  var result = Path.arcToPath([0,1], mesh, Path.testPathHexToMesh)
  if (result.path.length !== 1) { console.log("expected length 1, got:", result) }
  Path.testMeshes["0,0"].side = 0
  Path.testMeshes["0,1"].side = 2
  Path.testMeshes["1,0"].side = 3
  mesh = Path.testMeshes["0,0"]
  var result = Path.arcToPath([0,1], mesh, Path.testPathHexToMesh)
  if (result.path.length !== 3) { console.log("expected length 3, got:", result) }
  mesh = Path.testMeshes["0,1"]
  var result = Path.arcToPath([2,3], mesh, Path.testPathHexToMesh)
  if (result.path.length !== 3) { console.log("expected length 3, got:", result) }
  mesh = Path.testMeshes["1,0"]
  var result = Path.arcToPath([3,4], mesh, Path.testPathHexToMesh)
  if (result.path.length !== 3 || result.loop) { console.log("expected length 3 and no loop, got:", result) }
  Path.testMeshes["1,0"].side = 4
  var result = Path.arcToPath([4,5], mesh, Path.testPathHexToMesh)
  if (result.path.length !== 3 || !result.loop) { console.log("expected length 3 and loop, got:", result) }
}
Path.test()
*/

module.exports = Path