import { copyTextToClipboard } from './Clipboard.js';
import Info from './Info.js';
import { slot } from './Url.js'

var State = require('./State.js')
var Color = require('./Color.js')
var Share = require('./Share.js')
var Input = require('./Input.js')
var Tile = require('./Tile.js')
var Log = require('./Log.js')
var Draw = require('./Draw.js')
var Hex = require('./Hex.js')
var Path = require('./Path.js')
var Action = require('./Action.js')
var Collision = require('./Collision.js') // Used once
var Random = require('./Random.js')
var Store = require('./Store.js')
var Icons = require('./Icons.js')
var Warp = require('./Warp.js')
var Material = require('./Material.js')
var Shaders = require('./shaders/Shaders.js')
var HexShader = require('./shaders/HexShader.js')

var Generate = {}

var On = {}

// (anti) behavior
On.inert = {
    tap: function(){},
    pointerDown: function(){},
    pointerUp: function(){},
    drag: function(){}
}

function reportY() {
    let ys = {}
    for (var i=0; i<State.scene.meshes.length; i++) {
        var mesh = State.scene.meshes[i]
        if (mesh.position && mesh.position.y) {
            ys[mesh.position.y] = (ys[mesh.position.y] || 0) + 1;
        }
    }
}

function reportUseLogarithmicDepth() {
    let ys = {}
    for (var i=0; i<State.scene.meshes.length; i++) {
        var mesh = State.scene.meshes[i]
        if (mesh.material) {
            ys[mesh.material.useLogarithmicDepth] = (ys[mesh.material.useLogarithmicDepth] || 0) + 1;
        }
        if (!mesh.material.useLogarithmicDepth) {
        }
    }
}

On.blackWhite = {
    tap: function(){},
    pointerUp: function(){},
    pointerDown: function(){
        State.backgroundWhich++
        State.hexEdgesOn = State.backgroundWhich%4 < 2
        let d = State.backgroundColors[State.backgroundWhich%4]
        HexShader.setBackgroundAndHexEdgeColors(State.backgroundMaterial, d)
        HexShader.setAllHexEdges()
        HexShader.setGeneratorColors()
        reportY()
        reportUseLogarithmicDepth()
    },
    drag: function(){}
}

On.share = {
    tap: function(){},
    pointerUp: function(){},
    pointerDown: function(){
        const normal = Share.encodeAllNormal()
        let base = window.location.href.replace(window.location.hash, '')
        let newHash = 'share/'+normal
        let shareUrl = base+'#/'+newHash
        copyTextToClipboard(shareUrl, Info.message)
        State.router.navigate(newHash)
    },
    drag: function(){}
}

On.gridToggle = {
    pointerUp: function(){},
    drag: function(){},
    pointerDown: function() {
        State.hexEdgesOn = !State.hexEdgesOn
        setAllHexEdges()
    }
}

On.marbleSwipe =  {
    collide: function(mesh) {
        //console.log("collide")
    },
    tap: function(mesh) {
        mesh.position = Hex.hexToWorld([1,2])
    },
    distancesCenter: function(mesh) {
        var positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);
        var numberOfVertices = positions.length/3;
        var list = [];
        for(var i = 0; i<numberOfVertices; i++) {
            //var distanceStart = BABYLON.Vector3.Distance(mesh.position, position)
            list.push(new BABYLON.Vector3(positions[i*3], positions[i*3+1], positions[i*3+2]))
        }
        return list;
    },
    pointerDown: function(mesh) {
        mesh.animations = null
        State.scene.stopAnimation(mesh)
        mesh.totalDiff = new BABYLON.Vector3(0,0,0)
        mesh.traveled = 0.0
        if (mesh.startPush && (Date.now() - mesh.startPush > 500)) {
            console.log("pointerDown", Date.now() - mesh.startPush)
            mesh.pull = true;
            Warp.pullStart(mesh)
        } else {
            // console.log("pointerDown")
            mesh.startPush = Date.now()
            mesh.distances = this.distancesCenter(mesh)
            mesh.pull = false;
        }
        mesh.runningTotals = []
        if (mesh.animationHandle) {
            Animate.halt(mesh, mesh.animationHandle)
        }
        mesh.fps = -1
    },
    ooo: function(mesh) {
        // var ms = Date.now() - mesh.startPush
        // var xTotal = 0
        // var zTotal = 0
        // mesh.runningTotals.forEach(function(diff) {
        //     xTotal += diff.x
        //     zTotal += diff.z
        // })
        // mesh.runningTotals = []
        // var xDelta = xTotal/ms*1000/60
        // var zDelta = zTotal/ms*1000/60
        // Don't roll on placement
        // this.roll(mesh, xDelta, zDelta)
        this.roll(mesh, 0.0, 0.0)
    },
    findHexArcPlace: function(mesh, xDelta, zDelta) {
        // mesh - marble
        // xDelta - push offset
        // zDelta - push offset
        // TODO Rework this. Draw little circles or such to find close.
        // TODO Or zoom in, whatever it takes.
        var hex = Hex.nearestHexCenter(mesh.position)
        var topMesh = Path.topMesh(hex)
        if (!topMesh || !topMesh.name || (topMesh.name !== "web" && topMesh.name !== "tile")) {
            return {}
        } else {
            // This ignores the position of the marble, assumes vector from center of hex
            var allowedSides = Hex.allowedSides(xDelta, zDelta)
            var normalizedArcs = Tile.normalizedArcs(topMesh.strokes, topMesh.side)
            var found = allowedSides.reduce(function(found, side) {
                if (!found) {
                    found = normalizedArcs.reduce(function(found, arc) {
                        if (!found) {
                          if (arc[0] === side) {
                            found = [arc[1], arc[0]]
                          } else if (arc[1] === side) {
                            found = [arc[0], arc[1]]
                          }
                        }
                        return found
                    }, undefined)
                }
                return found
            }, undefined)
            //console.log(normalizedArcs, allowedSides, found)
            return {
                hex: hex,
                arc: found,
                mesh: topMesh
            }
        }
    },
    roll: function(mesh, xDelta, zDelta) {
        var hexArc = this.findHexArcPlace(mesh, xDelta, zDelta)
        if (hexArc.arc) {
            var positions = Path.curve(hexArc)
            mesh.positon = positions[0]
            if (xDelta == 0.0 && zDelta == 0.0) return;
            var speed = Math.sqrt(xDelta*xDelta + zDelta*zDelta)
            // The slowest non-zero speed I could do was 0.3
            // The fastest: 10.6.
            mesh.fps = 30 * speed
            Action.firstArc(mesh, hexArc)
            //mesh.animationHandle = Animate.move(mesh, xDelta, zDelta)
        }
    },
    pointerUp: function(mesh) {
        delete mesh.startPush
        var hex = Hex.nearestHexCenter(mesh.position)
        var topMesh = Path.topMesh(hex)
        if (topMesh && topMesh.type == "color") {
            let color = topMesh.backgroundColor
            mesh.material = State.marbleColorMaterials[color]
            mesh.color = color
        }
        mesh.traveled = 0.0
        if (mesh.pull) {
            mesh.pull = false
            Warp.snapBack(mesh)
            this.roll(mesh, -mesh.totalDiff.x/10.0, -mesh.totalDiff.z/10.0)
        } else {
            if (!mesh.def.noRegenerate) {
                let distance = Hex.cubeDistance(hex, mesh.def.hex)
                if (distance >= 1) {
                    mesh.def.noRegenerate = true
                    var newMesh = Generate.piece(mesh.def)
                    newMesh.def.noRegenerate = false    
                }
            }
            this.roll(mesh, 0.0, 0.0)
        }
    },
    drag: function(mesh, diff) {
        var now = Date.now()
        mesh.totalDiff.x += diff.x
        mesh.totalDiff.z += diff.z
        mesh.traveled += Math.abs(diff.x) + Math.abs(diff.z)
        if (mesh.pull) {
            Warp.pull(mesh, diff)
        } else {
            if ((now - mesh.startPush) > 700 && mesh.traveled < 20.0) {
                mesh.pull = true
                mesh.totalDiff = new BABYLON.Vector3(0,0,0)
                // console.log("drag")
                Warp.pullStart(mesh)
            } else {
                mesh.position.x += diff.x
                mesh.position.z += diff.z
            }
        }
    }
}

Generate.pieces = function(pieces) {    
    var frameMeshes = []
    pieces.forEach(function(piece) {
        var mesh = Generate.piece(piece)
        if (piece.type === "frame") {
            frameMeshes.push(mesh)
        }
        piece.id = mesh.id
    })
    if (frameMeshes.length > 0) {
        State.backgroundMaterial = frameMeshes[1].material
        State.backgroundMaterial.freeze()
        frameMeshes.forEach(function(mesh){
            mesh.position.y = State.yFrame
            mesh.material = State.backgroundMaterial
            mesh.freezeWorldMatrix()
        })
    }
}

Generate.marble = function(piece) {
    var w = Hex.hexToWorld(piece.hex,  Hex.worldHexRatio)
    let mesh = Draw.makeMarble(piece.backgroundColor)
    mesh.position.x = w.x
    mesh.position.y = piece.y || State.yCovered
    mesh.position.z = w.z
    mesh.backgroundColor = piece.backgroundColor
    if (piece.inMotion) {
      On.marbleSwipe.roll(mesh, piece.inMotion, piece.inMotion)
    }
    return mesh
}

Generate.basic = function(piece) {
    var w = Hex.hexToWorld(piece.hex,  Hex.worldHexRatio)
    let mesh = Generate.hexTile({
      strokes: piece.strokes,
      backgroundColor: piece.backgroundColor,
      hexEdgeColor: piece.hexEdgeColor,
      x: w.x,
      y: piece.y || State.yCovered,
      z: w.z,
      parent: State.ground,
      name: piece.name,
      type: piece.type,
      hex: piece.hex,
      side: piece.side | 0,
      shader: piece.shader,
      centerWidth: piece.centerWidth ? piece.centerWidth : 12.6
    })
    return mesh
}

Generate.fixStrokes = function(piece) {
    let defaultBandColor = Color.colors[0]
    let edgeColor = Color.colors[2]
    let tileStrokes = piece.strokes.map(function(stroke) {
        let bandColor = defaultBandColor
        if (stroke.bandColor) {
            bandColor = stroke.bandColor == "#dd3" ? Color.colors[13] : stroke.bandColor
        }
        return {
            stroke: stroke.stroke,
            bandColor: bandColor,
            edgeColor: edgeColor
        }
    })
    piece.strokes = tileStrokes
    piece.shader = 'track'
}

// appearance, location, behavior
Generate.piece = function(piece) {
    if (!piece.type) {
        piece.type = "normal"
    }
    if (piece.shader == undefined && piece.name === 'tile' && piece.strokes && piece.strokes.length > 0) {
        // TODO From Mazes and Billiards... fix it there instead
        Generate.fixStrokes(piece)
    }
    if (piece.type == 'frame') {
        piece.shader = 'track'
    }
    var mesh = piece.name == "marble"
    ? Generate.marble(piece)
    : piece.name == "blackWhite"
    ? Generate.blackWhiteTile(piece)
    : Generate.basic(piece)

    mesh.type = piece.type
    if (piece.type == "labeled") {
            var hex_outline = piece.hex_outline || true
            var strokes = piece.strokes
            var material = Material.generateTextureMaterial({
            //var dynamicTexture = Draw.generateHexTexture({
                  name: "tileMat",
                  type: piece.type,
                  strokes: piece.strokes,
                  backgroundColor: piece.backgroundColor,
                  hexEdgeColor: piece.hexEdgeColor,
                  hex_outline: hex_outline,
                  textureSize: 64,
                  nocache: true
            })
            mesh.material = material
            Draw.addLabelToTexture(mesh.material.diffuseTexture, piece.label)
    }
    if (piece.type === "icon") {
            var iconSVG = Icons[piece.label]
            var hex_outline = piece.hex_outline || true
            var material = Material.generateTextureMaterial({
                  name: "tileMat",
                  type: piece.type,
                  strokes: piece.strokes,
                  backgroundColor: piece.backgroundColor,
                  hexEdgeColor: piece.hexEdgeColor,
                  hex_outline: hex_outline,
                  textureSize: 64,
                  nocache: true
            })
            mesh.material = material
            Draw.addIconToTexture(mesh.material.diffuseTexture, iconSVG, piece.backgroundColor, piece.hexEdgeColor)
     }
    //  if (piece.type === "snapshot") {
    //         console.log(piece.url)
    //         var dynamicTexture = new BABYLON.DynamicTexture("Generate Thumbnail", 512, State.scene, true);
    //         var context = dynamicTexture._context
    //         var img = new Image();
    //         img.src = piece.url;
    //         img.onload = function() {
    //             //Add image to dynamic texture
    //             context.drawImage(this, 0, 0, 512, 512);
    //             dynamicTexture.update();
    //         }
    //         mesh.material = Draw.generateMaterial("Thumbnail", dynamicTexture)
    // }
    if (piece.label) {
        mesh.label = piece.label
    }
    if (piece.fps) {
        mesh.fps = piece.fps
    }
    if (piece.locked) {
        mesh.locked = piece.locked
    }
    if (piece.paintTrail) {
        mesh.paintTrail = piece.paintTrail
    }
    mesh.def = Object.assign({}, piece)
    if (piece.topMenu) {
        mesh.on = {
            snap: function() {},
            pointerDown: State.Manager.restartMainChoices,
            pointerUp: function() {},
            drag: function() {}
        }
    }
    if (piece.on) {
        // Copy elements, because mesh.on.pointerUp can get set
        // Need to move all the behaviors to On
        var on = piece.on
        if (typeof on === "string") {
            on = On[on] || Generate[on]
        }
        mesh.on = Object.assign({}, on)
        if (mesh.on.collide) {
            Collision.agents.push(mesh)
        }
    }
    mesh.generator = !!piece.generator
    HexShader.setGeneratorColors()
    return mesh
}

Generate.uDesignMove = function(mesh) {
    Input.tapAndDragEnd(mesh)
    if (mesh.originHex[0] !== mesh.hex[0] || mesh.originHex[1] !== mesh.hex[1]) {
      var originHex = [mesh.originHex[0], mesh.originHex[1]]
      var toHex = [mesh.hex[0], mesh.hex[1]]
      //Thumbnails.updateCurrent("uDesign")
      Store.localChange({
            command: "move",
            data: {
                id: mesh.id,
                from: originHex,
                to: toHex
            }
      })
      Store.persist()
    }
    mesh.originHex = null
}

Generate.uDesignNormalOn = {
    snap: function() {},
    noSnap: function(mesh) { console.log("noSnap") },
    tap: function(mesh) {
      Action.tapTile(mesh)
      var id = mesh.id
      //Thumbnails.updateCurrent("uDesign")
      Store.localChange({
            command: "tap",
            data: {
                id: id
            }
      })
      Store.persist()
    },
    pointerDown: Input.tapAndDragStart,
    pointerUp: Generate.uDesignMove,
    drag: Input.drag
}

Generate.regenerateAndClear = function(mesh) {
    var newMesh = Generate.regeneratePiece(mesh)
    if (mesh.type !== "web") {
        mesh.type = "normal"
    }
    mesh.generator = false
    mesh.on = Generate.uDesignNormalOn

    return newMesh
}

// appearance, location, behavior
Generate.regenerate = function(mesh) {
    Input.tapAndDragEnd(mesh)
    var distance = Hex.cubeDistance(mesh.hex, mesh.def.hex)
    if (distance >= 1) {
        return Generate.regenerateAndClear(mesh)
    }
    return null
}

Generate.uDesignOn = {
    snap: function(mesh) {},
    noSnap: function(mesh) { console.log("noSnap") },
    tap: function(mesh) {
      Action.tapTile(mesh)
      var id = mesh.id
      //Thumbnails.updateCurrent("uDesign")
      Store.localChange({
            command: "tap",
            data: {
                id: id
            }
      })
      Store.persist()
    },
    pointerDown: Input.tapAndDragStart,
    pointerUp: function(mesh) {
        var newMesh = Generate.regenerate(mesh)
        if (newMesh) {
            console.log('newMesh')
            var originHex = [mesh.originHex[0], mesh.originHex[1]]
            var toHex = [mesh.hex[0], mesh.hex[1]]
            var id = mesh.id
            var newId = newMesh.id
            //Thumbnails.updateCurrent("uDesign")
            Store.localChange({
                    command: "moveclone",
                    data: {
                        pair: [id, newId],
                        from: originHex,
                        to: toHex
                    }
            })
            Store.persist()
            mesh.originHex = null
            mesh.type = 'normal'
            const centerWidth = HexShader.centerWidth(mesh.type)
            HexShader.setHexEdgeWidth(mesh.material, centerWidth)
        } else {
            console.log('no newMesh')
            Generate.uDesignMove(mesh)
        }
    },
    drag: Input.drag
}

// appearance, location, behavior
Generate.regeneratePiece = function(mesh) {
    var piece = mesh.def
    piece.y = State.yField
    if (piece.strokeSet) {
        var strokes = piece.strokeSet[Random.generateIndex(piece.strokeSet.length)]
        piece.strokes = strokes.map(function(stroke) {
                return {
                    stroke: stroke,
                }
        })
    }
    if (!piece.generator) {
        console.log('Expected generator')
    }
    return Generate.piece(piece)
}

On.designLockTile = false
// appearance, location, behavior
Generate.designRegenerate = function(mesh) {
    Input.tapAndDragEnd(mesh)
    var distance = Hex.cubeDistance(mesh.hex, mesh.def.hex)
    if (distance >= 1) {
      Generate.regeneratePiece(mesh)
      HexShader.setGeneratorColors()
      mesh.locked = On.designLockTile
    }
}

// behavior
Generate.colorOn = {
    snap: function(mesh) {
        var allTiles = Path.meshesAtHex(mesh.hex)
        if (allTiles.length == 2 && 
            (allTiles[0].def.type == 'frame' ||
            allTiles[1].def.type == 'frame')) {
            let newMesh = Generate.regeneratePiece(mesh)
            mesh.on.pointerUp = Generate.uDesignMove
            mesh.on.snap = () => {}
            mesh.type = 'normal'
            mesh.generator = false
            Store.localChange({
             command: "moveclone",
             data: {
                pair: [mesh.id, newMesh.id],
                from: mesh.originHex,
                to: mesh.hex
             }
            })
            Store.persist()
            mesh.originHex = null
            const centerWidth = HexShader.centerWidth(mesh.type)
            HexShader.setHexEdgeWidth(mesh.material, centerWidth)
        } else {
            var distance = Hex.cubeDistance(mesh.hex, mesh.def.hex)
            if (distance >= 1) {
                Action.colorTileHere(allTiles, mesh)
                mesh.dispose()
                Generate.regeneratePiece(mesh)
            }
        }
    },
    pointerDown: Input.tapAndDragStart,
    pointerUp: Input.tapAndDragEnd,
    drag: Input.drag
}

Generate.blackWhiteTile = function(def) {
    var w = Hex.hexToWorld(def.hex)

    var tile = Generate.hexTile({
        strokes: [],
        backgroundColor: Color.colors[Color.schemes[3][1]],
        hexEdgeColor: Color.colors[Color.schemes[3][2]],
        x: w.x,
        y: State.yField,
        z: w.z,
        parent: State.ground,
        name: "tile",
        type: "bw",
        hex: def.hex,
        //shader: "track"
    })  
    return tile
}

Generate.placeGenerateHexTile = function(tile, hex, side) {  
    var w = Hex.hexToWorld(hex)
    tile.position.y = State.yGenerator
    tile.position.z = w.z
    tile.position.x = w.x
    tile.side = side
    tile.hex = hex
    if (side !== 0) {
      tile.rotate(BABYLON.Axis.Y, (Math.PI/3)*side, BABYLON.Space.WORLD)
    }
    return tile
}

Generate.material = function(d) {
    return d.shader
    ? Shaders.getShaderMaterial(d)
    // At size 64, about 6.25mg, at 128, about 25mg for 20 colors, 19 shapes
    // (For text and icons)
    : Material.generateTextureMaterial({
        name: "tileMat",
        type: d.type,
        strokes: d.strokes,
        backgroundColor: d.backgroundColor,
        hexEdgeColor: d.hexEdgeColor,
        hex_outline: d.hex_outline || true,
        textureSize: 64
  })
}
// appearance, location
Generate.hexTile = function(d) {

  var material = Generate.material(d)

  var tile = Draw.generateAndPlaceHexTile(State.scene, material, d)

  State.tileId = Math.max(d.id || 0, State.tileId + 1)

  d.id = d.id || State.tileId

  tile.id = d.id
  tile.parent = d.parent
  tile.strokes = d.strokes
  tile.colorScheme = d.colorScheme
  tile.backgroundColor = d.backgroundColor
  tile.hexEdgeColor = d.hexEdgeColor
  tile.hex_outline = d.hex_outline
  tile.hex = d.hex
  tile.y = d.y

  Path.topMeshCandidate(tile)

  State.tileDict[tile.id] = tile

  d.command = "newHexTile"
  d.parent = null // TODO ParentId rather than parent
  Log.gameChange({
      type: "newHexTile",
      d: d
  })
  Log.takeSnapshot()
  return tile
}

// appearance
Generate.toRandomColorScheme = function(tiles) {
    var colorScheme = Color.schemes[Random.generateIndex(Color.schemes.length)]
    var bandColor = Color.colors[colorScheme[0]]
    var backgroundColor = Color.colors[colorScheme[1]]
    var edgeColor = Color.colors[colorScheme[2]]
    tiles.forEach(function(tile) {
        if (tile.name === "tile") {
          if (tile.type === "start" || tile.type == "finish" || tile.type == "agentHereFinish") {
            tile.backgroundColor = bandColor
            tile.hexEdgeColor = edgeColor
            tile.bandColor = backgroundColor
            tile.edgeColor = edgeColor
            tile.strokes.forEach(function(stroke) {
                stroke.bandColor = backgroundColor
                stroke.edgeColor = edgeColor
            })
          } else if (tile.type !== "frame" && !tile.marble) {
            tile.backgroundColor = backgroundColor
            tile.hexEdgeColor = edgeColor
            tile.bandColor = bandColor
            tile.edgeColor = edgeColor
            tile.strokes.forEach(function(stroke) {
                stroke.bandColor = bandColor
                stroke.edgeColor = edgeColor
            })
          }
        }
    })
}

module.exports = Generate
